
import { Component, Prop, Vue } from 'vue-property-decorator';
import DoubleIcon from '@client/components/DoubleIcon/DoubleIcon.vue';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { Device, Store } from '@client/models';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { Optional } from '@common/types';

@Component({
  components: { ModalHeader, DoubleIcon },
})
export default class WifiConfig extends Vue {
  /* DECLARATIONS */
  @Prop()
  private device!: Device;
  @Prop()
  private deviceStore!: Store;

  private devicesStore: DevicesStore = useDevicesStore();
  /* LIFECYCLE EVENTS */

  /* METHODS */
  async fetchDeviceWifiConfig(): Promise<void> {
    const updatedRail: Optional<Device> = await this.devicesStore.fetchDeviceWifiConfig(
      this.device.longId,
      this.deviceStore.idAzure
    );
    if (!updatedRail) {
      return;
    }
    this.$emit('onSync', updatedRail);
  }

  /* GETTERS */
  get isLoading(): boolean {
    return this.devicesStore.isFetching;
  }
}
