import { render, staticRenderFns } from "./SectionsDataTable.vue?vue&type=template&id=0b41f54c&scoped=true"
import script from "./SectionsDataTable.vue?vue&type=script&lang=ts"
export * from "./SectionsDataTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b41f54c",
  null
  
)

export default component.exports