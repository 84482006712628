
import { Component, Vue } from 'vue-property-decorator';
import CreateOrCloneGondolaTemplateModal from '@client/components/GondolaTemplate/CreateOrCloneGondolaTemplateModal.vue';

@Component({
  components: { CreateOrCloneGondolaTemplateModal },
})
export default class AddTemplateButton extends Vue {
  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }
}
