
import { Component, Vue, Watch } from 'vue-property-decorator';
import StoreSettings from '@client/components/StoreDetail/StoreSettings/StoreSettings.vue';
import StoreDetailGondolas from '@client/components/StoreDetail/StoreDetailGondolas.vue';
import StoreDetailGondolasLoader from '@client/components/StoreDetail/StoreDetailGondolasLoader.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import { Store } from '@client/models';
import StoreNotFound from '@client/components/NotFound/StoreNotFound.vue';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';

const OVERVIEW_TAB: string = 'overview';
const SETTINGS_TAB: string = 'settings';

@Component({
  components: {
    StoreDetailGondolasLoader,
    StoreDetailGondolas,
    StoreSettings,
    StoreNotFound,
  },
})
export default class StoreDetailView extends Vue {
  private isDataLoaded: boolean = false;
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private tagsStore: TagsStore = useTagsStore();

  private activeTab: string = OVERVIEW_TAB;

  async created(): Promise<void> {
    try {
      if (this.storeId) {
        await this.tagsStore.fetch();
        await Promise.all([this.schedulesStore.fetch(), this.gondolaTemplatesStore.fetch()]);
        await this.storesStore.fetchStoreDetails(this.storeId);
        await this.storesStore.populateDevices(this.storeId);
        await this.activeHoursStore.fetchActiveHours(this.storeId);
      }
    } catch (e) {
      console.error(e);
    }
    this.isDataLoaded = true;
  }

  beforeDestroy(): void {
    this.storesStore.clearCurrentStore();
  }

  @Watch('$route.fullPath')
  async onRouteChanged(): Promise<void> {
    this.activeTab = OVERVIEW_TAB;
  }

  @Watch('store')
  onStoreChange(store: Optional<Store>): void {
    if (store) {
      this.breadcrumbsStore.replace({
        path: `/stores/`,
        title: { key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path },
      });
      this.breadcrumbsStore.push({
        path: `${this.$route.fullPath}`,
        title: {
          key: this.$i18nTranslationKeys.storeDetail.breadcrumb.$path,
          params: {
            store: this.storeName,
          },
        },
      });
      this.breadcrumbsStore.setCurrentPage(
        {
          key: this.$i18nTranslationKeys.storeDetail.breadcrumb.$path,
          params: {
            store: this.storeName,
          },
        },
        'mdi-store',
        [
          {
            title: { key: this.$i18nTranslationKeys.storeDetail.aislesOverview.$path },
            icon: 'mdi-view-compact',
            clickEvent: () => {
              this.activeTab = OVERVIEW_TAB;
            },
            key: OVERVIEW_TAB,
            dataCy: 'aisles-overview-tab-cy',
          },
          {
            title: { key: this.$i18nTranslationKeys.storeDetail.settings.$path },
            icon: 'mdi-cog',
            clickEvent: () => {
              this.activeTab = SETTINGS_TAB;
            },
            key: SETTINGS_TAB,
            dataCy: 'settings-overview-tab-cy',
          },
        ]
      );
    }
  }

  @Watch('storeId')
  async onStoreIdChange(storeId: string): Promise<void> {
    this.isDataLoaded = false;
    await this.storesStore.fetchStoreDetails(storeId);
    this.isDataLoaded = true;
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get storeName(): string {
    return this.store?.name || this.storeId;
  }

  get store(): Optional<Store> {
    return this.storesStore.getCurrentStore(this.storeId);
  }
}
