
import { Component, Prop, Vue } from 'vue-property-decorator';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import { TimeSpanCalendar } from '@client/components';
import { Schedule } from '@client/models';
import SchedulesOverviewTable from '../SchedulesModalDialog/SchedulesOverviewTable.vue';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({
  components: { SchedulesOverviewTable, TimeSpanCalendar, Wrapper },
})
export default class SchedulesTab extends Vue {
  /* DECLARATIONS */
  @Prop({ default: () => [] })
  private schedules!: Array<Schedule>;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get gondolaTemplateId(): string {
    return this.$route.params.id;
  }

  get isGondolaTemplateFetching() {
    return this.gondolaTemplatesStore.isFetching;
  }
}
