
import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';
import { GondolaTemplate } from '@client/models';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import TagReference from '@client/models/SettingsModels/TagReference';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({
  components: { Wrapper, SelectTag },
})
export default class DetailsTab extends Vue {
  @Prop()
  private gondolaTemplate!: GondolaTemplate;
  @Prop()
  private isGondolaTemplateFetching!: boolean;

  private name: string = '';
  private selectedTags: TagReference[] = [];

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  created() {
    if (this.gondolaTemplate) {
      this.name = this.gondolaTemplate.name;
      this.selectedTags = this.gondolaTemplate.tags;
    }
  }

  validateName(name: string): boolean | TranslateResult {
    const duplicateGondolaTemplate: Optional<GondolaTemplate> = this.gondolaTemplatesStore.findName(this.name);
    if (!duplicateGondolaTemplate || duplicateGondolaTemplate._id === this.gondolaTemplate?._id) {
      return validateTextFieldLength(name, DEFAULT_COMMON_STRING_MAX_LENGTH, true);
    }
    return i18n.t(TranslationKeys.error.genericDuplicate.$path);
  }

  updateTags(selectedTags: TagReference[]): void {
    this.$emit('tags-change', selectedTags);
  }

  updatedName(): void {
    this.$emit('name-change', this.name);
  }
}
