
import { Component, Prop, Vue } from 'vue-property-decorator';
import Overview from '@client/components/DeviceDetail/Overview.vue';
import { Device, Gondola, GondolaPublishing, Store } from '@client/models';
import { Optional } from '@common/types';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  components: { Wrapper, Overview },
})
export default class OverviewTab extends Vue {
  /* DECLARATIONS */
  @Prop()
  private device!: Device;
  @Prop()
  private deviceStore?: Optional<Store>;
  @Prop()
  private deviceGondola?: Optional<Gondola>;
  @Prop()
  private lastPublishing?: Optional<GondolaPublishing>;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
