import { BaseLayer, ForegroundContent, ScheduledContent } from '@client/models/ScheduleModels';
import ScheduledContentModel from '@client/models/ScheduleModels/ScheduledContent.model';
import { ForegroundContentModelJSON, ScheduledContentModelJSON } from '@common/schedule/types';
import { ContentItem } from '@client/models/ContentModels/types';
import { Optional } from '@common/types';

export default class ForegroundContentModel {
  name: Optional<string>;
  baseLayer: BaseLayer;
  scheduledContent?: Array<ScheduledContent>;

  constructor(baseLayer: BaseLayer, name: Optional<string>, scheduledContent?: Array<ScheduledContent>) {
    this.name = name;
    this.baseLayer = baseLayer;
    this.scheduledContent = scheduledContent ? scheduledContent : [];
  }

  public static fromContentItem(contentItem: ContentItem): ForegroundContentModel {
    return new ForegroundContent(contentItem.toBaseLayer(), '');
  }

  public static fromJSON(foregroundContentModelJSON: ForegroundContentModelJSON): ForegroundContent {
    const baseLayerModel: BaseLayer = BaseLayer.fromJSON(foregroundContentModelJSON.baseLayer);
    const scheduledContentModel: ScheduledContentModel[] | undefined = foregroundContentModelJSON.scheduledContent?.map(
      (scheduledContentItem: ScheduledContentModelJSON) => ScheduledContent.fromJson(scheduledContentItem)
    );

    return new ForegroundContent(baseLayerModel, foregroundContentModelJSON.name, scheduledContentModel);
  }

  public toJSON(): ForegroundContentModelJSON {
    return {
      baseLayer: this.baseLayer.toJSON(),
      name: this.name || '',
      scheduledContent: this.scheduledContent?.map((scheduledContentItem: ScheduledContentModel) =>
        scheduledContentItem.toJSON()
      ),
    };
  }

  public clone(): ForegroundContent {
    return new ForegroundContent(
      this.baseLayer.clone(),
      this.name,
      this.scheduledContent?.map((scheduledContentItem: ScheduledContentModel) => scheduledContentItem.clone())
    );
  }
}
