
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DetectedObjectEntry, DeviceSensorObjects } from '@common/apim/definitions';
import { Bar } from 'vue-chartjs';
import { ChartDataOptions, OverallAverages } from '@client/components/SensorAnalytics/charts/types';
import { calculateOverallAverages } from '@client/components/SensorAnalytics/charts/utils';
import SwitchInput from '@client/components/SensorAnalytics/charts/Switch.vue';
import { VuetifyParsedThemeItem } from 'vuetify/types/services/theme';

@Component({
  components: { SwitchInput, Bar },
})
export default class ObjectsPerArea extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sensorDataObjects: DeviceSensorObjects | null = null;

  private mode: 'total' | 'average' = 'total';

  private overallOverages: OverallAverages = {
    averageObjectsDecision: 0,
    averageObjectsDwell: 0,
    averageObjectsExposure: 0,
    validObjectsDecision: 0,
    validObjectsDwell: 0,
    validObjectsExposure: 0,
  };

  private chartData: ChartDataOptions = {
    labels: [],
    datasets: [],
  };
  /* LIFECYCLE EVENTS */
  mounted(): void {
    this.setChartData();
  }

  @Watch('sensorDataObjects', { deep: true })
  onDataChange(): void {
    if (!this.sensorDataObjects) {
      return;
    }
    this.overallOverages = calculateOverallAverages(
      this.sensorDataObjects.detectedObjects.map((object: DetectedObjectEntry) => object.detectedObject)
    );
    this.setChartData();
  }
  /* METHODS */

  setChartData(): void {
    this.chartData = {
      labels: [
        this.$t(this.$i18nTranslationKeys.sensors.areas.awareness.$path),
        this.$t(this.$i18nTranslationKeys.sensors.areas.dwell.$path),
        this.$t(this.$i18nTranslationKeys.sensors.areas.decision.$path),
      ],
      datasets: [
        {
          data: this.dataset,
          labels: [
            this.$t(this.$i18nTranslationKeys.sensors.areas.awareness.$path),
            this.$t(this.$i18nTranslationKeys.sensors.areas.dwell.$path),
            this.$t(this.$i18nTranslationKeys.sensors.areas.decision.$path),
          ],
          tension: 0.5,
          borderRadius: 10,
          borderColor: [
            (this.$vuetify.theme.themes.light.primary as VuetifyParsedThemeItem).base,
            (this.$vuetify.theme.themes.light['puerto-rico-blue'] as VuetifyParsedThemeItem).base,
            (this.$vuetify.theme.themes.light['neon-green'] as VuetifyParsedThemeItem).base,
          ],
          backgroundColor: [
            (this.$vuetify.theme.themes.light.primary as VuetifyParsedThemeItem).base,
            (this.$vuetify.theme.themes.light['puerto-rico-blue'] as VuetifyParsedThemeItem).base,
            (this.$vuetify.theme.themes.light['neon-green'] as VuetifyParsedThemeItem).base,
          ],
          fill: true,
          datalabels: {
            formatter: (value: number) => (this.mode === 'average' ? `${(value * 100).toFixed(2)}%` : `${value}`),
          },
        },
      ],
    };
  }

  onSwitchValueChange(value: boolean): void {
    this.mode = value ? 'average' : 'total';
    this.setChartData();
  }
  /* GETTERS */
  get chartOptions() {
    return {
      responsive: true,
      type: 'bar',
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'start', // Position the label
          align: 'top', // Align the label to the top of the bar
          formatter: (value: number) => value, // Display the value
          font: {
            weight: 'bold',
          },
        },
      },
    };
  }

  get dataset(): Array<number> {
    const {
      averageObjectsExposure,
      averageObjectsDwell,
      averageObjectsDecision,
      validObjectsExposure,
      validObjectsDwell,
      validObjectsDecision,
    }: OverallAverages = this.overallOverages;
    if (this.mode === 'total') {
      return [validObjectsExposure, validObjectsDwell, validObjectsDecision];
    }
    return [averageObjectsExposure, averageObjectsDwell, averageObjectsDecision];
  }
}
