
import { Component, Prop, Vue } from 'vue-property-decorator';

import { TranslateResult } from 'vue-i18n';
import { StoreSyncEventLog } from '@client/models/EventLogModels/StoreSyncEventLog/StoreSyncEventLog.model';
import SynchronizedDevices from '@client/components/NotificationPanel/Notifications/elements/SynchronizedDevices.vue';
import { getStoreDetailPath } from '@client/router/utils';
import { Store } from '@client/models';
import { Optional } from '@common/types';
import Notification from '@client/components/NotificationPanel/Notifications/Notification.vue';

@Component({
  components: { Notification, SynchronizedDevices },
})
export default class StoreSynchronization extends Vue {
  /* DECLARATIONS */
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: StoreSyncEventLog;
  @Prop()
  private store: Optional<Store>;

  /* LIFECYCLE EVENTS */
  /* METHODS */

  /* GETTERS */
  get storeName(): string {
    return this.store?.name || this.eventLog.azureStoreId;
  }

  get notificationTitle(): TranslateResult {
    return ` - ${this.$t(this.$i18nTranslationKeys.notifications.storeSync.title.$path)}`;
  }

  get dateToDisplay(): string | Date {
    return this.eventLog.createdAt;
  }

  get linkToStore(): string {
    if (!this.store) {
      return '';
    }
    return getStoreDetailPath(this.eventLog.storeId);
  }

  get manualSynchronizationMessage(): TranslateResult {
    return this.eventLog.manual
      ? this.$t(this.$i18nTranslationKeys.notifications.storeSync.manual.$path)
      : this.$t(this.$i18nTranslationKeys.notifications.storeSync.automatic.$path);
  }

  get webhookRecreationMessage(): TranslateResult {
    return this.eventLog.isWebhookSynced
      ? this.$t(this.$i18nTranslationKeys.notifications.storeSync.webhookRecreated.$path)
      : this.$t(this.$i18nTranslationKeys.notifications.storeSync.webhookUpToDate.$path);
  }
}
