
import { Component, Vue } from 'vue-property-decorator';
import { DeviceTemplate, Gondola as GondolaModel, GondolaTemplate, Store } from '@client/models';
import { Gondola, GondolaTemplateContent, VisualTimer } from '@client/components';
import { TranslateResult } from 'vue-i18n';
import ForegroundContentModel from '@client/models/ScheduleModels/ForegroundContent.model';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { PublishingsStore, usePublishingsStore } from '@client/stores/publishings';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { ContentType } from '@common/enums';
import { EMPTY_LABEL_PLACEHOLDER } from '@client/models/ContentModels/types';
import { PublishProcessState } from '@client/enums';
import { BulkPublishingJSON, ValidatedPublishingJSON } from '@common/publishing/types';
import { extractPublishValidationError } from '@client/views/StorePages/PublishValidationErrorUtil';
import { getStoreDetailPath } from '@client/router/utils';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

const DEFAULT_REDIRECT_TIME: number = 3;

@Component({
  components: {
    Wrapper,
    VisualTimer,
    Gondola,
    GondolaTemplateContent,
  },
})
export default class PublishDetailView extends Vue {
  /* DECLARATIONS */
  private selectedGondolaTemplate: Optional<GondolaTemplate> = null;
  private redirectTime: number = DEFAULT_REDIRECT_TIME;
  private layoutMatchFilterActive: boolean = true;
  private storeId: string = '';
  private validationErrorMessage: TranslateResult = '';
  private publishProcessState: PublishProcessState = PublishProcessState.NOT_STARTED;
  private isDataLoading: boolean = true;

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private publishingsStore: PublishingsStore = usePublishingsStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private storesStore: StoresStore = useStoresStore();

  $refs!: {
    timer: VisualTimer;
  };

  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    this.storeId = this.$route.params.storeid;
    await this.gondolaTemplatesStore.fetch();
    await this.storesStore.fetchStoreDetails(this.storeId, true);
    this.breadcrumbsStore.replace({
      path: `/stores/`,
      title: { key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path },
    });
    this.breadcrumbsStore.push({
      path: `/stores/${this.storeId}`,
      title: {
        key: this.$i18nTranslationKeys.storeDetail.breadcrumb.$path,
        params: {
          store: this.store?.name || this.storeId,
        },
      },
    });
    this.breadcrumbsStore.push({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.publishDetailView.breadcrumb.$path },
    });
    this.breadcrumbsStore.setCurrentPage(
      { key: this.$i18nTranslationKeys.publishDetailView.breadcrumb.$path },
      'mdi-publish'
    );
    this.isDataLoading = false;
  }
  /* METHODS */
  async onPublish(): Promise<void> {
    if (this.publishProcessState !== PublishProcessState.VALIDATION_SUCCESS) {
      return;
    }
    if (!this.selectedGondolaTemplate || !this.gondola || !this.store) {
      return;
    }
    this.publishProcessState = PublishProcessState.IN_PROGRESS;
    const bulkPublishingJSON: BulkPublishingJSON = {
      templateId: this.selectedGondolaTemplate._id ?? '',
      templateHash: this.selectedGondolaTemplate.hash ?? '',
      sections: [
        {
          sectionId: this.gondola._id,
          storeId: this.storeId,
          storeHash: this.store.hash ?? '',
        },
      ],
    };
    const readyForRedirect: boolean = await this.publishingsStore.publish(bulkPublishingJSON);
    if (readyForRedirect) {
      this.publishProcessState = PublishProcessState.DONE;
      this.$refs.timer.startTimer();
    }
  }

  templateText(item: GondolaTemplate): string {
    return item.name ? item.name : item._id || '';
  }

  async templateSelected(selected: string): Promise<void> {
    this.selectedGondolaTemplate = this.gondolaTemplatesStore.getById(selected);
    if (!this.selectedGondolaTemplate || !this.gondola || !this.store) {
      return;
    }
    this.publishProcessState = PublishProcessState.IN_PROGRESS;
    const bulkPublishingJSON: BulkPublishingJSON = {
      templateId: this.selectedGondolaTemplate._id ?? '',
      templateHash: this.selectedGondolaTemplate.hash ?? '',
      sections: [
        {
          sectionId: this.gondola._id,
          storeId: this.storeId,
          storeHash: this.store?.hash ?? '',
        },
      ],
    };
    const validatedPublishingJSON: Optional<ValidatedPublishingJSON> = await this.publishingsStore.validate(
      bulkPublishingJSON
    );
    if (!validatedPublishingJSON) {
      return;
    }
    const readyToPublish: boolean = validatedPublishingJSON.validationState === 'READY_TO_PUBLISH';
    if (!readyToPublish) {
      this.validationErrorMessage = extractPublishValidationError(validatedPublishingJSON, this.storesStore, this);
      this.publishProcessState = PublishProcessState.VALIDATION_FAILED;
      return;
    }
    this.publishProcessState = PublishProcessState.VALIDATION_SUCCESS;
    this.validationErrorMessage = '';
  }

  async onRedirectTimerEnd(): Promise<void> {
    if (!this.gondola) {
      return;
    }
    await this.$router.push(
      getStoreDetailPath(this.$route.params.storeid, this.gondola.aisle, this.gondola.positionInAisle)
    );
  }

  /**
   * Method used for determining color of list item in dropdown
   * Returns text--disabled if the list item doesn't match layout of selected gondola
   * @param gondolaItem - current list item
   */
  getColorOfTemplateItem(gondolaItem: GondolaTemplate): string {
    return gondolaItem.layoutHash !== this.gondola?.layoutHash ? 'text--disabled' : '';
  }

  /* GETTERS */

  get gondola(): Optional<GondolaModel> {
    return this.storesStore.getGondolaById(this.$route.params.storeid, this.$route.params.gondolaid);
  }

  get filteredTemplates(): GondolaTemplate[] {
    let filteredTemplates: GondolaTemplate[] = this.gondolaTemplates;
    if (this.layoutMatchFilterActive) {
      filteredTemplates = filteredTemplates.filter(
        (template: GondolaTemplate) => template.layoutHash === this.gondola?.layoutHash
      );
    }
    return filteredTemplates;
  }

  /**
   * Return all the gondola template in the store,
   * By default, the templates are sorted by creation date, from oldest to newest,
   * by reversing the array, the templates are now sorted having the newest one first.
   */
  get gondolaTemplates(): GondolaTemplate[] {
    return this.gondolaTemplatesStore.gondolaTemplates.reverse();
  }

  get store(): Optional<Store> {
    return this.storesStore.getStoreById(this.storeId);
  }

  get infoMessage(): string {
    if (!this.layoutMatchFilterActive) {
      return '';
    }
    const numberOfMatchingTemplates: number = this.filteredTemplates.length;
    return ` (${this.$tc(
      this.$i18nTranslationKeys.publishDetailView.templatesFound.$path,
      numberOfMatchingTemplates
    )})`;
  }

  get hasError(): boolean {
    return this.validationErrorMessage !== '';
  }

  get errorText(): TranslateResult {
    return this.validationErrorMessage;
  }

  get isSelectedGondolaTemplateWithValidLabels(): boolean {
    const flattenedDevices: DeviceTemplate[] | undefined = this.selectedGondolaTemplate?.railGrid.flat(1);
    if (!flattenedDevices) {
      return false;
    }
    return !flattenedDevices.some((device: DeviceTemplate) =>
      device.foregroundContent.some(
        (foregroundContent: ForegroundContentModel) => foregroundContent.baseLayer.name === EMPTY_LABEL_PLACEHOLDER
      )
    );
  }

  get isSelectedGondolaTemplateWithValidBackground(): boolean {
    const flattenedDevices: DeviceTemplate[] | undefined = this.selectedGondolaTemplate?.railGrid.flat(1);
    if (!flattenedDevices) {
      return false;
    }
    return !flattenedDevices.some(
      (device: DeviceTemplate) =>
        device.backgroundContent?.length === 0 ||
        (device.backgroundContent[0].baseLayer?.type === ContentType.Playlist &&
          device.backgroundContent[0].baseLayer.playlist.length === 0)
    );
  }

  get isGondolaTemplateSelected(): boolean {
    return this.selectedGondolaTemplate != null;
  }

  get isPublishButtonDisabled(): boolean {
    return (
      !this.isGondolaTemplateSelected ||
      !this.isSelectedGondolaTemplateWithValidLabels ||
      !this.isSelectedGondolaTemplateWithValidBackground ||
      this.publishProcessState !== PublishProcessState.VALIDATION_SUCCESS
    );
  }

  get isPublishing(): boolean {
    return this.publishProcessState === PublishProcessState.IN_PROGRESS;
  }

  get filterActivityColor(): string {
    return this.layoutMatchFilterActive ? 'primary' : '';
  }
}
