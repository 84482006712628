import { ContentType } from '@common/enums';
import { GondolaTemplateWithIdJSON } from '@common/gondola-template/types';
import { TagReferenceJSON } from '../config/tags/types';

/**
 * This represents our scaling factor for content items previews
 * Each 158px is scaled down to 40px
 */
export const DEVICE_PREVIEW_SCALE_FACTOR_IN_PIXELS: number = 40;
/**
 * The maximum number of search results to be returned
 */
export const MAXIMUM_NUMBER_OF_SEARCH_RESULTS: number = 1000;
/**
 * One hour in milliseconds
 */
export const ONE_HOUR: number = 60 * 60 * 1000;

export interface BaseContentItemJSON {
  name: string;
  customerId: string;
  hash?: string;
  lastPreviewJobRun?: number;
  type: ContentType;
  parentFolder?: string;
  ancestors?: Ancestors;
  tags?: Array<TagReferenceJSON>;
}

export interface BaseContentItemWithIdJSON extends BaseContentItemJSON {
  _id: string;
}

export interface NewFolderJSON extends BaseContentItemJSON {
  name: string;
}

export interface FolderJSON extends NewFolderJSON {
  _id: string;
}

export interface NewImageJSON extends BaseContentItemJSON {
  originalName: string;
  url: string;
  previewUrl: string;
  previewState: ContentItemPreviewStates;
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  checksum: string;
}

export interface ImageJSON extends NewImageJSON {
  _id: string;
}

export interface NewVideoJSON extends NewImageJSON {
  duration?: number;
  fps?: number;
}

export interface VideoJSON extends NewVideoJSON {
  _id: string;
}

/**
 * Contains the populated array of playlist items
 */
export interface PopulatedPlaylistJSON extends BaseContentItemWithIdJSON {
  playlist: Array<PopulatedPlaylistItemJSON>;
}

/**
 * Same as {@link NonPopulatedPlaylistWithIdJSON} but excludes the _id field
 */
export interface NonPopulatedPlaylistJSON extends BaseContentItemJSON {
  playlist: Array<NonPopulatedPlaylistItemJSON>;
}

/**
 * Contains the non populated array of playlist items
 * The playlist item will be an array of string, each value is an object id
 */
export interface NonPopulatedPlaylistWithIdJSON extends NonPopulatedPlaylistJSON {
  _id: string;
}

/**
 * Same as {@link PopulatedPlaylistJSON} but excludes the _id field
 */
export interface PopulatedPlaylistItemJSON extends PlaylistItemJSON {
  contentItem: VideoJSON;
}

export interface PlaylistItemJSON {
  hideLabels: boolean;
}

export interface NonPopulatedPlaylistItemJSON extends PlaylistItemJSON {
  contentItem: string;
}

export interface ContentReferencesJSON {
  playlistsContainingItem: Array<PopulatedPlaylistJSON>;
  templatesContainingItem: Array<GondolaTemplateWithIdJSON>;
}

export type ContentItemJSON = VideoJSON | ImageJSON | PopulatedPlaylistJSON | FolderJSON;
export type NewContentItemJSON = NewVideoJSON | NewImageJSON | NewFolderJSON | NonPopulatedPlaylistJSON;
export type NonPopulatedContentItemJSON = VideoJSON | ImageJSON | NonPopulatedPlaylistWithIdJSON | FolderJSON;

export enum ContentItemPreviewStates {
  PENDING = 'PENDING',
  STARTING = 'STARTING',
  PROBING = 'PROBING',
  CREATING = 'CREATING',
  UPLOADING = 'UPLOADING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

/**
 * @type Ancestors
 * @description Represents the ancestors of a folder. This is sorted from the root to the deepest node.
 */
export type Ancestors = Array<Ancestor>;

export interface Ancestor {
  id: string;
  name: string;
}

/**
 * @interface Folder
 * @description Represents a folder structure in the cache tree with its ID, name, and children.
 */
export interface Folder extends Ancestor {
  children: Array<Folder>;
}

export const ROOT_NODE: string = 'root';
export const DEFAULT_ANCESTORS: Ancestors = [{ id: ROOT_NODE, name: ROOT_NODE }];
