
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getCreatedAtFormatted } from '@client/utils/DateTimeUtils';
import {
  EventState,
  PublishingDeviceEventState,
  PublishingDeviceEventType,
  PublishingDeviceEventUpdateJSON,
} from '@common/eventlog/types';
import { LayerPlaylistItem, PublishingDeviceEvent, PublishingDeviceEventUpdate } from '@client/models';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayer from '@client/models/ScheduleModels/ScheduleLayer.model';
import ContentType from '@common/enums/ContentType';

@Component({
  methods: {
    getCreatedAtFormatted,
  },
})
export default class PublishingNotificationEntry extends Vue {
  @Prop()
  private message!: PublishingDeviceEventUpdate;
  @Prop()
  private deviceEvent!: PublishingDeviceEvent;
  @Prop()
  private syncLayerDetails!: { layer: BaseLayerModel | ScheduleLayer | LayerPlaylistItem | undefined; message: string };
  @Prop()
  private readToken!: string;

  getIconForMessageState(state: PublishingDeviceEventState | EventState): string {
    switch (state) {
      case PublishingDeviceEventState.SENT_TO_APIM:
      case PublishingDeviceEventState.ACCEPTED_FROM_APIM:
      case PublishingDeviceEventState.COMPLETED:
        return 'mdi-check';
      case PublishingDeviceEventState.SENDING_TO_APIM:
        return 'mdi-send';
      case PublishingDeviceEventState.IN_PROGRESS:
        return 'mdi-progress-clock';
      case PublishingDeviceEventState.ERROR:
        return 'mdi-error';
      default:
        return '';
    }
  }

  getDeviceEventMessage({ id, type, state }: PublishingDeviceEventUpdateJSON): string {
    let eventMessage: string = '';
    switch (type) {
      case PublishingDeviceEventType.UPDATING_BACKGROUND:
        eventMessage = `${this.$t(this.$i18nTranslationKeys.notifications.eventType.updatingBackground.$path)}:`;
        break;
      case PublishingDeviceEventType.UPDATING_LABEL:
        eventMessage = `${this.$t(this.$i18nTranslationKeys.notifications.eventType.updatingLabel.$path)} ${
          parseInt(id.split('-').pop() || '') + 1
        }:`;
        break;
      case PublishingDeviceEventType.DELETING_LABEL:
        eventMessage = `${this.$t(this.$i18nTranslationKeys.notifications.eventType.deletingLabel.$path)}:`;
        break;
      case PublishingDeviceEventType.SYNCING_LAYER:
        eventMessage = `${this.syncLayerDetails.message}`;
        break;
      case PublishingDeviceEventType.INITIALIZING_DEVICE:
        eventMessage = `${this.$t(this.$i18nTranslationKeys.notifications.eventType.initializingDevice.$path)}:`;
        break;
      case PublishingDeviceEventType.SKIPPED_DEVICE:
        eventMessage = `${this.$t(this.$i18nTranslationKeys.notifications.eventType.deviceSkipped.$path)}:`;
        break;
      default:
        break;
    }
    return `${eventMessage} ${this.getDeviceEventState(state)}`;
  }

  getDeviceEventState(state: PublishingDeviceEventState | EventState): string {
    switch (state) {
      case PublishingDeviceEventState.SENT_TO_APIM:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.sentToAPIM.$path)}`;
      case PublishingDeviceEventState.SENDING_TO_APIM:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.sendingToAPIM.$path)}`;
      case PublishingDeviceEventState.IN_PROGRESS:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.inProgress.$path)}`;
      case PublishingDeviceEventState.ACCEPTED_FROM_APIM:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.accepted.$path)}`;
      case PublishingDeviceEventState.COMPLETED:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.completed.$path)}`;
      case PublishingDeviceEventState.ERROR:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.error.$path)}`;
      default:
        return '';
    }
  }

  getContentPreviewUrl(content: BaseLayerModel | ScheduleLayer | LayerPlaylistItem): string {
    return `${content.previewUrl || content.url}?${this.readToken}`;
  }

  get RailEventState(): typeof PublishingDeviceEventState {
    return PublishingDeviceEventState;
  }
  get ContentType(): typeof ContentType {
    return ContentType;
  }
}
