import { defineStore, StoreDefinition, Store } from 'pinia';
import { Breadcrumb, TranslateStringConfig, CurrentPage, PageTab } from './types';

export interface BreadcrumbsActions {
  push(data: Breadcrumb): void;
  replace(data: Breadcrumb): void;
  setCurrentPage(title: TranslateStringConfig, icon: string, tabs?: Array<PageTab>, isEditMode?: boolean): void;
  resetCurrentPage(): void;
}

export interface BreadcrumbsGetters {}

export interface BreadcrumbsState {
  breadcrumbs: Array<Breadcrumb>;
  currentPage: CurrentPage;
}

export type BreadcrumbsStoreDefinition = StoreDefinition<
  'breadcrumbs',
  BreadcrumbsState,
  BreadcrumbsGetters,
  BreadcrumbsActions
>;

export type BreadcrumbsStore = Store<'breadcrumbs', BreadcrumbsState, BreadcrumbsGetters, BreadcrumbsActions>;

export const useBreadcrumbsStore: BreadcrumbsStoreDefinition = defineStore('breadcrumbs', {
  state: (): BreadcrumbsState => ({
    breadcrumbs: Array<Breadcrumb>(),
    currentPage: { title: { key: '' }, icon: '', tabs: [], isEditMode: false },
  }),
  getters: {},
  actions: {
    push(breadcrumb: Breadcrumb) {
      const index: number = this.breadcrumbs.findIndex((storeBreadcrumb: Breadcrumb) => {
        return storeBreadcrumb.path === breadcrumb.path;
      });
      if (index >= 0) {
        this.breadcrumbs.splice(index + 1);
      } else {
        this.breadcrumbs.push(breadcrumb);
      }
    },
    replace(breadcrumb: Breadcrumb) {
      this.breadcrumbs = new Array<Breadcrumb>(breadcrumb);
    },
    setCurrentPage(title: TranslateStringConfig, icon: string, tabs?: Array<PageTab>, isEditMode: boolean = false) {
      this.currentPage.title = title;
      this.currentPage.icon = icon;
      this.currentPage.tabs = tabs;
      this.currentPage.isEditMode = isEditMode;
    },
    resetCurrentPage() {
      this.currentPage.title = { key: '' };
      this.currentPage.icon = '';
      this.currentPage.tabs = [];
      this.currentPage.isEditMode = false;
    },
  },
});
