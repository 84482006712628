
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FirmwareChangedEventLog } from '@client/models/EventLogModels/ReleaseManagementEventLog/FirmwareChangedEventLog.modal';
import { TranslateResult } from 'vue-i18n';
import { getDeviceDetailPath } from '@client/router/utils';
import { Optional } from '@common/types';
import { Store } from '@client/models';
import Notification from '@client/components/NotificationPanel/Notifications/Notification.vue';

@Component({
  components: { Notification },
})
export default class FirmwareChangedNotification extends Vue {
  /* DECLARATIONS */
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: FirmwareChangedEventLog;
  @Prop()
  private store: Optional<Store>;

  /* GETTERS */
  get notificationTitleIdentifier(): Optional<string> {
    return this.store?.name || this.eventLog.azureStoreId;
  }

  get linkToDeviceDetail(): string {
    if (!this.eventLog.device) {
      return '';
    }
    return getDeviceDetailPath(this.eventLog.device.longId);
  }

  get notificationTitle(): TranslateResult {
    return this.$t(this.$i18nTranslationKeys.notifications.firmwareChanged.title.$path, {
      device: this.eventLog.device?.longId,
    });
  }

  get deviceLongId(): string {
    return this.eventLog.device?.longId || '';
  }
}
