import { DeviceJSON } from '@common/device/types';
import { GondolaPublishingJSON } from '@common/publishing/types';
import { ActiveHoursScheduleJSON } from '@common/active-hours/types';

/**
 * Describes all types of event logs, used as a discriminator value
 */
export enum EventType {
  /**
   * Related to all publishing events
   */
  PUBLISHING = 'PUBLISHING',
  /**
   * Applies to "online" and "offline" events
   */
  ONLINE_STATUS = 'ONLINE_STATUS',
  /**
   * Applies to "registered" and "unregistered" events
   */
  REGISTERED_STATUS = 'REGISTERED_STATUS',
  /**
   * Applies to store active hours events
   */
  STORE_ACTIVE_HOURS = 'STORE_ACTIVE_HOURS',
  /**
   * Applies to section active hours events
   */
  SECTION_ACTIVE_HOURS = 'SECTION_ACTIVE_HOURS',
  /**
   * Applies to active hours config
   */
  ACTIVE_HOURS_CONFIG = 'ACTIVE_HOURS_CONFIG',
  /**
   * Applies to firmware released event
   */
  FIRMWARE_RELEASED = 'FIRMWARE_RELEASED',
  /**
   * Applies to firmware changed event
   */
  FIRMWARE_CHANGED = 'FIRMWARE_CHANGED',
  /**
   * Applies to store sync events
   */
  STORE_SYNC = 'STORE_SYNC',
}

/**
 * General state of the event,
 * Indicates the state of a whole publishing
 */
export enum EventState {
  /**
   * State after the APIM reports it started executing
   */
  IN_PROGRESS = 'IN_PROGRESS',
  /**
   * State after APIM reports that it accepted an event
   */
  ACCEPTED = 'ACCEPTED',
  /**
   * State if the APIM reports and ERROR
   */
  ERROR = 'ERROR',
  /**
   * State if the APIM reports that the action is completed
   */
  COMPLETED = 'COMPLETED',
  /**
   * State if a new publishing is sent while another publishing on the same device is pending.
   */
  CANCELED = 'CANCELED',
}

/**
 * General state of the device event,
 * Indicates the state of an individual device state
 */
export enum PublishingDeviceEventState {
  /**
   * State before sending the request to APIM
   */
  SENDING_TO_APIM = 'SENDING_TO_APIM',
  /**
   * State after successfully sending the request to APIM
   */
  SENT_TO_APIM = 'SENT_TO_APIM',
  /**
   * State after APIM returns an accepted status
   */
  ACCEPTED_FROM_APIM = 'ACCEPTED_FROM_APIM',
  /**
   * State after the APIM reports it started executing
   */
  IN_PROGRESS = 'IN_PROGRESS',
  /**
   * State if the APIM reports and ERROR
   */
  ERROR = 'ERROR',
  /**
   * State if the APIM reports that the action is completed
   */
  COMPLETED = 'COMPLETED',
  /**
   * State if the APIM reports that the action is cancelled
   */
  CANCELED = 'CANCELED',
}

/**
 * Specific device update event
 */
export enum PublishingDeviceEventType {
  /**
   * Skipped device, when a device is already published in case of a retry
   */
  SKIPPED_DEVICE = 'SKIPPED_DEVICE',
  /**
   * Device is being initialized, getting data from apim
   */
  INITIALIZING_DEVICE = 'INITIALIZING_DEVICE',
  /**
   * Content update events
   */
  SYNCING_LAYER = 'SYNCING_LAYER',
  DELETING_LABEL = 'DELETING_LABEL',
  UPDATING_LABEL = 'UPDATING_LABEL',
  UPDATING_BACKGROUND = 'UPDATING_BACKGROUND',
}

export enum DeviceStatusEventType {
  /**
   * Applies to "online" events
   */
  ONLINE = 'ONLINE',
  /**
   * Applies to "offline" events
   */
  OFFLINE = 'OFFLINE',
  /**
   * Applies to "registered" events
   */
  REGISTERED = 'REGISTERED',
  /**
   * Applies to "unregistered" events
   */
  UNREGISTERED = 'UNREGISTERED',
}

/**
 * Active hours update event types
 */
export enum ActiveHoursEventType {
  /**
   * Activated means the device is in powersave mode
   */
  ACTIVE_HOURS_ACTIVATED = 'ACTIVE_HOURS_ACTIVATED',
  /**
   * Deactivated means there is a config set but the device is not in power-save mode,
   * Can be caused by a few reasons,
   * No time for powersave yet or connect app sends some beacon to turn the device online in scan page
   */
  ACTIVE_HOURS_DEACTIVATED = 'ACTIVE_HOURS_DEACTIVATED',
  /**
   * Disabled means no config is set
   */
  ACTIVE_HOURS_DISABLED = 'ACTIVE_HOURS_DISABLED',
  /**
   * Enabled means a config is set (but not yet active)
   */
  ACTIVE_HOURS_ENABLED = 'ACTIVE_HOURS_ENABLED',
}

/**
 * Base object for event logs
 */
export interface BaseEventLogJSON {
  _id: string;
  schemaVersion: number;
  /**
   * Type of the event log
   */
  type: EventType;
  /**
   * Azure id related to the event log
   */
  azureStoreId: string;

  storeId: string;
  /**
   * Customer id related to the event log
   */
  customerId: string;
  /**
   * Creation timestamp
   */
  createdAt: Date;
  /**
   * Last updated timestamp
   */
  updatedAt: Date;
}

export interface DeviceStatusEventLogJSON extends BaseEventLogJSON {
  device?: DeviceJSON;
  deviceStatus: DeviceStatusEventType;
  eventExecutionTimestamp: string;
  longId: string;
  shortId: string;
}

export interface SectionActiveHoursEventLogJSON extends BaseEventLogJSON {
  gondolaId: string;
  activeHoursStatus: ActiveHoursEventType;
  devices: Array<SectionActiveHoursDeviceEventJSON>;
}

export interface StoreActiveHoursDeviceEventJSON {
  longId: string;
  shortId: string;
  dimmingLevel: number;
  activeHoursStatus: ActiveHoursEventType;
  dump: string;
  eventExecutionTimestamp: string;
}

export interface SectionActiveHoursDeviceEventJSON extends StoreActiveHoursDeviceEventJSON {
  transactionId: string;
}

export interface StoreActiveHoursEventLogJSON extends BaseEventLogJSON {
  transactionId: string;
  activeHoursStatus: ActiveHoursEventType;
  devices: Array<StoreActiveHoursDeviceEventJSON>;
}

export interface PublishingEventLogJSON extends BaseEventLogJSON {
  publishing: GondolaPublishingJSON;
  positionInAisle: string;
  aisle: string;
  gondolaId: string;
  devices: Array<PublishingDeviceEventJSON>;
  nextRunAt?: string;
}

export interface SupportedApplicationsJSON {
  application: string;
  deviceTypes: Array<string>;
}

export interface FirmwareReleasedEventLogJSON extends BaseEventLogJSON {
  build: string;
  version: string;
  branch: string;
  id: string;
  releasedNote: string;
  releasedDatetime: string;
  executionDatetime: string;
  supportedApplications: Array<SupportedApplicationsJSON>;
  supportedDevices: Array<DeviceJSON>;
}

export interface FirmwareChangedEventLogJSON extends BaseEventLogJSON {
  device?: DeviceJSON;
  timestamp: string;
  applicationPackageVersion: string;
  applicationSystemVersion: string;
}

/**
 * JSON object of an active hours config event log
 * @extends BaseEventLogJSON
 */
export interface ActiveHoursConfigEventLogJSON extends BaseEventLogJSON {
  /**
   * If this is not set, it is a store level config
   */
  gondolaId: string;
  /**
   * If this is not set, this a delete operation
   */
  activeHoursConfig?: ActiveHoursScheduleJSON;
}

/**
 * JSON object of a store sync event log
 */
export interface StoreSyncEventLogJSON extends BaseEventLogJSON {
  manual: boolean;
  isWebhookSynced: boolean;
  addedDevices: Array<DeviceJSON>;
  removedDevices: Array<DeviceJSON>;
  updatedDevices: Array<DeviceJSON>;
}

export type EventLogJSON =
  | BaseEventLogJSON
  | PublishingEventLogJSON
  | StoreActiveHoursEventLogJSON
  | SectionActiveHoursEventLogJSON
  | DeviceStatusEventLogJSON
  | FirmwareReleasedEventLogJSON;

export interface PublishingDeviceEventJSON {
  longId: string;
  shortId: string;
  state: EventState;
  transactionIds: Array<string>;
  events: Array<PublishingDeviceEventUpdateJSON>;
  numberOfExpectedSuccessfulContentEvents: number;
}

export interface PublishingDeviceEventUpdateJSON {
  message: string;
  id: string;
  state: PublishingDeviceEventState;
  type: PublishingDeviceEventType;
  timestamp: string;
}

export interface EventLogSearchResult {
  eventLogs: Array<EventLogJSON>;
  count: number;
  pageSize: number;
  page: number;
}
