
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import PlaylistModel, { PlaylistItem } from '@client/models/ContentModels/Playlist.model';
import { ContentItemSelectionTarget } from '@client/enums';
import videoDurationParser from '@client/utils/videoDurationParser';
import { ContentType } from '@common/enums';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { TranslateResult } from 'vue-i18n';
import VideoModel from '@client/models/ContentModels/Video.model';
import { getFPSDisplayValue } from '@client/components/ContentItems/utils';
import WarningMessage from '@client/components/Layouts/WarningMessage.vue';

@Component({
  components: {
    WarningMessage,
    // Needs to be imported this way because it is a recursive call
    ContentItemsList: () => import('../ContentItems/ContentItemsList.vue'),
    draggable,
  },
  methods: {
    validateTextFieldLength,
    videoDurationParser,
    getFPSDisplayValue,
  },
})
export default class PlaylistDialog extends Vue {
  /* DECLARATIONS */
  @Prop({ default: false })
  private showPlaylistDialog!: boolean;
  @Prop()
  selectedFolder!: string;
  @Prop({
    default: () => PlaylistModel.getDefaultEmptyPlaylist(),
  })
  private playlistContentItem!: PlaylistModel;
  @Prop({
    default: () => {
      // Will be overridden later
    },
  })
  cancelHandler!: () => void;
  @Prop()
  private currentlyActiveContentItemName?: string;
  @Prop()
  private dataCy!: string;
  private showContentItemDialog: boolean = false;
  private playlistName: string = '';
  private playlistItems: Array<PlaylistItem> = new Array<PlaylistItem>();

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();

  /* LIFECYCLE EVENTS */
  /* METHODS */
  @Watch('playlistContentItem')
  updateContent(): void {
    if (this.playlistContentItem) {
      this.playlistName = this.playlistContentItem?.name || '';
      this.playlistItems = this.playlistContentItem.playlist.map((playlistItem: PlaylistItem) => ({
        hideLabels: playlistItem.hideLabels,
        contentItem: playlistItem.contentItem,
      }));
    }
  }

  addVideoToPlaylist(item: VideoModel): void {
    this.playlistItems.push({ contentItem: item, hideLabels: false });
    this.showContentItemDialog = false;
  }

  closeDialog(): void {
    this.playlistName = '';
    this.playlistItems = new Array<PlaylistItem>();
    this.cancelHandler();
  }

  openDialog(): void {
    this.showContentItemDialog = true;
  }

  async confirmHandler(): Promise<void> {
    if (this.playlistName.length > DEFAULT_COMMON_STRING_MAX_LENGTH || !this.playlistName) {
      return;
    }
    const playlistToAddOrEdit: PlaylistModel = PlaylistModel.newPlaylistContentItemBuilder(
      this.playlistName,
      this.playlistItems,
      this.appGlobalStore.customer,
      this.playlistContentItem._id ? this.playlistContentItem.parentFolder : this.selectedFolder
    );
    if (this.playlistContentItem._id) {
      playlistToAddOrEdit._id = this.playlistContentItem._id;
      playlistToAddOrEdit.hash = this.playlistContentItem.hash;
      await this.contentItemsStore.updateContentItem(playlistToAddOrEdit);
    } else {
      await this.contentItemsStore.createContentItem(playlistToAddOrEdit);
    }
    this.playlistName = '';
    this.playlistItems = new Array<PlaylistItem>();
    this.cancelHandler();
  }

  removeVideoFromPlaylist(index: number): void {
    this.playlistItems.splice(index, 1);
  }

  closeContentItemDialog(): void {
    this.showContentItemDialog = false;
  }
  /* GETTERS */

  get DEFAULT_COMMON_STRING_MAX_LENGTH(): number {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  get ContentType(): typeof ContentType {
    return ContentType;
  }

  get ContentItemSelectionTarget(): typeof ContentItemSelectionTarget {
    return ContentItemSelectionTarget;
  }

  get selectedSchedule(): string | undefined {
    return this.schedulesStore.selectedSchedule;
  }

  get dialogTitle(): TranslateResult {
    return this.playlistContentItem._id
      ? `${this.$t(this.$i18nTranslationKeys.action.edit.$path)} ${this.$t(
          this.$i18nTranslationKeys.deviceView.playlist.$path
        )}`
      : this.$t(this.$i18nTranslationKeys.action.createEntity.$path, {
          entity: this.$tc(this.$i18nTranslationKeys.deviceView.playlist.$path),
        });
  }

  get playlistNameTitle(): string {
    return `${this.$t(this.$i18nTranslationKeys.deviceView.playlist.$path)} ${this.$t(
      this.$i18nTranslationKeys.deviceView.name.$path
    )}`;
  }
}
