import Vue, { CreateElement } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n/i18n';
import Component from 'vue-class-component';
import { PiniaVuePlugin } from 'pinia';
import pinia from './stores';
import VueRouter from 'vue-router';
import VueTour from 'vue-tour';
import TypedI18nPlugin from './plugins/i18n/TypedI18nPlugin';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from './stores/gondolaTemplates';
import './styles/__theme.scss';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  RadialLinearScale,
  PointElement,
  Tooltip,
  Title,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import VueMsalBrowser from '@client/plugins/vue-msal-browser';

// Explicitly register the required components
Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  Filler,
  PointElement,
  LineElement,
  LineController,
  RadialLinearScale,
  ChartDataLabels
);
Vue.config.productionTip = false;

Vue.use(VueMsalBrowser, {
  auth: {
    validateAuthority: false,
  },
  request: {
    scopes: ['openid', 'profile', 'offline_access'],
  },
  framework: {
    globalMixin: true,
  },
});

// Install the vue tour plugin
Vue.use(VueTour);

// Install pinia plugin
Vue.use(PiniaVuePlugin);

// Install router plugin
Vue.use(VueRouter);

// Install Typed I18n plugin
Vue.use(TypedI18nPlugin);

// Register the router hooks with their names
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave']);

// Create Vue App
export const EventBus: Vue = new Vue({
  i18n,
  pinia,
  router,
  vuetify,
  render: (h: CreateElement) => h(App),
  async created() {
    if (!this.$msal.isAuthenticated()) {
      await this.$msal.signIn();
    }
    const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
    gondolaTemplatesStore.loadGondolaZoomFromLocalStorage();
  },
}).$mount('#app');
