
import { Component, Vue } from 'vue-property-decorator';
import { GondolaTemplate as GondolaTemplateModel } from '@client/models';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import GondolaTemplate from '@client/components/GondolaTemplate/GondolaTemplate.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import GondolaTemplateListToolbar from '@client/components/GondolaTemplate/GondolaTemplateListToolbar.vue';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';

/**
 * Renders a list of gondola templates.
 * Also contains the toolbar that allows adding and filtering gondola templates.
 */
@Component({
  components: {
    GondolaTemplateListToolbar,
    GondolaTemplate,
  },
})
export default class GondolaTemplateListView extends Vue {
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private contentItemStore: ContentItemsStore = useContentItemsStore();
  private tagsStore: TagsStore = useTagsStore();

  created(): void {
    this.schedulesStore.clearSelectedSchedule();
    this.breadcrumbsStore.replace({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.gondolaTemplateListView.breadcrumb.$path },
    });
    this.breadcrumbsStore.setCurrentPage(
      { key: this.$i18nTranslationKeys.gondolaTemplateListView.breadcrumb.$path },
      'mdi-clipboard-multiple'
    );
    this.gondolaTemplatesStore.clearSearchFilters();
    this.gondolaTemplatesStore.fetch();
    this.tagsStore.fetch();
    this.contentItemStore.fetchContentItems();
  }

  /* GETTERS */

  get gondolaTemplates(): Array<GondolaTemplateModel> {
    return this.gondolaTemplatesStore.searchResults.results;
  }
}
