import { render, staticRenderFns } from "./SectionActiveHoursNotification.vue?vue&type=template&id=dabed354"
import script from "./SectionActiveHoursNotification.vue?vue&type=script&lang=ts"
export * from "./SectionActiveHoursNotification.vue?vue&type=script&lang=ts"
import style0 from "./SectionActiveHoursNotification.vue?vue&type=style&index=0&id=dabed354&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports