
import { Component, Vue } from 'vue-property-decorator';
import Header from '@client/components/Layouts/Header.vue';
import NavigationWrapper from '../components/Layouts/NavigationWrapper.vue';
import { Route } from 'vue-router/types/router';
import { NextFunction } from 'express';
import { MSALPublic } from '@client/plugins/vue-msal-browser/plugin';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { checkForRouteException } from '@client/router/utils';
import router from '@client/router';
import { AccountInfo } from '@azure/msal-browser';
import { IdTokenClaims } from '@client/models/UserModels';

@Component({
  components: { NavigationWrapper, Header },
})
export default class Index extends Vue {
  /* DECLARATIONS */
  private authService: MSALPublic = this.$msal;

  private appGlobal: AppGlobalStore = useAppGlobalStore();
  /* LIFECYCLE EVENTS */
  async created() {
    await this.checkAuthentication();
    router.beforeEach(async (to: Route, _from: Route, next: NextFunction) => {
      const breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();

      if (!checkForRouteException(to.name)) {
        breadcrumbsStore.resetCurrentPage();
      }

      await this.checkAuthentication();

      // If the user is authenticated, continue with the route
      if (this.authService.isAuthenticated()) {
        return next();
      }

      // Otherwise, log in
      await this.authService.signIn(to.fullPath);

      return;
    });
  }

  /* METHODS */
  async checkAuthentication(): Promise<void> {
    if (!this.authService.state.isInitialized) {
      await this.authService.initialize();
    }

    if (this.authService.isAuthenticated()) {
      const b2cUser: AccountInfo | null = this.authService.state.user;
      if (!b2cUser?.idTokenClaims) {
        return undefined;
      }
      const idTokenClaims: IdTokenClaims = b2cUser.idTokenClaims as unknown as IdTokenClaims;
      const customer: string = this.appGlobal.getCustomer;
      if (customer != idTokenClaims.extension_companyId) {
        this.appGlobal.setUser(idTokenClaims.aud);
        this.appGlobal.setCustomer(idTokenClaims.extension_companyId);
      }
    } else {
      await this.authService.signIn();
    }
  }
  /* GETTERS */
}
