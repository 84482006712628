
import { Component, Vue } from 'vue-property-decorator';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { PublishingsStore, usePublishingsStore } from '@client/stores/publishings';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { GondolaPublishing, GondolaTemplate, Store } from '@client/models';
import { PublishingSensorSearchResult } from '@client/stores/publishings/types';
import SensorViewLoader from '@client/components/SensorAnalytics/SensorViewLoader.vue';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { PublishingSensorDataTableItem } from '@client/models/SensorModels/PublishingSensorDataTableItem';
import SensorPublishingDataTable from '@client/components/SensorAnalytics/SensorPublishingDataTable.vue';
import SensorDetail from '@client/components/SensorAnalytics/SensorDetail.vue';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { ContentItem } from '@client/models/ContentModels/types';

@Component({
  components: { SensorDetail, SensorPublishingDataTable, SensorViewLoader },
})
export default class SensorView extends Vue {
  /* DECLARATIONS */
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private publishingsStore: PublishingsStore = usePublishingsStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private storesStore: StoresStore = useStoresStore();
  private devicesStore: DevicesStore = useDevicesStore();

  private isDataLoaded: boolean = false;
  private maximumPageNumber: number = 0;
  private currentPageNumber: number = 0;

  private storesMap: Map<string, Store> = new Map<string, Store>();
  private templatesMap: Map<string, GondolaTemplate> = new Map<string, GondolaTemplate>();

  private publishings: Array<PublishingSensorDataTableItem> = [];
  private contentItems: Array<ContentItem> = [];

  private selectedPublishing: string = '';
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    this.breadcrumbsStore.replace({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.sensors.navigationTitle.$path },
    });
    this.breadcrumbsStore.setCurrentPage(
      { key: this.$i18nTranslationKeys.sensors.navigationTitle.$path },
      'mdi-chart-line'
    );
    await this.devicesStore.fetch();
    await this.storesStore.fetch();
    await this.gondolaTemplatesStore.fetch();
    this.contentItems = await this.contentItemsStore.fetchContentItemsWithArchived();
    this.storesStore.getStores().forEach((store: Store) => {
      this.storesMap.set(store._id, store);
    });
    this.gondolaTemplatesStore.getTemplates().forEach((template: GondolaTemplate) => {
      if (!template._id) {
        return;
      }
      this.templatesMap.set(template._id, template);
    });
    const { publishings, totalPages }: PublishingSensorSearchResult =
      await this.publishingsStore.fetchPublishingsHavingSensorData(this.currentPageNumber);
    this.populatePublishings(publishings);
    this.maximumPageNumber = totalPages;
    this.isDataLoaded = true;
  }
  /* METHODS */

  private populatePublishings(publishings: Array<GondolaPublishing>): void {
    publishings.forEach((publishing: GondolaPublishing) => {
      const store: Store | undefined = this.storesMap.get(publishing.storeId);
      if (!store) {
        return;
      }
      let template: GondolaTemplate | undefined;
      if (publishing.gondolaTemplateId) {
        template = this.templatesMap.get(publishing.gondolaTemplateId);
      }
      this.publishings.push(new PublishingSensorDataTableItem(publishing, store, template));
    });
  }

  private onOpenPublishDetails(publishingId: string): void {
    this.selectedPublishing = publishingId;
  }

  private onReturn(): void {
    this.selectedPublishing = '';
  }

  /* GETTERS */
  get isLoading(): boolean {
    return this.storesStore.isFetching || this.gondolaTemplatesStore.isFetching;
  }

  get selectedPublishingObject(): PublishingSensorDataTableItem | undefined {
    if (!this.selectedPublishing) {
      return undefined;
    }
    return this.publishings.find(
      (sensorDataTableItem: PublishingSensorDataTableItem) =>
        sensorDataTableItem.publishing._id === this.selectedPublishing
    );
  }
}
