import { defineStore, StoreDefinition, Store } from 'pinia';
import { GondolaPublishing } from '@client/models';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Config from '@client/utils/config';
import {
  BulkPublishingJSON,
  GondolaPublishingJSON,
  PublishingSensorSearchResponse,
  ValidatedPublishingJSON,
} from '@common/publishing/types';
import { Optional } from '@common/types';
import { PublishingSensorSearchResult } from '@client/stores/publishings/types';

export interface PublishingsGetters {
  getPublishingsByGondolaId: (state: PublishingsState) => (templateId: string) => Array<GondolaPublishing>;
  getPublishingsForSection: (state: PublishingsState) => (gondolaId: string) => Optional<GondolaPublishing>;
}

export interface PublishingsActions {
  addOrUpdate(data: { templateId: string; publishings: Array<GondolaPublishing> }): void;
  fetchByTemplateId(data: { templateId: string }): Promise<void>;
  fetchPublishingsForSections(sections: Array<string>): Promise<void>;
  fetchPublishingsHavingSensorData(page: number): Promise<PublishingSensorSearchResult>;
  publish(bulkPublishingJSON: BulkPublishingJSON): Promise<boolean>;
  validate(bulkPublishingJSON: BulkPublishingJSON): Promise<Optional<ValidatedPublishingJSON>>;
}

export interface PublishingsState {
  publishings: Map<string, Array<GondolaPublishing>>;
}

export type PublishingsStoreDefinition = StoreDefinition<
  'publishings',
  PublishingsState,
  PublishingsGetters,
  PublishingsActions
>;

export type PublishingsStore = Store<'publishings', PublishingsState, PublishingsGetters, PublishingsActions>;

export const usePublishingsStore: PublishingsStoreDefinition = defineStore('publishings', {
  state: (): PublishingsState => ({
    publishings: new Map<string, Array<GondolaPublishing>>(),
  }),
  getters: {
    getPublishingsByGondolaId: (state: PublishingsState) => {
      return (templateId: string) => Array.from(state.publishings.get(templateId) || []);
    },
    getPublishingsForSection:
      (state: PublishingsState) =>
      (gondolaId: string): Optional<GondolaPublishing> => {
        const publishings: GondolaPublishing[] = Array.from(state.publishings.values()).flatMap(
          (publishings: GondolaPublishing[]) =>
            publishings.filter((publishing: GondolaPublishing) => publishing.gondolaId === gondolaId)
        );
        if (!publishings.length) {
          return undefined;
        }
        return publishings.reduce(
          (prev: Optional<GondolaPublishing>, current: Optional<GondolaPublishing>): Optional<GondolaPublishing> => {
            if (!prev) {
              return current;
            }
            const currentTimestamp: Optional<Date> = current?.publishInformation?.timestamp;
            const prevTimestamp: Date = prev?.publishInformation?.timestamp;

            return currentTimestamp && currentTimestamp > prevTimestamp ? current : prev;
          },
          null
        );
      },
  },
  actions: {
    addOrUpdate(data: { templateId: string; publishings: Array<GondolaPublishing> }): void {
      this.publishings.set(data.templateId, data.publishings);
    },
    async fetchByTemplateId(data: { templateId: string }): Promise<void> {
      try {
        const response: AxiosResponse<Array<GondolaPublishingJSON>> = await axios.get(
          `${Config.getApiUrl()}/publishing/by-gondola-template/${data.templateId}`
        );
        if (response.status) {
          const publishings: Array<GondolaPublishing> = response.data.map(GondolaPublishing.fromJSON);
          this.addOrUpdate({
            templateId: data.templateId,
            publishings: publishings,
          });
        }
      } catch (e) {
        const error: Error | AxiosError = e as Error | AxiosError;
        if (!axios.isAxiosError(error)) {
          console.error(error.message);
          return;
        }
        console.error(error.response?.status, error.response?.statusText);
      }
    },
    async fetchPublishingsForSections(sections: Array<string>): Promise<void> {
      try {
        const response: AxiosResponse<Array<GondolaPublishingJSON>> = await axios.post(
          `${Config.getApiUrl()}/publishing/by-sections/`,
          { sections: sections }
        );
        if (response.status < 400) {
          const publishings: Array<GondolaPublishing> = response.data.map(GondolaPublishing.fromJSON);
          publishings.forEach((publishing: GondolaPublishing) => {
            if (!publishing.gondolaTemplateId) {
              return;
            }
            const publishingsToBeUpdated: Array<GondolaPublishing> = this.getPublishingsByGondolaId(
              publishing.gondolaTemplateId
            );
            publishingsToBeUpdated.push(publishing);
            this.addOrUpdate({ templateId: publishing.gondolaTemplateId, publishings: publishingsToBeUpdated });
          });
        }
      } catch (e) {
        const error: Error | AxiosError = e as Error | AxiosError;
        if (!axios.isAxiosError(error)) {
          console.error(error.message);
          return;
        }
        console.error(error.response?.status, error.response?.statusText);
      }
    },
    async publish(bulkPublishingJSON: BulkPublishingJSON): Promise<boolean> {
      try {
        const response: AxiosResponse<Array<GondolaPublishingJSON>> = await axios.post(
          `${Config.getApiUrl()}/publishing`,
          bulkPublishingJSON,
          {
            params: {
              skipValidation: true,
            },
          }
        );
        return response.status < 400;
      } catch (e) {
        const error: Error | AxiosError = e as Error | AxiosError;
        if (!axios.isAxiosError(error)) {
          console.error(error.message);
          return false;
        }
        console.error(error.response?.status, error.response?.statusText);
      }
      return false;
    },
    async validate(bulkPublishingJSON: BulkPublishingJSON): Promise<Optional<ValidatedPublishingJSON>> {
      try {
        const response: AxiosResponse<ValidatedPublishingJSON> = await axios.post(
          `${Config.getApiUrl()}/publishing/validate/`,
          bulkPublishingJSON
        );
        return response.data;
      } catch (e) {
        const error: Error | AxiosError = e as Error | AxiosError;
        if (!axios.isAxiosError(error)) {
          console.error(error.message);
          return null;
        }
        console.error(error.response?.status, error.response?.statusText);
      }
    },
    async fetchPublishingsHavingSensorData(page: number): Promise<PublishingSensorSearchResult> {
      try {
        const response: AxiosResponse<PublishingSensorSearchResponse> = await axios.get(
          `${Config.getApiUrl()}/publishing/sensors`,
          {
            params: {
              page: page,
            },
          }
        );
        return {
          publishings: response.data.publishings.map(GondolaPublishing.fromJSON),
          totalPages: response.data.totalPages,
          currentPage: response.data.currentPage,
        };
      } catch (e) {
        const error: Error | AxiosError = e as Error | AxiosError;
        if (!axios.isAxiosError(error)) {
          console.error(error.message);
          return {
            publishings: [],
            totalPages: 0,
            currentPage: 0,
          };
        }
        console.error(error.response?.status, error.response?.statusText);
        return {
          publishings: [],
          totalPages: 0,
          currentPage: 0,
        };
      }
    },
  },
});
