import { SectionValidationResult } from '@client/stores/stores/types';
import { ValidationStatus } from '@common/error/types';
import { Device, Gondola, Store } from '@client/models';
import { Optional } from '@common/types';
import { ValidationErrorType } from '@common/stores/error';

export interface SectionValidation {
  areSectionsValid: boolean;
  shouldRerender: boolean;
  devicesToMoveFromOtherSections: Array<Device>;
}

/**
 * Validates the sections and returns the validation result
 * @param sections the sections to validate
 * @param sectionValidations the validation results for the sections, this property will be mutated
 * @param store the store
 * @param isEditMode whether the sections are in edit mode
 */
export const validateSections = (
  sections: Gondola[],
  sectionValidations: Array<SectionValidationResult>,
  store: Store,
  isEditMode: boolean = false
): SectionValidation => {
  let areSectionsValid: boolean = true;
  let sectionHasSensorDevice: boolean = false;
  const devicesToMoveFromOtherSections: Array<Device> = [];
  let shouldRerender: boolean = false;

  const deviceLongIds: Set<string> = new Set();
  const deviceShortIds: Set<string> = new Set();
  for (const [index, section] of sections.entries()) {
    const sectionValidation: SectionValidationResult = sectionValidations[index];
    sectionValidation.errors = [];
    sectionValidation.hasError = false;
    sectionValidation.generalSeverity = ValidationStatus.VALID;
    for (let row: number = 0; row < section.railGrid.length; row++) {
      for (let column: number = 0; column < section.railGrid[row].length; column++) {
        const device: Device = section.railGrid[row][column];
        if (!device.longId && !device.shortId) {
          continue;
        }
        let shouldCheckForDuplicates: boolean = !!device.gondolaId;
        if (isEditMode) {
          shouldCheckForDuplicates = !!device.gondolaId && device.gondolaId !== section._id;
        }
        if (shouldCheckForDuplicates) {
          sectionValidation.hasError = true;
          if (sectionValidation.generalSeverity !== ValidationStatus.CRITICAL_ERROR) {
            sectionValidation.generalSeverity = ValidationStatus.FORCIBLE_ERROR;
          }
          let duplicateSection: Optional<Gondola>;
          if (isEditMode) {
            duplicateSection = store.gondolas.find(
              (gondola: Gondola) => gondola._id === device.gondolaId && gondola._id !== section._id
            );
          } else {
            duplicateSection = store.gondolas.find((gondola: Gondola) => gondola._id === device.gondolaId);
          }
          sectionValidation.errors.push({
            severity: ValidationStatus.FORCIBLE_ERROR,
            column: column,
            row: row,
            message: {
              arguments: [
                device.shortId,
                device.storeId,
                device.gondolaId,
                duplicateSection?.aisle || '',
                duplicateSection?.positionInAisle || '',
              ],
              type: ValidationErrorType.DEVICE_DUPLICATE,
            },
          });
          devicesToMoveFromOtherSections.push(device);
          shouldRerender = true;
        }
        if (!isValidDeviceId(device.longId) && !isValidDeviceId(device.shortId)) {
          sectionValidation.hasError = true;
          sectionValidation.generalSeverity = ValidationStatus.CRITICAL_ERROR;
          sectionValidation.errors.push({
            severity: ValidationStatus.CRITICAL_ERROR,
            column: column,
            row: row,
            message: {
              arguments: [device.shortId],
              type: ValidationErrorType.INVALID_DEVICE_ID,
            },
          });
          shouldRerender = true;
        }
        if (deviceLongIds.has(device.longId) || deviceShortIds.has(device.shortId)) {
          sectionValidation.hasError = true;
          sectionValidation.generalSeverity = ValidationStatus.CRITICAL_ERROR;
          sectionValidation.errors.push({
            severity: ValidationStatus.CRITICAL_ERROR,
            column: column,
            row: row,
            message: {
              arguments: [device.shortId],
              type: ValidationErrorType.LOCAL_DUPLICATE_DEVICE,
            },
          });
          areSectionsValid = false;
          shouldRerender = true;
        }
        if (device.sensor && sectionHasSensorDevice) {
          sectionValidation.hasError = true;
          sectionValidation.generalSeverity = ValidationStatus.CRITICAL_ERROR;
          sectionValidation.errors.push({
            severity: ValidationStatus.CRITICAL_ERROR,
            column: column,
            row: row,
            message: {
              arguments: [device.shortId],
              type: ValidationErrorType.SENSOR_DEVICE_DUPLICATE,
            },
          });
          areSectionsValid = false;
          shouldRerender = true;
        }
        if (device.sensor) {
          sectionHasSensorDevice = true;
        }
        deviceLongIds.add(device.longId);
        deviceShortIds.add(device.shortId);
      }
    }
  }

  return {
    areSectionsValid,
    shouldRerender,
    devicesToMoveFromOtherSections,
  };
};

const isValidDeviceId = (deviceId: string): boolean => {
  if (!deviceId) {
    return false;
  }

  return !!deviceId.match(/^[0-9]{5}$/);
};
