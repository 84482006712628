
import { Component, Prop, Vue } from 'vue-property-decorator';
import router from '@client/router';
import Preview from '../Preview/Preview.vue';
import { BackgroundContent, ForegroundContent, HardwareModel, DeviceTemplate, Store } from '@client/models';
import DeviceGroup, { UNLINKED_GROUP_COLOR } from '@client/models/DeviceModels/DeviceGroup.model';
import { Optional } from '@common/types';
import {
  TOUR_DEVICE_CONTAINER_TARGET,
  TOUR_DEVICE_DROPDOWN_TARGET,
  TOUR_DEVICE_EDIT_TARGET,
} from '@client/components/DeviceTemplateEditTour/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { StoresStore, useStoresStore } from '@client/stores/stores';

@Component({
  components: {
    Preview,
  },
})
export default class Device extends Vue {
  @Prop() private deviceTemplate!: DeviceTemplate;
  @Prop() private gondolaTemplateId!: string;
  @Prop() private row!: number;
  @Prop() private col!: number;
  @Prop() private getCurrentlyDisplayedScheduleForTemplate!: boolean;
  @Prop() private firstDeviceInTemplate!: boolean;

  private sizes: HardwareModel[] = HardwareModel.getAll();

  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private storesStore: StoresStore = useStoresStore();

  private deviceGroup: DeviceGroup = this.deviceTemplate.railGroup;
  private hardwareModel: HardwareModel = this.deviceTemplate.hardwareModel;

  created() {
    this.deviceGroup = this.getDeviceGroup;
    this.hardwareModel = this.deviceTemplate.hardwareModel;
  }

  setDeviceGroup(railGroup: DeviceGroup): void {
    this.deviceGroup = railGroup;
    this.$emit('device-group-change', this.row, this.col, this.deviceGroup);
  }

  editDevice(): void {
    router.push({
      path: `/template/${this.gondolaTemplateId}/device/${this.row}/${this.col}`,
    });
  }

  changeSize(element: string): void {
    this.hardwareModel = HardwareModel.getByIdentifier(element);
    this.$emit('hardware-model-change', this.row, this.col, this.hardwareModel);
  }

  get getSize(): string {
    return `device-${this.hardwareModel.identifier}`;
  }

  get getDeviceGroups(): DeviceGroup[] {
    return DeviceGroup.DeviceGroups;
  }

  get getSelectedSchedule(): string {
    const storeModel: Optional<Store> = this.storesStore.getStoreById(this.$route.params.storeid);
    if (this.getCurrentlyDisplayedScheduleForTemplate && storeModel) {
      return this.schedulesStore.getCurrentlyDisplayedScheduleForTemplate(this.gondolaTemplateId, storeModel.timezone);
    }
    return this.schedulesStore.getSelectedSchedule();
  }

  get getDeviceGroup(): DeviceGroup {
    return this.deviceTemplate.railGroup;
  }

  get getForegroundContentItems(): Array<ForegroundContent> {
    return this.deviceTemplate?.foregroundContent || [];
  }

  get getBackgroundContentItem(): BackgroundContent | undefined {
    return this.deviceTemplate?.backgroundContent?.[0];
  }

  get deviceContainerId(): string | null {
    if (!this.firstDeviceInTemplate) {
      return null;
    }
    return TOUR_DEVICE_CONTAINER_TARGET;
  }

  get railEditButtonId(): string | null {
    if (!this.firstDeviceInTemplate) {
      return null;
    }
    return TOUR_DEVICE_EDIT_TARGET;
  }

  get railEditModelId(): string | null {
    if (!this.firstDeviceInTemplate) {
      return null;
    }
    return TOUR_DEVICE_DROPDOWN_TARGET;
  }

  get railContainerClass(): string {
    const defaultClass: string = `device-select-size device-select-hardware-model device-${this.hardwareModel.identifier}`;
    if (!this.deviceHasContent) {
      return defaultClass;
    }

    return `${defaultClass} device-select-hidden`;
  }

  get deviceHasContent(): boolean {
    return !!this.getBackgroundContentItem?.baseLayer || !!this.getForegroundContentItems.length;
  }

  get railGroupColor(): string {
    return this.deviceGroup.color ?? UNLINKED_GROUP_COLOR;
  }

  get railGroupIconColor(): string {
    return this.deviceGroup.isUnlinked ? 'error' : 'white';
  }

  get isDeviceGroupUnlinked(): boolean {
    return this.deviceGroup.isUnlinked === true;
  }
}
