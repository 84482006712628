
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class NotificationStatusChip extends Vue {
  @Prop()
  private color!: string;
  @Prop()
  private text!: string | TranslateResult;
}
