
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import SynchronizeStoreButton from '@client/components/StoreDetail/Buttons/SynchronizeStoreButton.vue';
import WarningMessage from '@client/components/Layouts/WarningMessage.vue';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import DeleteButton from '@client/components/DeleteButton/DeleteButton.vue';
import router from '@client/router';

@Component({
  components: { WarningMessage, SynchronizeStoreButton, DeleteButton },
})
export default class StoreActions extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;
  private storesStore: StoresStore = useStoresStore();
  private devicesStore: DevicesStore = useDevicesStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  async deleteStore() {
    await this.storesStore.deleteStore(this.store._id);
    await router.push(`/stores`);
  }
  /* GETTERS */

  get isLoading(): boolean {
    return (
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update ||
      this.activeHoursStore.loadingIndicator.update
    );
  }
  get isDisabled(): boolean {
    return this.devicesStore.getDevicesByStoreId(this.store._id).length > 0;
  }
}
