import HardwareModel from './HardwareModel.model';
import { BackgroundContent, ForegroundContent } from '@client/models/ScheduleModels';
import { DeviceTemplateJSON, DeviceTemplateWithIdJSON } from '@common/device/types';
import { BackgroundContentModelJSON, ForegroundContentModelJSON } from '@common/schedule/types';
import DeviceGroup from '@client/models/DeviceModels/DeviceGroup.model';

export default class DeviceTemplate {
  _id?: string;
  hardwareModel: HardwareModel;
  backgroundContent: Array<BackgroundContent> = new Array<BackgroundContent>();
  foregroundContent: Array<ForegroundContent> = new Array<ForegroundContent>();
  railGroup: DeviceGroup = DeviceGroup.DeviceGroupUnlinked;
  previewUrl: string = '';

  constructor(hardwareModel?: HardwareModel) {
    this.hardwareModel = hardwareModel || HardwareModel.getByIdentifier();
  }

  static fromJSON(deviceTemplateJSON: DeviceTemplateWithIdJSON): DeviceTemplate {
    const deviceTemplate: DeviceTemplate = new DeviceTemplate();
    deviceTemplate._id = deviceTemplateJSON._id;
    deviceTemplate.hardwareModel = HardwareModel.getByIdentifier(deviceTemplateJSON.hardwareModel);
    deviceTemplate.previewUrl = deviceTemplateJSON.previewUrl;

    if (deviceTemplateJSON.railGroup) {
      deviceTemplate.railGroup = DeviceGroup.fromJSON(deviceTemplateJSON.railGroup);
    }

    deviceTemplateJSON.backgroundContent.forEach((backgroundContentModelJSON: BackgroundContentModelJSON) => {
      deviceTemplate.backgroundContent.push(BackgroundContent.fromJSON(backgroundContentModelJSON));
    });
    deviceTemplateJSON.foregroundContent.forEach((foregroundContentModelJSON: ForegroundContentModelJSON) => {
      deviceTemplate.foregroundContent.push(ForegroundContent.fromJSON(foregroundContentModelJSON));
    });

    return deviceTemplate;
  }

  toJSON(): DeviceTemplateWithIdJSON {
    return {
      _id: this._id || '',
      hardwareModel: this.hardwareModel.identifier,
      backgroundContent: this.backgroundContent.map((backgroundContent: BackgroundContent) =>
        backgroundContent.toJSON()
      ),
      foregroundContent: this.foregroundContent.map((foregroundContent: ForegroundContent) =>
        foregroundContent.toJSON()
      ),
      railGroup: this.railGroup.toJSON(),
      previewUrl: this.previewUrl,
    };
  }

  toNewJSON(): DeviceTemplateJSON {
    return {
      hardwareModel: this.hardwareModel.identifier,
      backgroundContent: this.backgroundContent.map((backgroundContent: BackgroundContent) =>
        backgroundContent.toJSON()
      ),
      foregroundContent: this.foregroundContent.map((foregroundContent: ForegroundContent) =>
        foregroundContent.toJSON()
      ),
      railGroup: this.railGroup,
      previewUrl: this.previewUrl,
    };
  }
}
