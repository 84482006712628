import { BaseError, ErrorType, ValidationStatus } from '../error/types';
import { Optional } from '../types';
import { DeviceJSON } from '@common/device/types';
import { StoreResponseJSON } from './types';
import { GondolaJSON } from '../gondola-template/types';

/**
 * Definition of store specific error types
 */
export class StoreErrorType extends ErrorType {
  public static readonly GONDOLA_VALIDATION: ErrorType = new ErrorType('gondola_validation');
}

export interface GondolaValidationErrorDetails {
  severity?: ValidationStatus;
  fields: Optional<string>[][];
  duplicateDevices: DeviceJSON[];
}

export class DuplicateAzureIdError extends BaseError<StoreResponseJSON> {
  constructor(store: StoreResponseJSON, message: string = 'Found a Store with the same azure id') {
    super(message, 409, ErrorType.DUPLICATE, store);
  }
}

export class GondolaValidationError extends BaseError<GondolaValidationErrorDetails> {
  constructor(validationResult: GondolaValidationErrorDetails, message: string = 'Gondola validation failed') {
    super(message, 422, StoreErrorType.GONDOLA_VALIDATION, validationResult);
  }
}

export enum ValidationErrorType {
  // Thrown for "The provided device id is neither a long id nor a short id"
  INVALID_DEVICE_ID = 'INVALID_DEVICE_ID',
  // Thrown for "duplicate deviceId ${sectionDevice.shortId} in this section"
  LOCAL_DUPLICATE_DEVICE = 'LOCAL_DUPLICATE_DEVICE',
  // Thrown for "Device with id ${sectionDevice.shortId} not found"
  DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND',
  // Duplicate device Id ${sectionDevice.shortId} in section <a href="/stores/${existingDevice.storeId}/gondola/${existingDevice.gondolaId}">${existingDevice.gondolaId}</a>
  DEVICE_DUPLICATE = 'DEVICE_DUPLICATE',
  // Section already has a device with sensor, only one sensor device is allowed per section
  SENSOR_DEVICE_DUPLICATE = 'SENSOR_DEVICE_DUPLICATE',
}

export interface ValidationErrorMessageJSON {
  type: ValidationErrorType;
  arguments: string[];
}

export interface SectionDeviceValidationStatusJSON {
  message?: ValidationErrorMessageJSON;
  severity: ValidationStatus;
  row: number;
  column: number;
}

export interface SectionValidationResultJSON {
  section: GondolaJSON;
  hasError: boolean;
  errors: SectionDeviceValidationStatusJSON[];
  duplicateDevices: DeviceJSON[];
  forceUpdatedStores: StoreResponseJSON[];
  generalSeverity: ValidationStatus;
}

export class SectionValidationError extends BaseError<AddOrUpdateSectionsResultJSON> {
  constructor(validationResult: AddOrUpdateSectionsResultJSON, message: string = 'Section(s) validation failed') {
    super(message, 422, StoreErrorType.GONDOLA_VALIDATION, validationResult);
  }
}

export interface AddOrUpdateSectionsResultJSON {
  store: StoreResponseJSON;
  forceUpdatedStores: StoreResponseJSON[];
  validationResult: SectionValidationResultJSON[];
}
