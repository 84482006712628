import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '@client/plugins/i18n/i18n';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    t: (key: string, ...params: Array<string | number>) => i18n.t(key, params) as string,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: {
          lighten1: '#FFFFFF',
          base: '#F4F7FB',
        },
        anchor: '#6681d8',
        primary: {
          lighten5: '#eceffa',
          lighten4: '#c4cef0',
          lighten3: '#a7b6e8',
          lighten2: '#7f95de',
          lighten1: '#6681d8',
          base: '#4061ce',
          darken1: '#3a58bb',
          darken2: '#2d4592',
          darken3: '#233571',
          darken4: '#1b2957',
        },
        secondary: {
          lighten5: '#edf1f2',
          lighten4: '#c8d2d8',
          lighten3: '#abdbc5',
          lighten2: '#889faa',
          lighten1: '#718c99',
          base: '#4d6f80',
          darken1: '#466574',
          darken2: '#374f5b',
          darken3: '#2a3d46',
          darken4: '#202f36',
        },
        info: {
          lighten5: '#edf1f2',
          lighten4: '#c8d2d8',
          lighten3: '#abdbc5',
          lighten2: '#889faa',
          lighten1: '#718c99',
          base: '#4d6f80',
          darken1: '#466574',
          darken2: '#374f5b',
          darken3: '#2a3d46',
          darken4: '#202f36',
        },
        success: {
          lighten5: '#ebf2eb',
          lighten4: '#c2d6c2',
          lighten3: '#a4c3a5',
          lighten2: '#7aa77c',
          lighten1: '#619662',
          base: '#397c3b',
          darken1: '#347136',
          darken2: '#28582a',
          darken3: '#1f4420',
          darken4: '#183419',
        },
        error: {
          lighten5: '#fae9ed',
          lighten4: '#f1bbc7',
          lighten3: '#ea9aac',
          lighten2: '#e06c86',
          lighten1: '#da4f6e',
          base: '#d1234a',
          darken1: '#be2043',
          darken2: '#941935',
          darken3: '#731329',
          darken4: '#580f1f',
        },
        warning: {
          lighten5: '#fff7e6',
          lighten4: '#ffe5b0',
          lighten3: '#ffd88a',
          lighten2: '#ffc754',
          lighten1: '#ffbc33',
          base: '#ffab00',
          darken1: '#e89c00',
          darken2: '#b57900',
          darken3: '#8c5e00',
          darken4: '#6b4800',
        },
        accent: {
          lighten5: '#f7f7f7',
          lighten4: '#e5e5e5',
          lighten3: '#d8d8d8',
          lighten2: '#c6c7c7',
          lighten1: '#bbbcbc',
          base: '#aaabab',
          darken1: '#9b9c9c',
          darken2: '#797979',
          darken3: '#5e5e5e',
          darken4: '#474848',
        },
        accentVariant: {
          lighten5: '#f2f1f2',
          lighten4: '#d5d4d7',
          lighten3: '#c1bfc4',
          lighten2: '#a5a2a9',
          lighten1: '#949098',
          base: '#79747e',
          darken1: '#6e6a73',
          darken2: '#565259',
          darken3: '#434045',
          darken4: '#333135',
        },
        'neon-green': {
          lighten4: '#cdedc2',
          base: '#39b70d',
        },
        'puerto-rico-blue': {
          lighten4: '#d4f3ee',
          base: '#52cebb',
        },
        'hawkes-blue': {
          base: '#ccd4fc',
        },
      },
    },
  },
});
