import DeviceTemplate from '@client/models/DeviceModels/DeviceTemplate.model';
import BackgroundContentModel from '@client/models/ScheduleModels/BackgroundContent.model';
import ForegroundContentModel from '@client/models/ScheduleModels/ForegroundContent.model';
import { DeviceTemplateWithIdJSON } from '@common/device/types';
import { GondolaTemplateWithIdJSON, NewGondolaTemplateJSON } from '@common/gondola-template/types';
import TagReference from '@client/models/SettingsModels/TagReference';
import { TagReferenceJSON } from '@common/config/tags/types';

export default class GondolaTemplate {
  _id?: string;
  name: string;
  customerId: string;
  railGrid: DeviceTemplate[][];
  layoutHash: string;
  hash?: string;
  schemaVersion?: number;
  lastPublished?: Date;
  tags: Array<TagReference> = [];

  constructor(customerId: string, hash: string = '') {
    const device: DeviceTemplate = new DeviceTemplate();
    this.customerId = customerId;
    this.railGrid = [[device]];
    this.layoutHash = '';
    this.hash = hash;
    this.name = '';
    this.tags = [];
  }

  static fromJSON(gondolaTemplateObject: GondolaTemplateWithIdJSON): GondolaTemplate {
    const gondolaTemplate: GondolaTemplate = new GondolaTemplate(gondolaTemplateObject.customerId);
    gondolaTemplate._id = gondolaTemplateObject._id;
    gondolaTemplate.name = gondolaTemplateObject.name;
    gondolaTemplate.hash = gondolaTemplateObject.hash;
    gondolaTemplate.schemaVersion = gondolaTemplateObject.schemaVersion;
    gondolaTemplate.lastPublished = gondolaTemplateObject.lastPublished;
    gondolaTemplate.layoutHash = gondolaTemplateObject.layoutHash;
    gondolaTemplate.tags = gondolaTemplateObject.tags.map((tag: TagReferenceJSON) => TagReference.fromJSON(tag));
    gondolaTemplate.railGrid = gondolaTemplateObject.railGrid.map((row: Array<DeviceTemplateWithIdJSON>) => {
      return row.map((device: DeviceTemplateWithIdJSON) => {
        return DeviceTemplate.fromJSON(device);
      });
    });
    return gondolaTemplate;
  }

  /**
   * Return an exact copy of the current object and strip all database IDs to avoid conflicts.
   * Currently, copying ScheduledContent is not supported as well, thus all related entities are removed.
   */
  clone(removeIds: boolean = true): GondolaTemplate {
    const gondolaTemplateClone: GondolaTemplate = GondolaTemplate.fromJSON(this.toJSON());
    // now remove db-ids
    if (removeIds) {
      gondolaTemplateClone.railGrid.forEach((row: DeviceTemplate[]) =>
        row.forEach((content: DeviceTemplate) => {
          content.backgroundContent.forEach((backgroundContent: BackgroundContentModel) => {
            delete backgroundContent.baseLayer?._id;
          });
          content.foregroundContent.forEach((foregroundContent: ForegroundContentModel) => {
            delete foregroundContent.baseLayer?._id;
          });
        })
      );
    }
    return gondolaTemplateClone;
  }

  toJSON(): GondolaTemplateWithIdJSON {
    return {
      _id: this._id || '',
      hash: this.hash,
      customerId: this.customerId,
      name: this.name,
      railGrid: this.railGrid.map((row: Array<DeviceTemplate>) => {
        return row.map((device: DeviceTemplate) => device.toJSON());
      }),
      schemaVersion: this?.schemaVersion,
      lastPublished: this?.lastPublished,
      layoutHash: this.layoutHash,
      tags: this.tags.map((tag: TagReference) => TagReference.toJSON(tag)),
    };
  }

  toNewJSON(): NewGondolaTemplateJSON {
    return {
      name: this.name,
      hash: this.hash,
      customerId: this.customerId,
      railGrid: this.railGrid.map((row: Array<DeviceTemplate>) => {
        return row.map((deviceTemplate: DeviceTemplate) => deviceTemplate.toNewJSON());
      }),
      tags: this.tags.map((tag: TagReference) => TagReference.toJSON(tag)),
    };
  }
}
