
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventLog, Store } from '@client/models';
import { EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';
import { EventType } from '@common/eventlog/types';
import { SearchResponse } from '@client/stores/eventLogs/types';
import { Optional } from '@common/types';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import PublishingNotification from '@client/components/NotificationPanel/Notifications/PublishingNotification.vue';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import SortPicker from '@client/components/Filters/SortPicker/SortPicker.vue';
import SearchTextField from '@client/components/Filters/SearchTextField/SearchTextField.vue';
import DatePicker from '@client/components/Filters/DatePicker/DatePicker.vue';
import DeviceStatusNotification from '@client/components/NotificationPanel/Notifications/DeviceStatusNotification.vue';
import SectionActiveHoursNotification from '@client/components/NotificationPanel/Notifications/SectionActiveHoursNotification.vue';
import StoreActiveHoursNotification from '@client/components/NotificationPanel/Notifications/StoreActiveHoursNotification.vue';
import FirmwareReleasedNotification from '@client/components/NotificationPanel/Notifications/FirmwareReleasedNotification.vue';
import StoreSynchronizationNotification from '@client/components/NotificationPanel/Notifications/StoreSynchronizationNotification.vue';
import ActiveHoursConfigNotification from '@client/components/NotificationPanel/Notifications/ActiveHoursConfigNotification.vue';
import FirmwareChangedNotification from '@client/components/NotificationPanel/Notifications/FirmwareChangedNotification.vue';
import Moment from 'moment/moment';
import {
  DEFAULT_HEADER_EXTENSION_HEIGHT,
  DEFAULT_HEADER_HEIGHT,
  TABS_HEIGHT,
} from '@client/components/Layouts/variables';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    FirmwareChangedNotification,
    ActiveHoursConfigNotification,
    StoreSynchronizationNotification,
    FirmwareReleasedNotification,
    StoreActiveHoursNotification,
    SectionActiveHoursNotification,
    DeviceStatusNotification,
    DatePicker,
    SearchTextField,
    SortPicker,
    PublishingNotification,
    Wrapper,
  },
})
export default class DeviceEvents extends Vue {
  /* DECLARATIONS */
  @Prop()
  private deviceLongId!: string;
  private eventLogs: Array<EventLog> = [];
  private totalCount: number = 0;
  private page: number = 0;
  private resultsPerPage: number = 100;
  private isLoading: boolean = true;

  private readonly defaultSearchTypes: Array<EventType> = [
    EventType.STORE_ACTIVE_HOURS,
    EventType.SECTION_ACTIVE_HOURS,
    EventType.ONLINE_STATUS,
    EventType.PUBLISHING,
    EventType.REGISTERED_STATUS,
  ];

  private dateRangeValue: [string?, string?] = [];
  private groupByType: boolean = false;

  private storesStore: StoresStore = useStoresStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private eventLogStore: EventLogsStore = useEventLogsStore();
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    await this.initializeComponent();
  }
  /* METHODS */

  async initializeComponent(): Promise<void> {
    const searchResult: SearchResponse = await this.eventLogStore.search(
      {
        searchTerm: this.deviceLongId,
        types: this.defaultSearchTypes,
      },
      this.page,
      this.resultsPerPage
    );
    this.page += 1;
    this.eventLogs = searchResult.eventLogs;
    this.totalCount = searchResult.totalCount;
    this.isLoading = false;
  }

  formatSelectedQueryDate(): [number, number] | undefined {
    if (!this.dateRangeValue[0] || !this.dateRangeValue[1]) {
      return undefined;
    }
    return [Moment(this.dateRangeValue[0]).toDate().valueOf(), Moment(this.dateRangeValue[1]).toDate().valueOf()];
  }

  @Watch('dateRangeValue', { deep: true })
  onDateChange(): void {
    this.updateSearch();
  }

  async updateSearch(): Promise<void> {
    const searchResult: SearchResponse = await this.eventLogStore.search(
      {
        searchTerm: this.deviceLongId,
        types: this.defaultSearchTypes,
        dateRange: this.formatSelectedQueryDate(),
      },
      0,
      this.resultsPerPage
    );
    this.page = 1;
    this.eventLogs = searchResult.eventLogs;
    this.totalCount = searchResult.totalCount;
  }

  async loadMore(): Promise<void> {
    if ((this.eventLogs.length < this.totalCount || this.page === 0) && !this.eventLogStore.isSearchingDevice) {
      const searchResult: SearchResponse = await this.eventLogStore.search(
        {
          searchTerm: this.deviceLongId,
          types: this.defaultSearchTypes,
          dateRange: this.formatSelectedQueryDate(),
        },
        this.page,
        this.resultsPerPage
      );
      this.page += 1;
      this.eventLogs = this.eventLogs.concat(searchResult.eventLogs);
    }
  }

  getStore(event: EventLog): Optional<Store> {
    return this.storesStore.getStoreById(event.storeId);
  }

  getNotificationTypeTitle(type: string): TranslateResult {
    switch (type) {
      case EventType.PUBLISHING:
        return this.$t(this.$i18nTranslationKeys.notifications.types.publishing.$path);
      case EventType.STORE_ACTIVE_HOURS:
      case EventType.SECTION_ACTIVE_HOURS:
      case EventType.ACTIVE_HOURS_CONFIG:
        return this.$t(this.$i18nTranslationKeys.notifications.types.activeHours.$path);
      case EventType.ONLINE_STATUS:
        return this.$t(this.$i18nTranslationKeys.notifications.types.online.$path);
      case EventType.REGISTERED_STATUS:
        return this.$t(this.$i18nTranslationKeys.notifications.types.registration.$path);
      case EventType.STORE_SYNC:
        return this.$t(this.$i18nTranslationKeys.notifications.storeSync.$path);
      case EventType.FIRMWARE_RELEASED:
      case EventType.FIRMWARE_CHANGED:
        return this.$t(this.$i18nTranslationKeys.notifications.types.firmware.$path);
      default:
        return '';
    }
  }

  /* GETTERS */
  get isSearching(): boolean {
    return this.eventLogStore.isSearchingDevice;
  }

  get canLoadMore(): boolean {
    return this.eventLogs.length < this.totalCount;
  }

  get groupedEventLogs() {
    const groupedEvents: Record<string, EventLog[]> = {};

    for (const event of this.eventLogs) {
      let eventType: string = event.type;
      if (eventType === EventType.SECTION_ACTIVE_HOURS || eventType === EventType.ACTIVE_HOURS_CONFIG) {
        eventType = EventType.STORE_ACTIVE_HOURS;
      }
      if (eventType === EventType.FIRMWARE_CHANGED) {
        eventType = EventType.FIRMWARE_RELEASED;
      }
      if (!groupedEvents[eventType]) {
        groupedEvents[eventType] = [];
      }
      groupedEvents[eventType].push(event);
    }
    return groupedEvents;
  }

  get topBarPosition(): { top: string } {
    const defaultHeaderHeight: number = DEFAULT_HEADER_HEIGHT + DEFAULT_HEADER_EXTENSION_HEIGHT;
    if (!this.breadcrumbsStore.currentPage.tabs) {
      return { top: `${defaultHeaderHeight}px` };
    }
    return { top: `${defaultHeaderHeight + TABS_HEIGHT}px` };
  }
}
