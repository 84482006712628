
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import { validateTextFieldEmail } from '@client/utils/validateTextFieldEmail';
import { TranslateResult } from 'vue-i18n';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { validateNumberInput } from '@client/utils/validateNumberInput';
import {
  DEFAULT_COMMON_STRING_MAX_LENGTH,
  validateNoSymbolsInString,
  validateTextFieldLength,
} from '@client/utils/validateTextFieldLength';
import { NewReleaseManagement } from '@client/models/SettingsModels/NewReleaseManagement';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { Optional } from '@common/types';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import SaveButton from '@client/components/Settings/ReleaseManagement/Actions/SaveButton.vue';
import WarningMessage from '@client/components/Layouts/WarningMessage.vue';

@Component({
  components: { WarningMessage, ModalHeader, SaveButton },
  methods: {
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
    validateNumberInput,
    validateNoSymbolsInString,
    validateTextFieldLength,
    validateTextFieldEmail,
  },
})
export default class ConfigDialog extends Vue {
  @Prop()
  private configCount!: number;
  @Prop()
  private storesWithDefaultConfigCount!: number;
  @Prop()
  private isEditMode!: boolean;
  @Prop()
  private title!: TranslateResult;
  @Prop()
  private oldConfigValues: Optional<ReleaseManagement>;

  private MIN_QA_DELAY: number = 3;
  private MAX_QA_DELAY: number = 10;
  private MIN_RELEASE_DELAY: number = 11;
  private MAX_RELEASE_DELAY: number = 30;

  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();
  private releaseManagement: Optional<NewReleaseManagement> = null;

  created() {
    this.releaseManagement = this.initReleaseManagementValues();
  }
  initReleaseManagementValues(): NewReleaseManagement {
    if (this.isEditMode && this.oldConfigValues) {
      return {
        name: this.oldConfigValues.name,
        description: this.oldConfigValues.description,
        isDefault: this.oldConfigValues.isDefault,
        qaDelay: this.oldConfigValues.qaDelay,
        releaseDelay: this.oldConfigValues.releaseDelay,
        email: [this.oldConfigValues.email.toString()],
      };
    }
    return {
      name: '',
      description: '',
      isDefault: this.isFirst,
      qaDelay: this.MIN_QA_DELAY,
      releaseDelay: this.MIN_RELEASE_DELAY,
      email: [''],
    };
  }

  closeDialog(): void {
    this.$emit('dialogue-closed');
    this.releaseManagement = this.initReleaseManagementValues();
  }

  saveChanges(): void {
    if (this.isEditMode) {
      this.updateReleaseManagement();
    } else {
      this.createReleaseManagement();
    }
  }
  async updateReleaseManagement() {
    if (!this.releaseManagement || !this.oldConfigValues) {
      return false;
    }
    await this.releaseManagementStore.updateReleaseManagement(
      this.oldConfigValues._id,
      this.releaseManagement,
      this.oldConfigValues.hash
    );
    this.closeDialog();
  }

  async createReleaseManagement(): Promise<void> {
    if (!this.isValid) {
      return;
    }
    if (!this.releaseManagement) {
      return;
    }
    await this.releaseManagementStore.createReleaseManagement(this.releaseManagement);
    this.$emit('release-management-added');
    this.closeDialog();
  }

  /**
   * Validates the given name. Firstly, it checks whether Release Management with the same name already exists.
   * If it doesn't exist, validates text length
   *
   * @param {string} name - The name to be validated.
   * @returns {(boolean | TranslateResult)} - True if the name is valid, otherwise a translation result for the error message.
   */
  validateName(name: string): boolean | TranslateResult {
    const possibleDuplicateReleaseManagement: Optional<ReleaseManagement> =
      this.releaseManagementStore.getReleaseManagementByNameCaseInsensitive(name);
    //check whether exists, and it is not the one we are currently editing
    if (!possibleDuplicateReleaseManagement || possibleDuplicateReleaseManagement._id === this.oldConfigValues?._id) {
      return validateTextFieldLength(name, DEFAULT_COMMON_STRING_MAX_LENGTH, true);
    }
    return i18n.t(TranslationKeys.error.genericDuplicate.$path);
  }

  get infoboxMessage(): TranslateResult {
    if (this.isFirst) {
      return i18n.tc(TranslationKeys.settings.releaseManagement.configDialog.infobox.infoMessage.$path, 1);
    } else {
      return i18n.tc(TranslationKeys.settings.releaseManagement.configDialog.infobox.infoMessage.$path, 2, {
        count: this.storesWithDefaultConfigCount,
      });
    }
  }

  get isLoading(): boolean {
    return this.releaseManagementStore.isFetching || this.releaseManagementStore.isActionPending;
  }

  get wasDefault(): boolean {
    if (!this.oldConfigValues) {
      return false;
    }
    return this.oldConfigValues.isDefault;
  }

  get isFirst(): boolean {
    return this.configCount === 0;
  }

  get isValid(): boolean {
    if (!this.releaseManagement) {
      return false;
    }
    return (
      this.validateName(this.releaseManagement.name) === true &&
      validateNoSymbolsInString(this.releaseManagement.name) === true &&
      validateTextFieldEmail(this.releaseManagement.email[0], true) === true &&
      validateNumberInput(this.releaseManagement.qaDelay, this.MIN_QA_DELAY, this.MAX_QA_DELAY) === true &&
      validateNumberInput(this.releaseManagement.releaseDelay, this.MIN_RELEASE_DELAY, this.MAX_RELEASE_DELAY) ===
        true &&
      validateTextFieldLength(this.releaseManagement.description, DEFAULT_COMMON_STRING_MAX_LENGTH, true) === true
    );
  }
  get isSameAsInitValue(): boolean {
    //checks if this modal is used to edit an existing config
    if (!this.isEditMode) {
      return false;
    }
    //checks if there are old values available
    if (!this.releaseManagement || !this.oldConfigValues) {
      return false;
    }
    return (
      this.releaseManagement.name === this.oldConfigValues.name &&
      this.releaseManagement.description === this.oldConfigValues.description &&
      this.releaseManagement.isDefault === this.oldConfigValues.isDefault &&
      this.releaseManagement.qaDelay === this.oldConfigValues.qaDelay &&
      this.releaseManagement.releaseDelay === this.oldConfigValues.releaseDelay &&
      this.releaseManagement.email[0] === this.oldConfigValues.email[0]
    );
  }

  get isNewDefault(): boolean {
    if (!this.releaseManagement) {
      return false;
    }
    //checks if there are old values available
    if (!this.oldConfigValues) {
      return false;
    }
    // checks if the isDefault value has changed and is true now
    return this.releaseManagement.isDefault && this.releaseManagement.isDefault !== this.oldConfigValues.isDefault;
  }
}
