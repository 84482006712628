
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { PublishingSensorStatus } from '@client/models/SensorModels/PublishingSensorDataTableItem';

@Component({
  computed: {
    filterTypes(): Array<{ text: TranslateResult; value: PublishingSensorStatus }> {
      return [
        {
          text: this.$t(TranslationKeys.sensors.publishingStatus.completed.$path),
          value: PublishingSensorStatus.COMPLETED,
        },
        {
          text: this.$t(TranslationKeys.sensors.publishingStatus.running.$path),
          value: PublishingSensorStatus.RUNNING,
        },
      ];
    },
  },
})
export default class HeaderPublishSensorStatusFilter extends Vue {
  @Prop()
  private headerText!: string;
  @Prop()
  private defaultValue!: PublishingSensorStatus | null;

  private filterValue: PublishingSensorStatus | null = this.defaultValue;

  created() {
    this.reassignDefaultValue();
  }

  clearFilter(): void {
    this.filterValue = null;
  }

  @Watch('filterValue')
  emitValueChanged(): void {
    this.$emit('change', this.filterValue);
  }

  @Watch('defaultValue')
  reassignDefaultValue(): void {
    this.filterValue = this.defaultValue;
  }

  get filterButtonColor(): string {
    return this.filterValue ? 'primary' : '';
  }
}
