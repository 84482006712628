
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Optional } from '@common/types';
import { Gondola, GondolaPublishing, GondolaTemplate, Device, Store } from '@client/models';
import Moment from 'moment';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { TranslateResult } from 'vue-i18n';
import { OnlineStatus } from '@common/enums';
import { getStoreDetailPath } from '@client/router/utils';
import Preview from '@client/components/DeviceDetail/Preview.vue';

@Component({
  components: { Preview },
})
export default class Overview extends Vue {
  /* DECLARATIONS */
  @Prop()
  private device!: Device;
  @Prop()
  private deviceStore?: Optional<Store>;
  @Prop()
  private deviceGondola?: Optional<Gondola>;
  @Prop()
  private lastPublishing?: Optional<GondolaPublishing>;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private previewMode: 'device' | 'section' = 'device';
  /* LIFECYCLE EVENTS */
  /* METHODS */
  navigateToStore(): void {
    if (!this.deviceStore) {
      return;
    }
    this.$router.push(
      getStoreDetailPath(this.deviceStore._id, this.deviceGondola?.aisle, this.deviceGondola?.positionInAisle)
    );
  }

  navigateToTemplate(): void {
    if (!this.lastPublishing?.gondolaTemplateId) {
      return;
    }
    this.$router.push(`/template/${this.lastPublishing.gondolaTemplateId}`);
  }

  togglePreviewMode(): void {
    this.previewMode = this.previewMode === 'device' ? 'section' : 'device';
  }
  /* GETTERS */
  get onlineDateFormatted(): string {
    if (!this.device?.onlineStatusLastChanged) {
      return '';
    }
    const moment: typeof Moment = Moment;
    moment.locale?.(this.$i18n.locale);
    return `${moment(this.device.onlineStatusLastChanged).format('llll')}`;
  }

  get createdAt(): string {
    if (!this.device) {
      return '';
    }
    const createdAt: Date = new Date(parseInt(this.device._id.substring(0, 8), 16) * 1000);
    const moment: typeof Moment = Moment;
    moment.locale?.(this.$i18n.locale);
    return `${moment(createdAt).format('LL')} ${moment(createdAt).format('LTS')}`;
  }

  get areGondolaTemplatesFetched(): boolean {
    return this.gondolaTemplatesStore.fetched;
  }

  get gondolaTemplate(): Optional<GondolaTemplate> {
    if (!this.lastPublishing?.gondolaTemplateId) {
      return undefined;
    }
    return this.gondolaTemplatesStore.getById(this.lastPublishing.gondolaTemplateId);
  }

  get gondolaTemplateName(): TranslateResult {
    return (
      this.gondolaTemplate?.name || this.$t(this.$i18nTranslationKeys.notifications.publishedWithDeletedTemplate.$path)
    );
  }

  get isGondolaPublishingUpToDate(): boolean {
    return this.gondolaTemplate?.hash === this.lastPublishing?.gondolaTemplateHash;
  }

  get deviceOnlineStatusText(): TranslateResult {
    return this.device.onlineStatus == OnlineStatus.ONLINE
      ? this.$t(this.$i18nTranslationKeys.devices.onlineStatusChanged.$path, {
          date: this.onlineDateFormatted,
        })
      : this.$t(this.$i18nTranslationKeys.devices.offlineStatusChanged.$path, {
          date: this.onlineDateFormatted,
        });
  }

  get flashDate(): string {
    const moment: typeof Moment = Moment;
    moment.locale?.(this.$i18n.locale);
    return `${moment(this.device.firmware?.flashDate).format('LL')} ${moment(this.device.firmware?.flashDate).format(
      'LTS'
    )}`;
  }

  get hasDimmingLevel(): boolean {
    return this.device.dimmingLevel !== undefined;
  }

  get dimmingLevel(): number {
    if (!this.device.dimmingLevel || this.device.dimmingLevel === -1) {
      return 0;
    }
    return this.device.dimmingLevel;
  }
}
