
import { Component, Vue } from 'vue-property-decorator';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { Optional } from '@common/types';
import { Gondola, Store } from '@client/models';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import StoreNotFound from '@client/components/NotFound/StoreNotFound.vue';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import MultipleSectionsLoader from '@client/components/StoreSection/MultipleSectionsLoader.vue';
import { Route } from 'vue-router/types/router';
import { NextType } from '@client/definitions/hooks';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import EditSection from '@client/components/StoreSection/EditSection.vue';
import ConfirmLeaveModal from '@client/components/Layouts/ConfirmLeaveModal.vue';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';

@Component({
  components: { EditSection, ModalHeader, MultipleSectionsLoader, StoreNotFound, ConfirmLeaveModal },
})
export default class AddSectionsToAisleView extends Vue {
  /* DECLARATIONS */
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private storesStore: StoresStore = useStoresStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private devicesStore: DevicesStore = useDevicesStore();
  private tagsStore: TagsStore = useTagsStore();

  private isDataLoaded: boolean = false;

  private isFormDirty: boolean = false;
  private isValidationSuccessful: boolean = false;
  private showConfirmLeaveModal: boolean = false;
  private onLeaveModalConfirmed: () => void = () => {
    // Will be overridden later
  };
  private onLeaveModalCanceled: () => void = () => {
    // Will be overridden later
  };

  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    await this.tagsStore.fetch();
    await this.gondolaTemplatesStore.fetch();
    await this.storesStore.fetchStoreDetails(this.storeId);
    await this.devicesStore.fetch(this.storeId);
    this.updateBreadcrumbs();
    this.isDataLoaded = true;
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  }

  // on route change
  beforeRouteLeave(_to: Route, _from: Route, next: NextType): void {
    if (this.isFormDirty) {
      this.showConfirmLeaveModal = true;
      this.onLeaveModalConfirmed = () => {
        if (!this.isValidationSuccessful) {
          return;
        }
        this.showConfirmLeaveModal = false;
        this.isFormDirty = false;
        const child: EditSection | undefined = this.$refs['createMultipleSections'] as EditSection | undefined;
        if (child) {
          child.callSave(true, false).then((success: boolean) => {
            if (success) {
              next();
            } else {
              this.isFormDirty = true;
            }
          });
        }
      };
      this.onLeaveModalCanceled = () => {
        this.isFormDirty = false;
        this.showConfirmLeaveModal = false;
        next();
      };
    } else {
      // Navigate to next view
      next();
    }
  }

  // if browser window / tab closes
  beforeWindowUnload(e: Event): void {
    if (this.isFormDirty) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = false;
    }
  }

  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  }

  /* METHODS */

  updateBreadcrumbs(): void {
    this.breadcrumbsStore.replace({
      path: `/stores`,
      title: {
        key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path,
      },
    });
    this.breadcrumbsStore.push({
      path: `/stores/${this.storeId}`,
      title: {
        key: this.$i18nTranslationKeys.storeDetail.breadcrumb.$path,
        params: {
          store: this.storeName || this.storeId,
        },
      },
    });
    this.breadcrumbsStore.push({
      path: `${this.$route.fullPath}`,
      title: {
        key: this.$i18nTranslationKeys.gondolaView.breadcrumbEdit.$path,
      },
    });
    this.breadcrumbsStore.setCurrentPage(
      { key: this.$i18nTranslationKeys.storeDetail.createOrEditSections.editSection.$path },
      'mdi-view-dashboard-edit',
      undefined,
      true
    );
  }

  onFormChange(isDirty: boolean): void {
    this.isFormDirty = isDirty;
  }

  closeLeaveDialog(): void {
    this.showConfirmLeaveModal = false;
  }

  onValidationChange(isValid: boolean): void {
    this.isValidationSuccessful = isValid;
  }

  /* GETTERS */
  get storeId(): string {
    return this.$route.params.storeid;
  }

  get store(): Optional<Store> {
    return this.storesStore.getStoreById(this.storeId);
  }

  get section(): Optional<Gondola> {
    if (!this.store) {
      return null;
    }
    return this.store.gondolas.find((gondola: Gondola) => gondola._id === this.$route.params.gondolaid);
  }

  get storeName(): string {
    return this.store?.name || '';
  }
}
