
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device, DevicePublishing, Gondola, GondolaPublishing, Store } from '@client/models';
import { Optional } from '@common/types';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';
import DevicePublishingScheduledContentModel from '@client/models/DeviceModels/DevicePublishingScheduledContentModel';
import Schedules from '@client/components/DeviceDetail/Schedules.vue';
import ActiveHours from '@client/components/DeviceDetail/ActiveHours.vue';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import DeviceNotFound from '@client/components/NotFound/DeviceNotFound.vue';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import WifiConfig from '@client/components/DeviceDetail/WifiConfig.vue';

@Component({
  components: { Wrapper, ActiveHours, Schedules, DeviceNotFound, WifiConfig },
})
export default class DeviceDetail extends Vue {
  /* DECLARATIONS */
  @Prop()
  private device!: Device;
  @Prop()
  private deviceStore!: Store;
  @Prop()
  private deviceGondola?: Optional<Gondola>;
  @Prop()
  private lastPublishing?: Optional<GondolaPublishing>;

  private devicesStore: DevicesStore = useDevicesStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */

  async syncDevice(): Promise<void> {
    if (this.device && this.deviceStore) {
      const updatedRail: Optional<Device> = await this.devicesStore.syncDevice(
        this.device.longId,
        this.deviceStore.idAzure
      );
      if (!updatedRail) {
        return;
      }
      this.onDeviceSync(updatedRail);
    }
  }

  onDeviceSync(updatedRail: Device): void {
    this.$emit('onSync', updatedRail);
  }

  /* GETTERS */
  get schedules(): Array<ScheduleModel> {
    const schedules: Set<ScheduleModel> = new Set<ScheduleModel>();
    if (!this.lastPublishing) {
      return [];
    }
    this.lastPublishing?.railGrid?.flat<DevicePublishing[][]>(1).forEach((devicePublishing: DevicePublishing) => {
      devicePublishing.foregroundContent.forEach((foregroundContent: DevicePublishingForegroundContentModel) =>
        foregroundContent.scheduledContent?.forEach((scheduledContent: DevicePublishingScheduledContentModel) => {
          if (scheduledContent.schedule) {
            schedules.add(scheduledContent.schedule);
          }
        })
      );
      devicePublishing.backgroundContent[0].scheduledContent?.forEach(
        (scheduledContent: DevicePublishingScheduledContentModel) => {
          if (scheduledContent.schedule) {
            schedules.add(scheduledContent.schedule);
          }
        }
      );
    });
    return Array.from(schedules);
  }

  get isLoading(): boolean {
    return this.devicesStore.isFetching;
  }

  get activeHours(): ActiveHoursSchedule {
    if (this.deviceGondola?.activeHours.length) {
      return this.deviceGondola.activeHours;
    }
    return this.deviceStore?.activeHours || [];
  }

  get isSectionActiveHoursOverridden(): boolean {
    if (this.deviceGondola?.activeHours?.length) {
      return true;
    }
    return false;
  }
}
