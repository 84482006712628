
import { Component, Prop, Vue } from 'vue-property-decorator';
import NotificationTimeDisplay from '@client/components/NotificationPanel/NotificationTimeDisplay.vue';
import NotificationTitle from '@client/components/NotificationPanel/NotificationTitle.vue';
import { LocaleMessage } from 'vue-i18n';
import { DeviceStatusEventType, EventState } from '@common/eventlog/types';

@Component({
  computed: {
    EventState() {
      return EventState;
    },
  },
  components: { NotificationTitle, NotificationTimeDisplay },
})
export default class Notification extends Vue {
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private primaryIcon?: string;
  @Prop()
  private secondaryIcon?: string;
  @Prop()
  private dateToDisplay?: string | Date;
  @Prop()
  private id?: string;
  @Prop()
  private title?: string | LocaleMessage;
  @Prop()
  private titleIdentifier?: string | null;
  @Prop()
  private titleLink?: string;
  @Prop()
  private publishingStatus?: EventState;
  @Prop({ default: false })
  private hideChipsWhenClosed?: boolean;
  @Prop()
  private deviceStatus?: DeviceStatusEventType;

  private show: boolean = false;

  get color(): string {
    if (this.publishingStatus) {
      switch (this.publishingStatus) {
        case EventState.COMPLETED:
          return 'success';
        case EventState.ERROR:
          return 'error';
        case EventState.CANCELED:
          return 'accent';
        case EventState.ACCEPTED:
          return 'secondary';
      }
    }
    if (this.deviceStatus) {
      switch (this.deviceStatus) {
        case DeviceStatusEventType.ONLINE:
          return 'success';
        case DeviceStatusEventType.OFFLINE:
          return 'error';
        case DeviceStatusEventType.REGISTERED:
          return 'success';
        case DeviceStatusEventType.UNREGISTERED:
          return 'error';
      }
    }

    return 'primary';
  }

  get hasSecondaryIcon(): boolean {
    return !!this.secondaryIcon;
  }

  get showChips(): boolean {
    if (this.hideChipsWhenClosed === false) {
      if (this.publishingStatus !== EventState.CANCELED && this.publishingStatus !== EventState.COMPLETED) {
        return true;
      }
    }
    return this.show;
  }
}
