
import { Component, Vue } from 'vue-property-decorator';
import { ContentItems } from '@client/components';
import { ContentItemSelectionTarget } from '@client/enums';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';

@Component({
  components: {
    ContentItems,
  },
})
export default class ContentManagementView extends Vue {
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  async created(): Promise<void> {
    this.breadcrumbsStore.replace({
      path: `/assets`,
      title: { key: this.$i18nTranslationKeys.contentManagement.title.$path },
    });
    this.breadcrumbsStore.setCurrentPage(
      { key: this.$i18nTranslationKeys.contentManagement.title.$path },
      'mdi-multimedia'
    );
    await this.contentItemsStore.fetchContentItems();
  }

  get defaultContentItemMode(): ContentItemSelectionTarget {
    return ContentItemSelectionTarget.NONE;
  }
}
