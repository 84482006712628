
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Preview as DevicePreview } from '@client/components';
import { Optional } from '@common/types';
import { GondolaPublishing, Device, DevicePublishing } from '@client/models';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';

@Component({
  components: {
    DevicePreview,
  },
})
export default class Preview extends Vue {
  /* DECLARATIONS */
  @Prop()
  private lastPublishing!: GondolaPublishing;
  @Prop()
  private device!: Device;
  @Prop({ default: 'device' })
  private previewMode!: 'device' | 'section';

  private schedulesStore: SchedulesStore = useSchedulesStore();
  /* LIFECYCLE EVENTS */
  @Watch('previewMode')
  onPreviewModeChange(): void {
    setTimeout(() => {
      if (this.previewMode === 'section') {
        const device: HTMLElement | null = document.getElementById(this.device.longId);
        const container: HTMLElement | null = document.getElementById('rail-grid-container');
        if (!container || !device) {
          return;
        }
        const topPos: number | undefined = device.offsetTop - this.device.hardwareModel.height;
        const leftPos: number | undefined = device.offsetLeft;
        container.scrollTo({
          top: topPos,
          left: leftPos,
          behavior: 'smooth',
        });
      }
    }, 200);
  }
  /* METHODS */
  getSize(deviceTemplate: DevicePublishing): string {
    let mode: string = 'dimmed';
    if (deviceTemplate.longId === this.device.longId) {
      mode = 'highlight';
    }
    return `device-${deviceTemplate.hardwareModel.identifier} ${mode}`;
  }

  getDeviceHighlightFromGroup(devicePublishing: DevicePublishing): string {
    if (!devicePublishing.railGroup.color) {
      return '';
    }
    return `outline: 6px solid ${devicePublishing.railGroup.color};`;
  }

  /* GETTERS */
  get devicePublishing(): Optional<DevicePublishing> {
    return this.lastPublishing.railGrid
      ?.flat<DevicePublishing[][]>(1)
      .find((device: DevicePublishing) => device.longId === this.device.longId);
  }

  get deviceSelectedSchedule(): string {
    return this.schedulesStore.selectedSchedule;
  }
}
