
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { GondolaTemplate } from '@client/models';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';
import { Optional } from '@common/types';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import router from '@client/router';
import { TranslateResult } from 'vue-i18n';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';

@Component({
  methods: {
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
    validateTextFieldLength,
  },
  components: { SelectTag, ModalHeader },
})
export default class CloneGondolaTemplateModal extends Vue {
  @Prop()
  private gondola!: Optional<GondolaTemplate>;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private title!: TranslateResult;
  private name: string = '';
  private selectedTags: Array<TagReference> = [];

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();

  created() {
    if (this.gondola) {
      this.selectedTags = this.gondola.tags;
      this.name = `${this.$t(this.$i18nTranslationKeys.copyOf.$path)}${this.gondola.name}`;
    }
  }

  closeDialog(): void {
    this.$emit('dialogue-closed');
    this.name = '';
    this.selectedTags = [];
  }

  updateSelectedTags(selectedTags: Array<TagReference>): void {
    this.selectedTags = selectedTags;
  }

  async addGondolaTemplate(): Promise<void> {
    const newGondolaTemplate: GondolaTemplate = new GondolaTemplate(this.appGlobalStore.customer);
    newGondolaTemplate.name = this.name;
    newGondolaTemplate.tags = this.selectedTags;
    const gondolaTemplateId: Optional<string> = await this.gondolaTemplatesStore.create(newGondolaTemplate.toNewJSON());
    if (!gondolaTemplateId) {
      return;
    }
    await router.push(`/template/${gondolaTemplateId}`);
  }

  async saveChanges(): Promise<void> {
    if (this.gondola && this.gondola._id) {
      const cloneTemplateId: Optional<string> = await this.gondolaTemplatesStore.clone(
        this.gondola._id,
        this.name,
        this.selectedTags
      );
      await router.push(`/template/${cloneTemplateId}`);
      return;
    }
    await this.addGondolaTemplate();
  }

  validateName(name: string): boolean | TranslateResult {
    const duplicateGondolaTemplate: Optional<GondolaTemplate> = this.gondolaTemplatesStore.findName(this.name);
    if (!duplicateGondolaTemplate || duplicateGondolaTemplate._id === this.gondola?._id) {
      return validateTextFieldLength(name, DEFAULT_COMMON_STRING_MAX_LENGTH, true);
    }
    return i18n.t(TranslationKeys.error.genericDuplicate.$path);
  }

  get isValid() {
    return this.validateName(this.name) === true;
  }

  get isSameAsInitValue(): boolean {
    if (!this.gondola) {
      return false;
    }
    return this.name === this.gondola.name && this.selectedTags === this.gondola.tags;
  }
}
