
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { Device, DeviceSearchResult, Gondola, GondolaPublishing, Store } from '@client/models';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import DeviceDetailsTab from '@client/components/DeviceDetail/DeviceDetailsTab.vue';
import DeviceDetailLoader from '@client/components/DeviceDetail/DeviceDetailLoader.vue';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import OverviewTab from '@client/components/DeviceDetail/OverviewTab.vue';
import DeviceNotFound from '@client/components/NotFound/DeviceNotFound.vue';
import EventsTab from '@client/components/DeviceDetail/EventsTab.vue';

const OVERVIEW_TAB: string = 'overview';
const DETAILS_TAB: string = 'details';
const EVENTS_TAB: string = 'events';

@Component({
  components: { EventsTab, DeviceNotFound, OverviewTab, DeviceDetailLoader, DeviceDetailsTab },
})
export default class DeviceDetailView extends Vue {
  /* DECLARATIONS */
  private devicesStore: DevicesStore = useDevicesStore();
  private storesStore: StoresStore = useStoresStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private store: Optional<Store> = null;
  private deviceData: Optional<DeviceSearchResult> = null;

  private isDataLoaded: boolean = false;
  private activeTab: string = OVERVIEW_TAB;
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    await this.onRouteChanged();
  }

  /* METHODS */

  @Watch('$route.fullPath')
  async onRouteChanged(): Promise<void> {
    this.activeTab = OVERVIEW_TAB;
    this.breadcrumbsStore.replace({
      path: '/devices/',
      title: {
        key: this.$i18nTranslationKeys.devices.overviewBreadcrumb.$path,
      },
    });
    this.breadcrumbsStore.push({
      path: `${this.$route.fullPath}`,
      title: {
        key: this.$i18nTranslationKeys.deviceDetail.breadcrumb.$path,
        params: { longId: this.deviceId },
      },
    });
    this.breadcrumbsStore.setCurrentPage(
      { key: this.$i18nTranslationKeys.deviceDetail.breadcrumb.$path, params: { longId: this.deviceId } },
      'mdi-devices',
      [
        {
          title: { key: this.$i18nTranslationKeys.devices.overviewBreadcrumb.$path },
          icon: 'mdi-cellphone-information',
          clickEvent: () => {
            this.activeTab = OVERVIEW_TAB;
          },
          key: OVERVIEW_TAB,
          dataCy: 'overview-tab-cy',
        },
        {
          title: { key: this.$i18nTranslationKeys.gondolaTemplateDetailView.detailTab.$path },
          icon: 'mdi-text-box-search-outline',
          clickEvent: () => {
            this.activeTab = DETAILS_TAB;
          },
          key: DETAILS_TAB,
          dataCy: 'details-tab-cy',
        },
        {
          title: { key: this.$i18nTranslationKeys.deviceDetail.events.$path },
          icon: 'mdi-calendar-clock',
          clickEvent: () => {
            this.activeTab = EVENTS_TAB;
          },
          key: EVENTS_TAB,
          dataCy: 'events-tab-cy',
        },
      ]
    );
    this.deviceData = await this.devicesStore.getDevice(this.deviceId);
    if (this.deviceData?.device) {
      this.store = await this.storesStore.fetchStoreDetails(this.deviceData.device.storeId);
    }
    this.isDataLoaded = true;
  }
  /* METHODS */

  onDeviceSync(updatedRail: Device): void {
    if (this.deviceData) {
      this.deviceData.device = updatedRail;
    }
  }
  /* GETTERS */
  get deviceId(): string {
    return this.$route.params.longId;
  }

  get device(): Optional<Device> {
    return this.deviceData?.device;
  }

  get lastPublishing(): Optional<GondolaPublishing> {
    return this.deviceData?.publishing;
  }

  get deviceGondola(): Optional<Gondola> {
    if (!this.device) {
      return undefined;
    }
    return this.storesStore.getGondolaByDeviceId(this.device.longId)?.gondolaModel;
  }

  get isSearching(): boolean {
    return this.devicesStore.isSearching || this.storesStore.isFetching || !this.isDataLoaded;
  }
}
