
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class WarningMessage extends Vue {
  /* DECLARATIONS */
  @Prop()
  private title!: TranslateResult;
  @Prop()
  private icon!: string;
  @Prop()
  private cy!: string;
  @Prop()
  private color!: string;
  @Prop({ default: true })
  private containsTitle!: boolean;
}
