
import { Component, Prop, Vue } from 'vue-property-decorator';
import Device from '@client/components/Device/Device.vue';
import ModalDialog from '../ModalDialog/ModalDialog.vue';
import { DeviceTemplate, GondolaTemplate, HardwareModel } from '@client/models';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { default as TemplateDevice } from '@client/components/Device/DeviceTemplate.vue';
import DeviceGroup from '@client/models/DeviceModels/DeviceGroup.model';

@Component({
  components: {
    TemplateDevice,
    Device,
    ModalDialog,
  },
})
export default class GondolaTemplateContentEdit extends Vue {
  @Prop()
  private loading!: boolean;
  @Prop()
  private gondolaTemplate!: GondolaTemplate;
  @Prop({ default: false })
  private showCurrentlyDisplayedSchedule!: boolean;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private showDeleteDeviceDialog: boolean = false;

  private railGrid: Array<Array<DeviceTemplate>> = [];

  private cancelHandler = () => {
    // Will be overridden later
  };
  private confirmHandler = () => {
    // Will be overridden later
  };

  created() {
    this.railGrid = this.gondolaTemplate.railGrid;
  }

  checkAndSetLoading(): boolean {
    if (this.loading) {
      return true;
    }
    this.$emit('loading', true);
    return false;
  }

  async addDeviceRow(): Promise<void> {
    this.railGrid.push(new Array<DeviceTemplate>(new DeviceTemplate()));
    this.$emit('rail-grid-change', this.railGrid);
  }

  addDeviceCol(rowIndex: number): void {
    this.railGrid[rowIndex].push(new DeviceTemplate());
    this.$emit('rail-grid-change', this.railGrid);
  }
  onHardwareModelChange(row: number, col: number, hardwareModel: HardwareModel): void {
    this.railGrid[row][col].hardwareModel = hardwareModel;
    this.$emit('rail-grid-change', this.railGrid);
  }
  onDeviceGroupChange(row: number, col: number, deviceGroup: DeviceGroup): void {
    this.railGrid[row][col].railGroup = deviceGroup;
    this.$emit('rail-grid-change', this.railGrid);
  }

  async deleteDevice(rowIndex: number, colIndex: number): Promise<void> {
    this.railGrid[rowIndex].splice(colIndex, 1);

    if (this.railGrid[rowIndex].length === 0) {
      this.railGrid.splice(rowIndex, 1);
    }
    this.$emit('rail-grid-change', this.railGrid);
  }
}
