
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItemSelectionTarget } from '@client/enums';
import { ContentItem } from '@client/models/ContentModels/types';
import ImageModel from '@client/models/ContentModels/Image.model';
import { downloadAsset, getAncestorName } from '@client/components/ContentItems/ListItems/utils';
import { ContentItemPreviewStates } from '@common/content-item/types';
import DownloadButton from './DownloadButton.vue';
import DeleteButton from './DeleteButton.vue';
import AssignButton from './AssignButton.vue';
import ParentFolderColumn from '@client/components/ContentItems/ListItems/ParentFolderColumn.vue';
import FileNameField from '@client/components/ContentItems/ListItems/FileNameField.vue';
import RecreatePreviewButton from '@client/components/ContentItems/ListItems/RecreatePreviewButton.vue';
import MoveButton from '@client/components/ContentItems/ListItems/MoveButton.vue';
import EditTagsButton from '@client/components/ContentItems/ListItems/EditTagsButton.vue';
import TagsList from '@client/components/Settings/Tags/TagsList.vue';

/**
 * Renders an image row in the content items table.
 */
@Component({
  components: {
    TagsList,
    EditTagsButton,
    FileNameField,
    RecreatePreviewButton,
    AssignButton,
    DownloadButton,
    DeleteButton,
    ParentFolderColumn,
    MoveButton,
  },
  methods: {
    getContentItemParentFolderName: getAncestorName,
    downloadAsset,
  },
})
export default class ImageItem extends Vue {
  /* DECLARATIONS */
  @Prop()
  private imageItem!: ImageModel;
  @Prop()
  private index!: number;
  @Prop()
  private expand!: (value: boolean) => void;
  @Prop()
  private isExpanded!: boolean;
  @Prop()
  private showParentFolder!: boolean;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private isModalEmbedded!: boolean;
  @Prop({ default: ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND })
  private contentItemSelectionTarget!: ContentItemSelectionTarget;
  @Prop()
  private currentlyActiveContentItemName?: string;
  @Prop()
  private isSelected!: boolean;
  @Prop()
  private setSelectedRow!: (id: string) => void;
  @Prop()
  private deleteContentItem!: (contentItem: ContentItem) => void;
  @Prop()
  private updateContentItem!: (contentItem: ContentItem) => void;
  @Prop()
  setSelectedFolder!: (folderId: string) => void;
  @Prop()
  private openMoveDialog!: (contentItemToMove: ContentItem) => void;
  @Prop()
  private openEditTagsDialog!: (contentItemToEdit: ContentItem) => void;
  @Prop()
  private onToggleSelection!: (contentItem: ContentItem) => void;
  @Prop()
  private isChecked!: boolean;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /**
   * Cases where the button should not be visible:
   * 1. If we are in playlist edit mode, since here we only want to be able to
   *    add items to the playlist (and not delete ContentItems from the system)
   * 2. When the ContentItem that is currently selected (and rendered) for the active slot
   *    is the same as listed (i.e. given as parameter to this function) one
   */
  isActionButtonVisible(contentItem: ImageModel): boolean {
    if (this.isModalEmbedded) {
      return false;
    }
    if (this.contentItemSelectionTarget === ContentItemSelectionTarget.PLAYLIST) {
      return false;
    }

    if (this.currentlyActiveContentItemName) {
      return this.currentlyActiveContentItemName !== contentItem.getName();
    }

    return true;
  }

  onRowClick(id: string): void {
    this.setSelectedRow(id);
    this.expand(!this.isExpanded);
  }

  onContentItemClick(item: ImageModel): void {
    if (!this.hasValidContentItemTarget) {
      return;
    }
    this.updateContentItem(item);
  }

  /* GETTERS */
  get hasValidContentItemTarget(): boolean {
    return this.contentItemSelectionTarget !== ContentItemSelectionTarget.NONE;
  }
  get selectedRowClass(): string | null {
    return this.isSelected ? 'selected-highlight-row' : null;
  }

  get previewValue(): number {
    switch (this.imageItem.previewState) {
      case ContentItemPreviewStates.COMPLETED:
        return 100;
      case ContentItemPreviewStates.UPLOADING:
        return 85;
      case ContentItemPreviewStates.CREATING:
        return 65;
      case ContentItemPreviewStates.PROBING:
        return 45;
      case ContentItemPreviewStates.STARTING:
        return 25;
      case ContentItemPreviewStates.PENDING:
        return 10;
      default:
        break;
    }
    return 0;
  }

  get hideDetailColumns(): boolean {
    return this.contentItemSelectionTarget === ContentItemSelectionTarget.MOVE_ITEM;
  }

  get actionMenuWidth(): string {
    return !this.imageItem.isPreviewFailed ? '150px' : '220px';
  }
}
