
import { Component, Vue } from 'vue-property-decorator';
import router from '@client/router';
import { Store } from '@client/models';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { ErrorStore, useErrorStore } from '@client/stores/error';
import { StoresStore, useStoresStore, StoreGondolaWrapper } from '@client/stores/stores';
import { Optional } from '@common/types';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import { getStoreDetailPath } from '@client/router/utils';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import QRCodeOverlay from '@client/components/Store/QRCodeOverlay.vue';
import EditButton from '@client/components/Settings/ReleaseManagement/Actions/EditButton.vue';
import DeleteButton from '@client/components/Settings/ReleaseManagement/Actions/DeleteButton.vue';
import Tag from '@client/components/Settings/Tags/Tag.vue';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import SearchTextField from '@client/components/Filters/SearchTextField/SearchTextField.vue';
import { includesSelectedTags } from '@client/utils/TagUtils';
import StoreCreationWizard from '@client/components/Store/StoreCreationWizard/StoreCreationWizard.vue';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import TagsList from '@client/components/Settings/Tags/TagsList.vue';

@Component({
  components: {
    TagsList,
    SelectTag,
    SearchTextField,
    Tag,
    StoreCreationWizard,
    QRCodeOverlay,
    EditButton,
    DeleteButton,
    Wrapper,
  },
})
export default class StoreListView extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreListView';

  private showSearchFailedErrorModal: boolean = false;
  private searchFailedErrorMessage: string = '';
  private search: string = '';
  private selectedTags: TagReference[] = [];

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private errorStore: ErrorStore = useErrorStore();
  private storesStore: StoresStore = useStoresStore();
  private devicesStore: DevicesStore = useDevicesStore();
  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();
  private contentItemStore: ContentItemsStore = useContentItemsStore();
  private tagsStore: TagsStore = useTagsStore();

  async created(): Promise<void> {
    this.breadcrumbsStore.replace({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path },
    });
    this.breadcrumbsStore.setCurrentPage(
      { key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path },
      'mdi-store'
    );
    await this.tagsStore.fetch();
    await this.storesStore.fetch();
    await this.releaseManagementStore.fetch();
    await this.contentItemStore.fetchContentItems();
  }

  beforeDestroy(): void {
    this.errorStore.deregister(StoreListView.ERROR_OBSERVER_KEY);
  }

  hideSearchFailedErrorModal(): void {
    this.showSearchFailedErrorModal = false;
  }

  rowClick(selectedStore: Store): void {
    router.push(`/stores/${selectedStore._id}`);
  }

  navigateToDeviceById(): void {
    const storeGondolas: Optional<StoreGondolaWrapper> = this.storesStore.getGondolaByDeviceId(this.search);
    if (!storeGondolas) {
      return;
    } else {
      this.$router.push(
        getStoreDetailPath(
          storeGondolas.storeModel._id,
          storeGondolas.gondolaModel.aisle,
          storeGondolas.gondolaModel.positionInAisle
        )
      );
    }
  }

  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$tc(this.$i18nTranslationKeys.store.$path),
        align: 'left',
        sortable: true,
        value: 'name',
      },
      { text: this.$tc(this.$i18nTranslationKeys.aisle.$path, 2), value: 'numberOfAisles' },
      { text: this.$tc(this.$i18nTranslationKeys.gondola.$path, 2), value: 'numberOfGondolas' },
      {
        text: this.$tc(this.$i18nTranslationKeys.device.statusNames.online.$path, 3),
        value: 'numberOfOnlineDevices',
      },
      {
        text: this.$tc(this.$i18nTranslationKeys.device.statusNames.offline.$path, 4),
        value: 'numberOfOfflineDevices',
      },
      {
        text: this.$tc(this.$i18nTranslationKeys.device.statusNames.unassigned.$path, 5),
        value: 'numberOfUnassignedDevices',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.title.$path),
        value: 'tags',
        sortable: false,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.action.actions.$path),
        value: 'actions',
      },
    ];
  }

  get loading(): boolean {
    return !this.storesStore.fetched || this.storesStore.isFetching || this.devicesStore.isFetching;
  }

  get stores(): Array<Store> {
    return this.storesStore.stores;
  }

  get filteredStores(): Store[] {
    if (this.selectedTags.length === 0) {
      return this.stores;
    }
    return this.stores.filter((store: Store) => {
      return includesSelectedTags(store.tags, this.selectedTags);
    });
  }

  updateTagFilter(selectedTags: Array<TagReference>) {
    this.selectedTags = selectedTags;
  }
}
