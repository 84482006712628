import { render, staticRenderFns } from "./OverviewTab.vue?vue&type=template&id=1ad6324d"
import script from "./OverviewTab.vue?vue&type=script&lang=ts"
export * from "./OverviewTab.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports