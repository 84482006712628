
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SaveButton extends Vue {
  @Prop()
  private isValid!: boolean;
  @Prop()
  private hasNoChange!: boolean;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private cy!: string;

  save(): void {
    this.$emit('update-gondola-template');
  }
}
