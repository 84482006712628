
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Optional } from '@common/types';
import { ActiveHourItem, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { TranslateResult } from 'vue-i18n';
import TimePicker from '@client/components/TimePicker/TimePicker.vue';
import { isActiveAllDay, equals } from '@client/utils/ActiveHoursUtils';
import { Gondola, Store } from '@client/models';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import ActiveHoursTable from '@client/components/ActiveHours/ActiveHoursTable.vue';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

/**
 * Object for representing existence of an error in start and end time input
 */
export interface TimeValidationError {
  startTime: boolean;
  endTime: boolean;
  endTimeBigger: boolean;
}

@Component({
  components: {
    ModalHeader,
    ActiveHoursTable,
  },
  methods: {
    isActiveAllDay,
  },
})
export default class ActiveHours extends Vue {
  @Prop({ default: false })
  private isIcon!: boolean;
  @Prop({ default: false })
  private small!: boolean;
  @Prop()
  private isDisabled!: boolean;
  @Prop()
  private isMissingAzureId!: boolean;
  @Prop()
  private storeId!: string;
  @Prop()
  private activeHoursSchedule!: ActiveHoursSchedule;
  @Prop()
  private activeHoursJobFailed!: boolean;
  @Prop({ default: 'mdi-clock' })
  private defaultIcon!: string;
  @Prop({ default: 'mdi-clock-check' })
  private configSetIcon!: string;
  @Prop({ default: 'mdi-clock-alert' })
  private errorIcon!: string;
  /**
   * Prop which holds store active hours in case this component is used inside {@link SectionActiveHours}
   * Undefined in case this component is used in {@link StoreActiveHours}
   */
  @Prop()
  private initialStoreActiveHours?: ActiveHoursSchedule;
  /**
   * Prop that holds the gondola id of the section in case this is a section active hours
   * will be undefined for store level active hours
   * @private
   */
  @Prop()
  private gondolaId?: string;

  private localActiveHoursSchedule: ActiveHoursSchedule = [];
  private static readonly NUMBER_OF_DAYS_IN_WEEK: number = 7;
  errors: TimeValidationError[] = [];
  showDialog: boolean = false;
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();

  @Watch('activeHoursSchedule')
  initValues(): void {
    if (this.activeHoursSchedule) {
      // deep copy of prop to local field, so we can discard changes in case of cancel
      this.localActiveHoursSchedule = this.activeHoursSchedule.map((element: ActiveHourItem, index: number) => {
        // Also reinitialize the timepicker components
        (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
        (this.$refs[`startTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
        return element.clone();
      });
    }
  }

  /**
   * After clicking cancel, discard any changes
   */
  closeDialog(): void {
    this.showDialog = false;
    this.initValues();
  }

  onErrorChanged(errors: Array<TimeValidationError>): void {
    this.errors = errors;
  }

  /**
   * Check if there are no errors and send data to server
   */
  async saveActiveHours(): Promise<void> {
    const hasError: boolean = this.errors.some(
      (error: TimeValidationError) => error.startTime || error.endTime || error.endTimeBigger
    );
    if (hasError) {
      return;
    }
    this.$emit('onSave', this.localActiveHoursSchedule);
  }

  created(): void {
    this.initValues();
  }

  /**
   * Checks if every device is active whole day
   */
  isActiveHoursStateDefault(): boolean {
    if (!this.localActiveHoursSchedule) {
      return true;
    }
    //in case of section active hours
    if (this.initialStoreActiveHours) {
      return equals(this.localActiveHoursSchedule, this.initialStoreActiveHours);
    }
    //in case of store active hours
    return !this.localActiveHoursSchedule.filter((item: ActiveHourItem) => !isActiveAllDay(item.schedule)).length;
  }

  get isLoading(): boolean {
    return (
      this.activeHoursStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update
    );
  }

  /**
   * Reset section specific active hours to store level active hours
   */
  resetToStoreActiveHours(): void {
    if (!this.localActiveHoursSchedule) {
      return;
    }
    for (let i: number = 0; i < ActiveHours.NUMBER_OF_DAYS_IN_WEEK; i++) {
      if (!this.initialStoreActiveHours) {
        return;
      }
      Vue.set(this.activeHoursSchedule, i, this.initialStoreActiveHours[i]);
    }
    this.$emit('onReset');
    this.showDialog = false;
  }

  get isSectionScope(): boolean {
    return !!this.gondolaId;
  }

  get gondolaName(): Optional<string> {
    if (!this.gondolaId) {
      return '';
    }
    const currentStore: Optional<Store> = this.storesStore.getStoreById(this.storeId);
    const currentGondola: Optional<Gondola> = currentStore?.gondolas.find(
      (gondola: Gondola) => gondola._id === this.gondolaId
    );
    if (!currentStore || !currentGondola) {
      return this.gondolaId;
    }
    return `${currentGondola.aisle}-${currentGondola.positionInAisle}`;
  }

  get buttonTooltipMessage(): TranslateResult {
    if (this.isMissingAzureId) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.missingAzureId.$path);
    }
    if (this.isDisabled) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.apimActionsDisabled.$path);
    }
    if (this.activeHoursJobFailed) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.activeHoursJobFailed.$path);
    }
    return this.isSectionScope
      ? this.$t(this.$i18nTranslationKeys.activeHours.editDeviceMessage.$path)
      : this.$t(this.$i18nTranslationKeys.activeHours.title.$path);
  }

  get isButtonDisabled(): boolean {
    return this.isDisabled || this.isMissingAzureId;
  }

  /**
   * Determines which icon will be used based on AH configuration
   */
  get icon(): string {
    if (this.activeHoursJobFailed) {
      return this.errorIcon;
    }
    return this.isActiveHoursStateDefault() ? this.defaultIcon : this.configSetIcon;
  }

  get activeHoursButtonDataCy(): string {
    return this.isSectionScope ? `active-hours-${this.gondolaName}-edit-cy` : 'active-hours-edit-cy';
  }

  get isSaveButtonDisabled(): boolean {
    return this.errors.some((error: TimeValidationError) => error.startTime || error.endTime || error.endTimeBigger);
  }
}
