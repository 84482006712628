
import { Component, Vue } from 'vue-property-decorator';
import { LocaleSwitch } from '@client/components';
import { AccountInfo } from '@azure/msal-browser';
import { IdTokenClaims } from '@client/models/UserModels';

@Component({
  components: { LocaleSwitch },
})
export default class UserMenu extends Vue {
  /* DECLARATIONS */
  /* LIFECYCLE EVENTS */
  /* METHODS */
  logout(): void {
    this.$msal.signOut();
  }

  editProfile(): void {
    this.$msal.editProfile();
  }
  /* GETTERS */

  get username(): string {
    const accountInfo: AccountInfo | null = this.$msal.state.user;
    if (!accountInfo?.idTokenClaims) {
      return '';
    }

    const idTokenClaims: IdTokenClaims = accountInfo.idTokenClaims as unknown as IdTokenClaims;
    return `${idTokenClaims.given_name} ${idTokenClaims.family_name}`;
  }

  get isAuthenticated(): boolean {
    return this.$msal.isAuthenticated();
  }
}
