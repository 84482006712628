
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import EditStoreSettingsButton from '@client/components/StoreDetail/Buttons/EditStoreSettingsButton.vue';
import TagsList from '@client/components/Settings/Tags/TagsList.vue';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';

@Component({
  components: { TagsList, EditStoreSettingsButton },
})
export default class GeneralSettings extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;

  private tagsStore: TagsStore = useTagsStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    await this.tagsStore.fetch();
    await this.contentItemsStore.fetchContentItems();
  }
  /* METHODS */
  /* GETTERS */
}
