import { GondolaPublishing, GondolaTemplate, Store } from '@client/models';

export type PublishingSensorTableFilter = {
  templateName: string;
  store: string;
  aisle: string;
  section: string;
  publishStatus: PublishingSensorStatus | null;
  startTime: [string?, string?];
  endTime: [string?, string?];
};

export class PublishingSensorDataTableItem {
  publishing: GondolaPublishing;
  store: Store;
  template?: GondolaTemplate;

  constructor(publishing: GondolaPublishing, store: Store, template?: GondolaTemplate) {
    this.publishing = publishing;
    this.template = template;
    this.store = store;
  }
}

export enum PublishingSensorStatus {
  COMPLETED = 'Completed',
  RUNNING = 'Running',
}
