
import { Vue, Component, Watch } from 'vue-property-decorator';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';
import { PageTab, TranslateStringConfig } from '@client/stores/breadcrumbs/types';
import { TABS_HEIGHT } from '@client/components/Layouts/variables';
import { PortalTarget } from 'portal-vue';
import i18n from '@client/plugins/i18n/i18n';

@Component({
  components: {
    PortalTarget,
  },
})
export default class PageTitle extends Vue {
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private selectedTab: number = 0;
  private title: TranslateResult = '';

  created() {
    this.onEditModeChange();
    this.setTitle();
  }

  @Watch('breadcrumbsStore.currentPage.isEditMode', { immediate: true, deep: true })
  onEditModeChange() {
    const extensionBarTitleContainer: Element | null = document.getElementById('page-title');
    if (!extensionBarTitleContainer) {
      return;
    }
    if (this.isEditMode) {
      extensionBarTitleContainer.classList.add('primary', 'darken-1');
    } else {
      extensionBarTitleContainer.classList.remove('primary', 'darken-1');
    }
  }

  @Watch('tabs')
  onTabsChange() {
    this.$nextTick(() => {
      this.selectedTab = 0;
    });
  }

  @Watch('$i18n.locale')
  @Watch('breadcrumbsStore.currentPage.title')
  setTitle(): void {
    this.title = this.getTranslationResultFromConfig(
      this.breadcrumbsStore.currentPage.title,
      this.breadcrumbsStore.currentPage.isEditMode
    );
  }

  getTranslationResultFromConfig(config: TranslateStringConfig, isEditMode: boolean = false): TranslateResult {
    let title: TranslateResult =
      config.tcNumber !== undefined
        ? i18n.tc(config.key, config.tcNumber, config.params)
        : i18n.t(config.key, config.params);
    if (isEditMode) {
      title = (title as string).replace('primary--text', 'white--text font-weight-bold') as TranslateResult;
    }
    return title;
  }

  get icon(): string {
    return this.breadcrumbsStore.currentPage.icon;
  }

  get tabs(): Optional<Array<PageTab>> {
    return this.breadcrumbsStore.currentPage.tabs;
  }

  get tabsHeight(): string {
    return `${TABS_HEIGHT}px`;
  }

  get isEditMode(): boolean {
    return this.breadcrumbsStore.currentPage.isEditMode;
  }

  get iconChipColor(): string {
    return this.breadcrumbsStore.currentPage.isEditMode ? 'white' : 'primary lighten-5';
  }

  get titleColor(): string {
    return this.breadcrumbsStore.currentPage.isEditMode
      ? 'white--text font-weight-regular'
      : 'accentVariant--text text--darken-2';
  }
}
