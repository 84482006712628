import { VueConstructor } from 'vue';
import {
  AccountInfo,
  BrowserAuthOptions,
  CacheOptions,
  Configuration,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MSALPublic } from '@client/plugins/vue-msal-browser/plugin';

export type MSALState = {
  user: AccountInfo | null;
  accessToken: string | null;
  idToken: string | null;
  isInitialized: boolean;
};

export default class VueMsalBrowser {
  static install(Vue: VueConstructor, options: Configuration) {
    const state: MSALState = Vue.observable({ user: null, accessToken: null, idToken: null, isInitialized: false });
    Vue.prototype.$msal = new VueMsalBrowser(options, state);
  }

  private static readonly cache: CacheOptions = {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  };

  private static readonly auth: BrowserAuthOptions = {
    postLogoutRedirectUri: window.location.href,
    navigateToLoginRequestUrl: false,
    authority: `${process.env.VUE_APP_B2C_AUTHORITY_BASE}${process.env.VUE_APP_B2C_AUTHORITY_POLICY_SIGNUP_SIGNIN}`,
    clientId: `${process.env.VUE_APP_B2C_CLIENTID}`,
    redirectUri: `${process.env.VUE_APP_B2C_REDIRECTURI}`,
    knownAuthorities: [
      `https://${process.env.VUE_APP_B2C_TENANT}.b2clogin.com/${process.env.VUE_APP_B2C_TENANT}.onmicrosoft.com`,
      `${process.env.VUE_APP_B2C_AUTHORITY_BASE}`,
    ],
  };

  constructor(options: Configuration, state: MSALState) {
    const cache: CacheOptions = { ...VueMsalBrowser.cache, ...options.cache };
    const auth: BrowserAuthOptions = { ...VueMsalBrowser.auth, ...options.auth };
    const msal: PublicClientApplication = new PublicClientApplication({
      ...options,
      cache,
      auth,
      system: {
        loggerOptions: {
          logLevel: LogLevel.Error,
          loggerCallback: (level: LogLevel, message: string) => {
            switch (level) {
              case LogLevel.Error:
                console.error('MSAL', message);
                return;
              case LogLevel.Info:
                console.info('MSAL', message);
                return;
              case LogLevel.Verbose:
                console.debug('MSAL', message);
                return;
              case LogLevel.Warning:
                console.warn('MSAL', message);
                return;
              default:
                console.info('MSAL', message);
                return;
            }
          },
        },
      },
    });
    return new MSALPublic(msal, state);
  }
}
