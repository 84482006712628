
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class SensorViewLoader extends Vue {
  /* DECLARATIONS */
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
