
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { Gondola, Store } from '@client/models';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

@Component({
  components: { ModalHeader },
  methods: {
    validateTextFieldLength,
  },
})
export default class CloneSectionButton extends Vue {
  /* DECLARATIONS */
  @Prop({ required: false })
  private section!: Gondola;
  @Prop()
  private isLoading!: boolean;

  private storesStore: StoresStore = useStoresStore();

  private showDialog: boolean = false;
  private sectionName: string = '';
  private sectionAisle: string = '';
  private isSectionNameValid: boolean = false;

  /* LIFECYCLE EVENTS */
  created(): void {
    this.sectionAisle = this.section.aisle;
  }
  /* METHODS */
  openModal(): void {
    this.showDialog = true;
  }

  closeModal(): void {
    this.showDialog = false;
    this.sectionName = '';
    this.validate();
  }

  async onSave(): Promise<void> {
    this.validate();
    if (!this.isSectionNameValid) {
      return;
    }
    if (!this.store) {
      return;
    }

    await this.storesStore.cloneSection(
      this.storeId,
      this.store.hash,
      this.section._id,
      this.sectionName,
      this.sectionAisle
    );

    this.$router.push({ query: { aisle: this.sectionAisle } }).catch((error: Error) => {
      console.error(error);
    });
    this.closeModal();
  }

  validate(): void {
    let isValid: boolean = true;
    if (validateTextFieldLength(this.sectionName.trim(), DEFAULT_COMMON_STRING_MAX_LENGTH, true) !== true) {
      isValid = false;
    }
    if (this.isSectionNameUnique(this.sectionName) !== true) {
      isValid = false;
    }
    this.isSectionNameValid = isValid;
  }

  aisleSelectChange() {
    // It triggers the text view validation method when the aisle changes.
    (this.$refs.cloneSectionNameText as Vue & { validate: () => boolean }).validate();
    this.validate();
  }

  isSectionNameUnique(sectionName: string): boolean | TranslateResult {
    if (!this.store) {
      return false;
    }
    return this.storesStore.sectionNameExistsInAisle(this.storeId, this.sectionAisle, sectionName)
      ? this.$t(this.$i18nTranslationKeys.storeDetail.duplicateSection.$path)
      : true;
  }
  /* GETTERS */

  get DEFAULT_COMMON_STRING_MAX_LENGTH() {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get store(): Optional<Store> {
    return this.storesStore.getStoreById(this.storeId);
  }

  get aisles(): Array<string> {
    return this.storesStore.getAisleNamesOfStore(this.storeId);
  }

  get modalTitle(): TranslateResult {
    const title: TranslateResult = this.$t(this.$i18nTranslationKeys.action.clone.$path);
    return `${title} - <strong class="primary--text">${this.section.positionInAisle}</strong>`;
  }
}
