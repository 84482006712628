
import { Component, Prop, Vue } from 'vue-property-decorator';
import Preview from '../Preview/Preview.vue';
import { BackgroundContent, ForegroundContent, DeviceTemplate, Store } from '@client/models';
import DeviceGroup, { UNLINKED_GROUP_COLOR } from '@client/models/DeviceModels/DeviceGroup.model';
import { Optional } from '@common/types';
import DeviceTemplateEditTour from '@client/components/DeviceTemplateEditTour/DeviceTemplateEditTour.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { StoresStore, useStoresStore } from '@client/stores/stores';

@Component({
  components: {
    DeviceTemplateEditTour,
    Preview,
  },
})
export default class Device extends Vue {
  @Prop() private deviceTemplate!: DeviceTemplate;
  @Prop() private gondolaTemplateId!: string;
  @Prop() private getCurrentlyDisplayedScheduleForTemplate!: boolean;
  @Prop() private firstDeviceInTemplate!: boolean;

  private schedulesStore: SchedulesStore = useSchedulesStore();
  private storesStore: StoresStore = useStoresStore();

  get getSize(): string {
    return `device-${this.deviceTemplate.hardwareModel.identifier}`;
  }

  get getSelectedSchedule(): string {
    const storeModel: Optional<Store> = this.storesStore.getStoreById(this.$route.params.storeid);
    if (this.getCurrentlyDisplayedScheduleForTemplate && storeModel) {
      return this.schedulesStore.getCurrentlyDisplayedScheduleForTemplate(this.gondolaTemplateId, storeModel.timezone);
    }
    return this.schedulesStore.getSelectedSchedule();
  }

  get getDeviceGroup(): DeviceGroup {
    return this.deviceTemplate.railGroup;
  }

  get getForegroundContentItems(): Array<ForegroundContent> {
    return this.deviceTemplate?.foregroundContent || [];
  }

  get getBackgroundContentItem(): BackgroundContent | undefined {
    return this.deviceTemplate?.backgroundContent?.[0];
  }

  get deviceHasContent(): boolean {
    return !!this.getBackgroundContentItem?.baseLayer || !!this.getForegroundContentItems.length;
  }

  get railGroupColor(): string {
    return this.getDeviceGroup.color ?? UNLINKED_GROUP_COLOR;
  }

  get railGroupIconColor(): string {
    return this.getDeviceGroup.color === DeviceGroup.DeviceGroupUnlinked.color ? 'error' : 'white';
  }

  get isDeviceGroupUnlinked(): boolean {
    return this.getDeviceGroup.isUnlinked !== undefined && this.getDeviceGroup.isUnlinked;
  }
}
