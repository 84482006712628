import { RouteConfig } from 'vue-router/types/router';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';

const SensorsPage = () => import('@client/views/SensorPages/SensorView.vue');

export const sensorRoutes: Array<RouteConfig> = [
  {
    path: '/sensors',
    name: 'sensors',
    alias: '/',
    component: SensorsPage,
    meta: {
      title: i18n.t(TranslationKeys.sensors.navigationTitle.$path),
    },
  },
];
