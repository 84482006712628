
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Device, DeviceTemplate, Gondola, GondolaTemplate, Store } from '@client/models';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import DevicesGrid from '@client/components/Gondola/DevicesGrid.vue';
import { TranslateResult } from 'vue-i18n';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import { SectionValidationError, SectionValidationResultJSON } from '@common/stores/error';
import { AxiosError } from 'axios';
import { SectionValidationResult } from '@client/stores/stores/types';
import { ValidationStatus } from '@common/error/types';
import { getStoreDetailPath } from '@client/router/utils';
import { v4 as uuidv4 } from 'uuid';
import { Portal } from 'portal-vue';
import { SectionValidation, validateSections } from '@client/utils/validateSections';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';

@Component({
  components: { SelectTag, Wrapper, DevicesGrid, Portal },
})
export default class CreateMultipleSections extends Vue {
  /* DECLARATIONS */
  @Prop({ type: Store, required: true })
  private store!: Store;
  @Prop({ type: String, required: true })
  private aisle!: string;
  private sectionsToAdd: Array<Gondola> = [];
  private sectionValidation: Array<SectionValidationResult> = [];
  private rerenderKey: number = 0;
  private areSectionsValid: boolean = true;
  private showForceMoveModal: boolean = false;
  private devicesToMoveFromOtherSections: Array<Device> = [];

  private storesStore: StoresStore = useStoresStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  /* LIFECYCLE EVENTS */
  created(): void {
    const defaultSection: Gondola = new Gondola(this.aisle, '', [[new Device()]]);
    defaultSection._id = uuidv4();
    this.sectionsToAdd.push(defaultSection);
    this.sectionValidation.push({ errors: [], hasError: false, generalSeverity: ValidationStatus.VALID });
  }

  mounted() {
    this.validateForm();
  }

  @Watch('sectionsToAdd', { deep: true })
  onSectionsToAddChange(): void {
    const isFormDirty: boolean =
      this.sectionsToAdd.length > 1 ||
      this.sectionsToAdd[0].positionInAisle !== '' ||
      this.sectionsToAdd[0].railGrid.flat(1).length > 1;
    this.$emit('change', isFormDirty);
  }

  @Watch('areSectionsInvalid')
  onAreSectionsInvalidChange(): void {
    this.$emit('validation', !this.areSectionsInvalid);
  }

  /* METHODS */

  updateSection(section: Gondola, index: number): void {
    this.$set(this.sectionsToAdd, index, section);
    this.validateSections();
    this.rerenderKey++;
  }

  getDisplayNameForTemplate(template: GondolaTemplate): string | undefined {
    if (template.name && template.name !== '') {
      return template.name;
    }
    return template._id;
  }

  onTemplateSelected(selectedTemplateId: string, section: Gondola): void {
    const selectedTemplate: Optional<GondolaTemplate> = this.gondolaTemplatesStore.getById(selectedTemplateId);
    if (!selectedTemplate) {
      return;
    }
    section.railGrid = selectedTemplate.railGrid.map((row: Array<DeviceTemplate>) =>
      row.map((cell: DeviceTemplate) => new Device(cell.hardwareModel))
    );
  }

  addNewSection(): void {
    const newSection: Gondola = new Gondola(this.aisle, '', [[new Device()]]);
    newSection._id = uuidv4();
    this.sectionsToAdd.unshift(newSection);
    this.sectionValidation.unshift({ errors: [], hasError: false, generalSeverity: ValidationStatus.VALID });
    this.validateForm();
  }

  removeSection(index: number): void {
    this.sectionsToAdd.splice(index, 1);
    this.sectionValidation.splice(index, 1);
    this.validateForm();
  }

  onTagListChanged(section: Gondola, selectedTags: Array<TagReference>) {
    section.tags = selectedTags;
  }

  checkSectionNameValidity(value: string, index: number): TranslateResult | boolean {
    const valueToCheck: string = value.trim().toLocaleLowerCase();
    if (!valueToCheck) {
      return this.$t(this.$i18nTranslationKeys.error.requiredField.$path);
    }
    const sectionNames: string[] = this.sectionsToAdd.map((section: Gondola) =>
      section.positionInAisle.toLocaleLowerCase()
    );
    this.store.gondolas.forEach((gondola: Gondola) => {
      if (gondola.aisle !== this.aisle) {
        return;
      }
      sectionNames.push(gondola.positionInAisle.toLocaleLowerCase());
    });
    sectionNames.splice(index, 1);
    if (sectionNames.includes(valueToCheck)) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.createOrEditSections.duplicateSection.$path);
    }
    return true;
  }

  isDeleteButtonDisabled(index: number): boolean {
    return index === 0 && this.sectionsToAdd.length === 1;
  }

  validateSections(): void {
    this.areSectionsValid = true;
    this.devicesToMoveFromOtherSections = [];
    const { devicesToMoveFromOtherSections, areSectionsValid, shouldRerender }: SectionValidation = validateSections(
      this.sectionsToAdd,
      this.sectionValidation,
      this.store
    );
    this.devicesToMoveFromOtherSections = devicesToMoveFromOtherSections;
    this.areSectionsValid = areSectionsValid;
    if (shouldRerender) {
      this.rerenderKey++;
    }
  }

  async onSave(): Promise<void> {
    if (this.areSectionsInvalid) {
      return;
    }
    if (
      this.isValidationForcibleError &&
      !this.sectionValidation.every((validation: SectionValidationResult) => validation.hasError === false)
    ) {
      this.showForceMoveModal = true;
      return;
    }
    await this.callSave();
    return;
  }

  public async callSave(isForced: boolean = false): Promise<boolean> {
    this.$emit('change', false);
    try {
      const sections: Array<Gondola> = this.sectionsToAdd.map((section: Gondola) => {
        // Remove the uuids from the sections, they're used for the frontend only as a key to have the correct transition animation
        return new Gondola(this.aisle, section.positionInAisle, section.railGrid, undefined, section.tags);
      });
      await this.storesStore.addSectionsToAisle(this.store._id, this.aisle, sections, isForced);
      await this.$router.push(getStoreDetailPath(this.store._id));
      return true;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        this.showForceMoveModal = false;
        if (error.response?.status === 422) {
          this.devicesToMoveFromOtherSections = [];
          const validationError: SectionValidationError = error.response.data as SectionValidationError;
          validationError.details?.validationResult.forEach((validationResult: SectionValidationResultJSON) => {
            this.devicesToMoveFromOtherSections.push(...validationResult.duplicateDevices.map(Device.fromJSON));
            const sectionIndex: number = this.sectionsToAdd.findIndex(
              (section: Gondola) => section.positionInAisle === validationResult.section.positionInAisle
            );
            if (sectionIndex === -1) {
              return;
            }
            this.sectionValidation[sectionIndex] = {
              errors: validationResult.errors,
              hasError: validationResult.hasError,
              generalSeverity: validationResult.generalSeverity,
            };
            this.rerenderKey++;
          });
        }
      }
      console.error(error);
      return false;
    }
  }

  onForceMoveAbort(): void {
    this.showForceMoveModal = false;
  }

  isLastSection(index: number): boolean {
    return index === this.sectionsToAdd.length - 1;
  }

  async onForceMove(): Promise<void> {
    await this.callSave(true);
  }

  navigateToStore(): void {
    this.$router.push(getStoreDetailPath(this.store._id));
  }

  /**
   * This is used to force the validation of the form
   */
  validateForm(): void {
    this.$nextTick(() => {
      (this.$refs.sections as unknown as { validate: () => void }).validate();
    });
  }

  /* GETTERS */
  get storeName(): string {
    return this.store.name || '';
  }

  get sectionsUsedDeviceIds(): Array<string> {
    return this.sectionsToAdd
      .map((section: Gondola) => section.railGrid)
      .flat()
      .map((row: Array<Device>) => row.map((device: Device) => device.shortId))
      .flat();
  }

  get templates(): GondolaTemplate[] {
    return this.gondolaTemplatesStore.gondolaTemplates;
  }

  get isSectionDuplicate(): boolean {
    const sectionNames: string[] = this.sectionsToAdd.map((section: Gondola) =>
      section.positionInAisle.trim().toLocaleLowerCase()
    );
    const storeSectionNames: string[] = this.store.gondolas
      .filter((section: Gondola) => section.aisle === this.aisle)
      .map((section: Gondola) => section.positionInAisle.trim().toLocaleLowerCase());
    return (
      // Check uniqueness across the aisle sections that already exist
      sectionNames.some((sectionName: string) => storeSectionNames.includes(sectionName)) ||
      // Check uniqueness across the sections to be added
      sectionNames.some((sectionName: string) => sectionNames.filter((name: string) => name === sectionName).length > 1)
    );
  }

  get isSectionEmpty(): boolean {
    return this.sectionsToAdd.some((section: Gondola) => section.positionInAisle === '');
  }

  get isValidationForcibleError(): boolean {
    return this.sectionValidation.every(
      (validation: SectionValidationResult) => validation.generalSeverity !== ValidationStatus.CRITICAL_ERROR
    );
  }

  get areSectionsInvalid(): boolean {
    return (
      this.sectionsToAdd.length === 0 ||
      this.isSectionDuplicate ||
      this.isSectionEmpty ||
      !this.areSectionsValid ||
      !this.isValidationForcibleError
    );
  }

  get isLoading(): boolean {
    return this.storesStore.loadingIndicator.update;
  }
}
