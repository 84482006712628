
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class SwitchInput extends Vue {
  /* DECLARATIONS */
  @Prop()
  trueText!: TranslateResult;
  @Prop()
  falseText!: TranslateResult;
  private value: boolean = false;
  /* LIFECYCLE EVENTS */
  @Watch('value')
  onValueChange(): void {
    this.$emit('change', this.value);
    this.$emit('input', this.value);
  }
  /* METHODS */
  /* GETTERS */
  get falseActiveClass(): string {
    return !this.value ? 'active' : '';
  }

  get trueActiveClass(): string {
    return this.value ? 'active' : '';
  }
}
