
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GondolaTemplate as GondolaTemplateModel } from '@client/models';
import GondolaTemplatePlaceholder from './GondolaTemplatePlaceholder.vue';
import GondolaTemplateContent from './GondolaTemplateContent.vue';
import SmartPreview from '../SmartPreview/SmartPreview.vue';
import ScaleControl from '../ScaleControl/ScaleControl.vue';
import RepublishButton from '@client/components/GondolaTemplate/Buttons/RepublishButton.vue';
import EditButton from '@client/components/GondolaTemplate/Buttons/EditButton.vue';
import CloneButton from '@client/components/GondolaTemplate/Buttons/CloneButton.vue';
import DeleteButton from '@client/components/GondolaTemplate/Buttons/DeleteButton.vue';
import Tag from '@client/components/Settings/Tags/Tag.vue';
import TagsList from '@client/components/Settings/Tags/TagsList.vue';
@Component({
  components: {
    TagsList,
    Tag,
    DeleteButton,
    CloneButton,
    EditButton,
    RepublishButton,
    GondolaTemplatePlaceholder,
    SmartPreview,
    ScaleControl,
    GondolaTemplateContent,
  },
})
export default class GondolaTemplate extends Vue {
  @Prop()
  private gondola!: GondolaTemplateModel;
  @Prop()
  private gondolaIndex!: number;
}
