
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import Tag from '@client/models/SettingsModels/Tag.model';
import TagReference from '@client/models/SettingsModels/TagReference';
import { TranslateResult } from 'vue-i18n';
import TagPreview from '@client/components/Settings/Tags/TagPreview.vue';

@Component({
  components: { TagPreview },
})
export default class SelectTag extends Vue {
  private tagsStore: TagsStore = useTagsStore();
  private selectedTags: Array<TagReference> = [];
  @Prop({ default: 2 })
  private numberOfShownElements!: number;

  @Prop({ default: () => [] })
  private alreadySelectedTags!: Array<TagReference>;
  @Prop()
  private loading!: boolean;
  @Prop({ default: false })
  private isStandAlone!: boolean;

  @Prop({ default: false })
  private clearable!: boolean;

  async created() {
    this.init();
  }

  // needed to reset the state after closing the modal
  @Watch('alreadySelectedTags')
  init() {
    this.selectedTags = this.alreadySelectedTags.map(
      (tag: TagReference) => new TagReference(tag.id, tag.name, tag.color)
    );
  }

  validateSelectedTags(): boolean | TranslateResult {
    return this.selectedTags.length <= 5
      ? true
      : this.$t(this.$i18nTranslationKeys.settings.tags.assignTag.tagCountWarning.$path);
  }

  onTagListChanged() {
    this.$emit('onTagListChanged', this.selectedTags);
  }

  onClearTag(tagId: string) {
    this.selectedTags = this.selectedTags.filter((tag: TagReference) => tag.id !== tagId);
    this.$emit('onTagListChanged', this.selectedTags);
  }

  onBlur(): void {
    if (this.alreadySelectedTags.length === this.selectedTags.length) {
      if (
        this.alreadySelectedTags.every((tag: TagReference, index: number) => tag.id === this.selectedTags[index].id)
      ) {
        return;
      }
    }
    this.$emit('onTagListBlur', this.selectedTags);
  }

  onInput(): void {
    this.selectedTags = this.selectedTags
      .slice()
      .sort((a: TagReference, b: TagReference) => a.name.localeCompare(b.name));
  }

  isAlreadyAssigned(tagId: string): boolean {
    return this.selectedTags.findIndex((tag: TagReference) => tag.id === tagId) !== -1;
  }

  get isLoading(): boolean {
    return this.tagsStore.isFetching || this.tagsStore.isActionPending || this.loading;
  }

  get tagsList(): Array<TagReference> {
    return this.tagsStore.tags.map((tag: Tag) => new TagReference(tag._id, tag.name, tag.color));
  }

  get canTagBeAdded(): boolean {
    return this.tagsList.filter((tagRef: TagReference) => this.isAlreadyAssigned(tagRef.id)).length < 5;
  }
}
