
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GondolaTemplate } from '@client/models';
import CreateOrCloneGondolaTemplateModal from '@client/components/GondolaTemplate/CreateOrCloneGondolaTemplateModal.vue';

@Component({
  components: { CreateOrCloneGondolaTemplateModal },
})
export default class CloneButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondolaIndex!: number;
  @Prop({ required: true })
  private gondola!: GondolaTemplate;

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }
}
