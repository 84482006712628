import { render, staticRenderFns } from "./NotificationStatusChip.vue?vue&type=template&id=f9c66584&scoped=true"
import script from "./NotificationStatusChip.vue?vue&type=script&lang=ts"
export * from "./NotificationStatusChip.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9c66584",
  null
  
)

export default component.exports