
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DetectedObjectEntry, DeviceSensorObjects } from '@common/apim/definitions';
import { secondsToHHMMSS } from '@client/components/SensorAnalytics/charts/utils';

@Component({
  methods: {
    secondsToHHMMSS,
  },
})
export default class Overview extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sensorDataObjects: DeviceSensorObjects | null = null;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get totalDetectedObjects(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.length;
  }

  get totalEngageTime(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.reduce((acc: number, detectedObject: DetectedObjectEntry) => {
      return (
        acc +
        detectedObject.detectedObject.decisionTime +
        detectedObject.detectedObject.dwellTime +
        detectedObject.detectedObject.exposureTime
      );
    }, 0);
  }

  get averageInteractionTime(): number {
    if (!this.sensorDataObjects || this.totalDetectedObjects === 0 || this.totalEngageTime === 0) {
      return 0;
    }
    return this.totalEngageTime / this.totalDetectedObjects;
  }
}
