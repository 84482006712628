
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '@client/models';
import { getDeviceDetailPath } from '@client/router/utils';

@Component({})
export default class SynchronizedDevices extends Vue {
  /* DECLARATIONS */
  @Prop()
  private closePanel!: () => void;
  @Prop()
  private devices!: Device[];
  @Prop()
  private translationKey!: string;
  @Prop({ default: true })
  private showIdAsLink!: boolean;

  private initialNumberOfDevicesShown: number = 10;
  private maximumNumberOfDevicesToShow: number = this.initialNumberOfDevicesShown;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  navigateToDeviceDetail(longId: string): void {
    const link: string = getDeviceDetailPath(longId);
    // If we are already on the device detail page, do not navigate to the same page
    const [path]: Array<string> = link.split('?');
    if (this.$route.fullPath === path) {
      this.closePanel();
      return;
    }
    this.$router.push(link);
  }
  showAllDevices(): void {
    this.maximumNumberOfDevicesToShow = this.devices.length;
  }
  /* GETTERS */

  get parsedDevices(): Array<Device> {
    return this.devices.slice(0, this.maximumNumberOfDevicesToShow);
  }

  get isSectionDisabled(): boolean {
    return this.devices.length === 0;
  }

  get showMoreDeviceVisible(): boolean {
    return this.devices.length > this.maximumNumberOfDevicesToShow;
  }

  get numberOfDevicesHidden(): number {
    return this.devices.length - this.maximumNumberOfDevicesToShow;
  }
}
