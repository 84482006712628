
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DeviceTemplate, GondolaTemplate, Schedule } from '@client/models';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { ErrorStore, useErrorStore } from '@client/stores/error';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { Optional } from '@common/types';
import TemplateNotFound from '@client/components/NotFound/TemplateNotFound.vue';
import GondolaTemplateLoader from '@client/components/GondolaTemplate/GondolaTemplateOverviewLoader.vue';
import RepublishButton from '@client/components/GondolaTemplate/Buttons/RepublishButton.vue';
import LayoutTab from '@client/components/GondolaTemplate/LayoutTab.vue';
import Tag from '@client/components/Settings/Tags/Tag.vue';
import SchedulesTab from '@client/components/GondolaTemplate/SchedulesTab.vue';
import { Portal } from 'portal-vue';
import DetailsTab from '@client/components/GondolaTemplate/DetailsTab.vue';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import TagReference from '@client/models/SettingsModels/TagReference';
import { TranslateResult } from 'vue-i18n';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import SaveButton from '@client/components/GondolaTemplate/Buttons/SaveButton.vue';
import ConfirmLeaveModal from '@client/components/Layouts/ConfirmLeaveModal.vue';
import { Route } from 'vue-router/types/router';
import { NextType } from '@client/definitions/hooks';
import _ from 'lodash';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';

const LAYOUT_TAB: string = 'layout';
const SCHEDULES_TAB: string = 'schedules';
const DETAILS_TAB: string = 'details';

@Component({
  methods: {
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
    validateTextFieldLength,
  },
  components: {
    ConfirmLeaveModal,
    DetailsTab,
    SchedulesTab,
    LayoutTab,
    RepublishButton,
    TemplateNotFound,
    GondolaTemplateLoader,
    Portal,
    Tag,
    SaveButton,
  },
})
export default class GondolaTemplateDetailView extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'GondolaTemplateDetailView';
  private selectedSchedule: string = '';
  private isDataLoaded: boolean = false;
  private activeTab: string = LAYOUT_TAB;
  private isLoading: boolean = false;
  private showConfirmLeaveModal: boolean = false;

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private errorStore: ErrorStore = useErrorStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private tagsStore: TagsStore = useTagsStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  private gondolaTemplate: GondolaTemplate = new GondolaTemplate('');

  async created(): Promise<void> {
    this.schedulesStore.clearSelectedSchedule();
    await this.gondolaTemplatesStore.fetch();
    await this.schedulesStore.fetch();
    await this.tagsStore.fetch();
    await this.contentItemsStore.fetchContentItems();
    this.isDataLoaded = true;
    this.initGondolaTemplate();
  }

  private onLeaveModalConfirmed: () => void = () => {
    // Will be overridden later
  };
  private onLeaveModalCanceled: () => void = () => {
    // Will be overridden later
  };

  initGondolaTemplate(): void {
    if (this.oldGondolaTemplate) {
      this.gondolaTemplate = this.oldGondolaTemplate.clone(false);
    }
  }

  beforeDestroy(): void {
    this.errorStore.deregister(GondolaTemplateDetailView.ERROR_OBSERVER_KEY);
  }

  beforeRouteLeave(_to: Route, _from: Route, next: NextType): void {
    if (!this.hasChange) {
      next();
      return;
    }
    this.showConfirmLeaveModal = true;
    this.onLeaveModalConfirmed = () => {
      this.updateGondolaTemplate().then(() => {
        this.showConfirmLeaveModal = false;
        next();
      });
    };
    this.onLeaveModalCanceled = () => {
      this.showConfirmLeaveModal = false;
      next();
    };
    //next();
  }

  @Watch('schedulesStore.schedules', { deep: true })
  @Watch('schedulesStore.selectedSchedule')
  onSchedulesChanged(): void {
    this.selectedSchedule = this.schedulesStore.selectedSchedule;
  }

  @Watch('isDataLoaded')
  onGondolaTemplateFetched() {
    if (this.gondolaTemplate) {
      this.breadcrumbsStore.replace({
        path: `/templates`,
        title: { key: this.$i18nTranslationKeys.gondolaTemplateListView.breadcrumb.$path },
      });
      this.breadcrumbsStore.push({
        path: `${this.$route.fullPath}`,
        title: {
          key: this.$i18nTranslationKeys.gondolaTemplateDetailView.breadcrumb.$path,
          params: {
            template: this.gondolaTemplate.name || this.id,
          },
        },
      });
      this.setCurrentPage();
    }
  }

  @Watch('$route.fullPath')
  async onRouteChanged(): Promise<void> {
    this.activeTab = LAYOUT_TAB;
  }

  @Watch('$i18n.locale')
  setCurrentPage(): void {
    this.activeTab = LAYOUT_TAB;
    this.breadcrumbsStore.setCurrentPage(
      {
        key: this.$i18nTranslationKeys.gondolaTemplateDetailView.breadcrumb.$path,
        params: { template: this.oldGondolaTemplate?.name || this.id },
      },
      'mdi-clipboard',
      [
        {
          title: { key: this.$i18nTranslationKeys.gondolaTemplateDetailView.layoutTab.$path },
          clickEvent: () => {
            this.activeTab = LAYOUT_TAB;
          },
          key: LAYOUT_TAB,
          dataCy: 'layout-tab-cy',
        },
        {
          title: { key: this.$i18nTranslationKeys.gondolaTemplateDetailView.scheduleTab.$path },
          clickEvent: () => {
            this.activeTab = SCHEDULES_TAB;
          },
          key: SCHEDULES_TAB,
          dataCy: 'schedules-tab-cy',
        },
        {
          title: { key: this.$i18nTranslationKeys.gondolaTemplateDetailView.detailTab.$path },
          clickEvent: () => {
            this.activeTab = DETAILS_TAB;
          },
          key: DETAILS_TAB,
          dataCy: 'details-tab-cy',
        },
      ],
      true
    );
  }

  validateName(name: string): boolean | TranslateResult {
    const dublicateGondolaTemplate: Optional<GondolaTemplate> = this.gondolaTemplatesStore.findName(
      this.gondolaTemplate.name
    );
    if (!dublicateGondolaTemplate || dublicateGondolaTemplate._id === this.gondolaTemplate?._id) {
      return validateTextFieldLength(name, DEFAULT_COMMON_STRING_MAX_LENGTH, true);
    }
    return i18n.t(TranslationKeys.error.genericDuplicate.$path);
  }

  updateName(name: string): void {
    this.gondolaTemplate.name = name;
  }
  updateTags(tags: TagReference[]): void {
    this.gondolaTemplate.tags = tags;
  }
  updateRailgrid(railGrid: Array<Array<DeviceTemplate>>): void {
    this.gondolaTemplate.railGrid = railGrid;
  }

  navigateToTemplateOverview(): void {
    this.$router.push('/templates');
  }

  closeLeaveDialog(): void {
    this.showConfirmLeaveModal = false;
  }

  async updateGondolaTemplate(): Promise<void> {
    if (!this.gondolaTemplate?._id) {
      return;
    }
    if (!this.hasChange || this.validateName(this.gondolaTemplate.name) !== true) {
      return;
    }
    this.isLoading = true;
    try {
      await this.gondolaTemplatesStore.updateGondolaTemplate(this.gondolaTemplate);
      this.initGondolaTemplate();
    } catch (error) {
      console.error('Failed to update gondola template:', error);
    } finally {
      this.isLoading = false;
      this.setCurrentPage();
      await this.tagsStore.fetch();
    }
  }

  get id(): string {
    return this.$route.params.id;
  }

  get schedules(): Array<Schedule> {
    return this.schedulesStore.getSchedulesForTemplate(this.id);
  }

  get isValid() {
    return this.validateName(this.gondolaTemplate.name) === true;
  }

  get hasChange() {
    if (!this.oldGondolaTemplate || !this.gondolaTemplate) {
      return false;
    }
    return !_.isEqual(this.gondolaTemplate.toJSON(), this.oldGondolaTemplate.toJSON());
  }

  get oldGondolaTemplate(): Optional<GondolaTemplate> {
    return this.gondolaTemplatesStore.getById(this.id);
  }
}
