import {
  PublicClientApplication,
  AuthenticationResult,
  RedirectRequest,
  AccountInfo,
  AuthError,
} from '@azure/msal-browser';
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { MSALState } from '@client/plugins/vue-msal-browser/index';
import { IdTokenClaims } from '@client/models/UserModels';

export class MSALPublic {
  private msal: PublicClientApplication;
  public state: MSALState = { user: null, accessToken: null, idToken: null, isInitialized: false };

  private static readonly request: RedirectRequest = {
    scopes: [`${process.env.VUE_APP_B2C_SCOPE}`],
  };

  constructor(msal: PublicClientApplication, state: MSALState) {
    this.msal = msal;
    this.state = state;
  }

  async signIn(redirectUri: string = `${process.env.VUE_APP_B2C_REDIRECTURI}`): Promise<void> {
    if (!this.state.isInitialized) {
      await this.initialize();
    }
    await this.msal.handleRedirectPromise().catch(async (e: unknown) => {
      console.warn('Failed to handle redirect promise', e);
      const error: AuthError = e as AuthError;
      if (error.errorMessage.includes('AADB2C90118')) {
        await this.msal.loginRedirect({
          authority: `${process.env.VUE_APP_B2C_AUTHORITY_BASE}${process.env.VUE_APP_B2C_AUTHORITY_POLICY_PASSWORDRESET}`,
          scopes: ['openid'],
          extraQueryParameters: { ui_locales: localStorage.localeId || 'en' },
        });
      }
    });
    if (this.areAccountsAvailable()) {
      this.setActiveAccount();
      await this.acquireToken({ ...MSALPublic.request });
      window.location.hash = '';
      return;
    } else if (window.location.hash) {
      this.setActiveAccount();
      await this.acquireToken({ ...MSALPublic.request });
    }
    await this.msal.loginRedirect({
      scopes: ['openid'],
      authority: `${process.env.VUE_APP_B2C_AUTHORITY_BASE}${process.env.VUE_APP_B2C_AUTHORITY_POLICY_SIGNUP_SIGNIN}`,
      extraQueryParameters: { ui_locales: localStorage.localeId || 'en' },
    });
  }

  async signOut(): Promise<void> {
    await this.msal.handleRedirectPromise().catch((e: unknown) => {
      console.error(e);
    });
    await this.msal.logoutRedirect();
  }

  acquireTokenRedirect(request: RedirectRequest): void {
    this.msal.acquireTokenRedirect(request);
  }

  acquireTokenPopup(request: RedirectRequest): void {
    this.msal.acquireTokenPopup(request);
  }

  async editProfile(): Promise<void> {
    await this.msal.loginRedirect({
      authority: `${process.env.VUE_APP_B2C_AUTHORITY_BASE}${process.env.VUE_APP_B2C_AUTHORITY_POLICY_EDITPROFILE}`,
      scopes: ['profile'],
      extraQueryParameters: { ui_locales: localStorage.localeId || 'en' },
    });
  }

  async acquireToken(request: RedirectRequest, retries: number = 0): Promise<AuthenticationResult> {
    try {
      const response: AuthenticationResult = await this.msal.acquireTokenSilent(request);
      this.state.user = response.account;
      this.state.accessToken = response.accessToken;
      this.state.idToken = response.idToken;
      await this.setupAxiosConfig();
      return response;
    } catch (e: unknown) {
      console.error(e);
      const error: AuthError = e as AuthError;
      if (this.requiresInteraction(error.errorCode)) {
        this.acquireTokenRedirect(request);
      } else if (retries > 0) {
        return new Promise((resolve: (value: AuthenticationResult | PromiseLike<AuthenticationResult>) => void) => {
          setTimeout(async () => {
            const res: AuthenticationResult = await this.acquireToken(request, retries - 1);
            await this.setupAxiosConfig();
            resolve(res);
          }, 60 * 1000);
        });
      }
      throw error;
    }
  }

  isAuthenticated(): boolean {
    return this.state.isInitialized && this.state.accessToken !== null;
  }

  private areAccountsAvailable(): boolean {
    if (!this.state.isInitialized) {
      return false;
    }

    const accounts: Array<AccountInfo> = this.msal.getAllAccounts();
    return accounts && accounts.length > 0;
  }

  private setActiveAccount(): void {
    const accounts: Array<AccountInfo> = this.msal.getAllAccounts();
    const activeAccount: AccountInfo | null = this.msal.getActiveAccount();
    if (!activeAccount && accounts.length > 0) {
      this.msal.setActiveAccount(accounts[0]);
    }
  }

  async initialize(): Promise<void> {
    await this.msal.initialize();
    this.state.isInitialized = true;
  }

  private requiresInteraction(errorCode?: string) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return errorCode === 'consent_required' || errorCode === 'interaction_required' || errorCode === 'login_required';
  }

  async setupAxiosConfig(): Promise<void> {
    axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
      const accountInfo: AccountInfo | null = this.state.user;
      if (!accountInfo?.idTokenClaims) {
        throw new Error('No idTokenClaims available');
      }

      const idTokenClaims: IdTokenClaims = accountInfo.idTokenClaims as unknown as IdTokenClaims;
      config.headers.Authorization = `Bearer ${this.state.accessToken}`;
      config.headers['x-customer-id'] = idTokenClaims.extension_companyId;
      config.params = config.params || {};
      return config;
    });
  }
}
