
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

@Component({
  components: { ModalHeader },
})
export default class ModalDialog extends Vue {
  @Prop() title!: string | TranslateResult;
  @Prop({
    default: false,
  })
  confirmOnly!: boolean;
  @Prop() text!: string | TranslateResult;
  @Prop({ default: false }) showDialog!: boolean;
  @Prop({ default: 'primary' }) actionColor!: string;
  @Prop({
    default: () => {
      // Will be overridden later
    },
  })
  confirmHandler!: () => void;
  @Prop({
    default: () => {
      // Will be overridden later
    },
  })
  cancelHandler!: () => void;
  @Prop({
    default: 'Cancel',
  })
  cancelMessage!: string | TranslateResult;
  @Prop({
    default: 'Delete',
  })
  confirmMessage!: string | TranslateResult;
  @Prop({ default: false })
  isLoading!: boolean;
}
