
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Optional } from '@common/types';
import { Device, DeviceTemplate, Gondola, Gondola as GondolaModel, Store } from '@client/models';
import router from '@client/router';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { StoresStore, useStoresStore } from '@client/stores/stores';

@Component({})
export default class CreateTemplateFromSectionButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondola!: Gondola;
  @Prop()
  private isLoading!: boolean;

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private storesStore: StoresStore = useStoresStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  async createTemplate(gondolaId: string): Promise<void> {
    const gondola: Optional<GondolaModel> = this.currentStore?.gondolas.find(
      (gondola: GondolaModel) => gondola._id === gondolaId
    );
    if (!gondola) {
      return;
    }

    const gondolaTemplateId: Optional<string> = await this.gondolaTemplatesStore.create({
      hash: '',
      customerId: this.appGlobalStore.customer,
      railGrid: gondola.railGrid.map((gondolaRow: Array<Device>) =>
        gondolaRow.map((device: Device) => new DeviceTemplate(device.hardwareModel).toNewJSON())
      ),
      name: '',
      tags: [],
    });
    if (!gondolaTemplateId) {
      return;
    }
    await router.push(`/template/${gondolaTemplateId}`);
  }
  /* GETTERS */
  get storeId(): string {
    return this.$route.params.storeid;
  }

  get currentStore(): Optional<Store> {
    return this.storesStore.getStoreById(this.storeId);
  }
}
