
import { Component, Prop, Vue } from 'vue-property-decorator';
import Device from '@client/components/Device/Device.vue';
import ModalDialog from '../ModalDialog/ModalDialog.vue';
import { DeviceTemplate } from '@client/models';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({
  components: {
    Device,
    ModalDialog,
  },
})
export default class GondolaTemplateContent extends Vue {
  @Prop() private id!: string;
  @Prop() private loading!: boolean;
  @Prop({ default: false }) private showCurrentlyDisplayedSchedule!: boolean;
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  get railGrid(): Array<Array<DeviceTemplate>> | null {
    return this.gondolaTemplatesStore.getById(this.id)?.railGrid || null;
  }
}
