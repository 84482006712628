
import { Component, Prop, Vue } from 'vue-property-decorator';
import router from '@client/router';

@Component({
  components: {},
})
export default class EditButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondolaIndex!: number;
  @Prop()
  private gondolaTemplateId?: string;

  /* LIFECYCLE EVENTS */
  /* METHODS */
  openGondola(gondolaTemplateId?: string): void {
    if (!gondolaTemplateId) {
      return;
    }
    router.push(`/template/${gondolaTemplateId}`);
  }
  /* GETTERS */
}
