
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { TranslateResult } from 'vue-i18n';
import { ModalDialog } from '@client/components';

@Component({
  components: { ModalDialog, ModalHeader },
})
export default class DeleteButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private entityName!: string;
  @Prop()
  private entityType!: string;
  @Prop()
  private title!: TranslateResult;
  @Prop()
  private message!: TranslateResult;
  @Prop({ default: false })
  private isDisabled!: boolean;
  @Prop({ default: '' })
  private errorText!: TranslateResult;
  @Prop({ default: false })
  private isIcon!: boolean;

  private isDeleteDialogOpen: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  closeDialog(): void {
    this.isDeleteDialogOpen = false;
  }

  openDialog(): void {
    this.isDeleteDialogOpen = true;
  }

  deleteAction(): void {
    this.$emit('confirm-delete');
    this.closeDialog();
  }
  /* GETTERS */
}
