
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTextFilter from '@client/components/Filters/HeaderFilters/HeaderTextFilter.vue';
import Moment from 'moment/moment';
import { getStoreDetailPath } from '@client/router/utils';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import {
  PublishingSensorDataTableItem,
  PublishingSensorStatus,
  PublishingSensorTableFilter,
} from '@client/models/SensorModels/PublishingSensorDataTableItem';
import HeaderDateFilter from '@client/components/Filters/HeaderFilters/HeaderDateFilter.vue';
import HeaderPublishSensorStatusFilter from '@client/components/Filters/HeaderFilters/HeaderPublishSensorStatusFilter.vue';

@Component({
  methods: { getStoreDetailPath },
  computed: {
    Moment() {
      return Moment;
    },
  },
  components: { HeaderDateFilter, HeaderTextFilter, HeaderPublishSensorStatusFilter },
})
export default class SensorPublishingDataTable extends Vue {
  /* DECLARATIONS */
  @Prop({ required: true })
  private isSearching!: boolean;
  @Prop({ required: true })
  private items!: Array<PublishingSensorDataTableItem>;

  private filters: PublishingSensorTableFilter = this.initFilters();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  private initFilters(): PublishingSensorTableFilter {
    return {
      store: '',
      aisle: '',
      section: '',
      templateName: '',
      startTime: [],
      endTime: [],
      publishStatus: null,
    };
  }

  private onFilterReset(): void {
    this.filters = this.initFilters();
  }

  private changeStoreFilter(value: string): void {
    this.filters.store = value;
  }

  private changeAisleFilter(value: string): void {
    this.filters.aisle = value;
  }

  private changeSectionFilter(value: string): void {
    this.filters.section = value;
  }

  private changeTemplateNameFilter(value: string): void {
    this.filters.templateName = value;
  }

  private changeStartTimeFilter(value: [string?, string?]): void {
    this.filters.startTime = value;
  }

  private changeEndTimeFilter(value: [string?, string?]): void {
    this.filters.endTime = value;
  }

  private changePublishStatusFilter(value: PublishingSensorStatus | null): void {
    this.filters.publishStatus = value;
  }

  private openPublishingDetails(publishingId: string): void {
    this.$emit('open-publishing-details', publishingId);
  }

  /* GETTERS */
  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$t(this.$i18nTranslationKeys.devices.store.$path),
        value: 'store',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.aisle.$path),
        value: 'aisle',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.section.$path),
        value: 'section',
        filterable: true,
      },
      {
        text: this.$tc(this.$i18nTranslationKeys.template.$path, 1),
        value: 'templateName',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.schedules.startTime.$path),
        value: 'startTime',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.schedules.endTime.$path),
        value: 'endTime',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.onlineStatus.$path),
        value: 'publishStatus',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.action.actions.$path),
        value: 'actions',
        sortable: false,
        filterable: false,
      },
    ];
  }

  get filteredItems(): Array<PublishingSensorDataTableItem> {
    let filteredItems: Array<PublishingSensorDataTableItem> = this.items;
    if (this.filters.store) {
      filteredItems = filteredItems.filter((item: PublishingSensorDataTableItem) =>
        item.store.name.toLowerCase().includes(this.filters.store.toLowerCase())
      );
    }
    if (this.filters.aisle) {
      filteredItems = filteredItems.filter((item: PublishingSensorDataTableItem) =>
        item.publishing.aisle.toLowerCase().includes(this.filters.aisle.toLowerCase())
      );
    }
    if (this.filters.section) {
      filteredItems = filteredItems.filter((item: PublishingSensorDataTableItem) =>
        item.publishing.positionInAisle.toLowerCase().includes(this.filters.section.toLowerCase())
      );
    }
    if (this.filters.templateName) {
      filteredItems = filteredItems.filter((item: PublishingSensorDataTableItem) =>
        item.template?.name.toLowerCase().includes(this.filters.templateName.toLowerCase())
      );
    }
    if (this.filters.startTime[0] && this.filters.startTime[1]) {
      filteredItems = filteredItems.filter(
        (item: PublishingSensorDataTableItem) =>
          Moment(item.publishing.publishInformation.timestamp).isSameOrAfter(this.filters.startTime[0]) &&
          Moment(item.publishing.publishInformation.timestamp).isSameOrBefore(this.filters.startTime[1])
      );
    }
    if (this.filters.endTime[0] && this.filters.endTime[1]) {
      filteredItems = filteredItems.filter(
        (item: PublishingSensorDataTableItem) =>
          Moment(item.publishing.publishingEndTime).isSameOrAfter(this.filters.endTime[0]) &&
          Moment(item.publishing.publishingEndTime).isSameOrBefore(this.filters.endTime[1])
      );
    }

    if (this.filters.publishStatus) {
      filteredItems = filteredItems.filter((item: PublishingSensorDataTableItem) =>
        item.publishing.publishingEndTime
          ? this.filters.publishStatus === PublishingSensorStatus.COMPLETED
          : this.filters.publishStatus === PublishingSensorStatus.RUNNING
      );
    }

    return filteredItems;
  }
}
