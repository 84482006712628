import { render, staticRenderFns } from "./EditSection.vue?vue&type=template&id=e3632ad2"
import script from "./EditSection.vue?vue&type=script&lang=ts"
export * from "./EditSection.vue?vue&type=script&lang=ts"
import style0 from "./EditSection.vue?vue&type=style&index=0&id=e3632ad2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports