
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Canvas, IImageOptions, Object as CanvasObject } from 'fabric/fabric-impl';
import {
  ForegroundContent,
  DeviceCanvasBackgroundItem,
  DeviceCanvasForegroundItem,
  DeviceTemplate,
  Schedule,
} from '@client/models';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import ScheduledContentModel from '@client/models/ScheduleModels/ScheduledContent.model';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import { LayerVisibility } from '@common/enums';
import { Optional } from '@common/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import DeviceTemplateButtons from '@client/components/Device/DeviceTemplateButtons.vue';
import { initializeCanvasInstance } from '@client/utils/CanvasUtils';
import { HIDDEN_SCHEDULE_URL_VALUE } from '@client/models/ContentModels/types';
import { BaseLayerModelJSON } from '@common/schedule/types';

/**
 * This component encapsulates the functionality of a fabric.js Canvas into a Vue component.
 * The basic principle is:
 * - get a DeviceTemplateModel
 * - whenever it changes: create and fill a canvas with the ContentItems of the DeviceTemplateModel
 */
@Component({
  components: { DeviceTemplateButtons },
})
export default class DeviceCanvas extends Vue {
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  @Prop()
  private selectedSchedule!: string;
  private canvas?: Canvas;
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private schedules: Array<Schedule> = this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
  private resizeEventListener: () => void = () => this.initCanvasDimensions();
  private initialLoadPromise: Promise<void> = Promise.resolve();

  async created(): Promise<void> {
    // Resize canvas properly after window resize
    window.addEventListener('resize', this.resizeEventListener);
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.resizeEventListener);
  }

  @Watch('schedulesStore.schedules.length')
  onSchedulesChanged(): void {
    this.schedules = this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
  }

  async mounted(): Promise<void> {
    // mounted ensures existence of the canvas dom element -> now to initial render
    this.initialLoadPromise = this.renderCanvas();
  }

  @Watch('selectedSchedule')
  @Watch('deviceTemplate', { deep: true })
  async onDeviceUpdated(): Promise<void> {
    this.initialLoadPromise.then(async () => {
      await this.renderCanvas();
    });
  }

  async renderCanvas(): Promise<void> {
    this.gondolaTemplatesStore.setIsCanvasLoading(true);
    // each time the device changes, we need to redraw full canvas
    if (this.$refs.canvasElement) {
      this.initializeAndRenderCanvas();
      await this.renderBackground();
      await this.renderForeground();
    }
    this.gondolaTemplatesStore.setIsCanvasLoading(false);
  }

  get isCanvasLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.isCanvasLoading;
  }

  @Watch('gondolaTemplatesStore.deviceContentSelection.index')
  onSelectedIndexChanged(): void {
    // each time the index changes, we need to select the correct canvas object
    const searchKey: string = this.gondolaTemplatesStore.deviceContentSelection.isForeground
      ? `label-${this.gondolaTemplatesStore.deviceContentSelection.index}`
      : 'background';
    if (this.$refs.canvasElement && this.canvas) {
      // Find object and select it
      this.canvas.getObjects().forEach((deviceCanvasObject: CanvasObject & IImageOptions) => {
        if (deviceCanvasObject.cacheKey === searchKey) {
          this.canvas?.setActiveObject(deviceCanvasObject);
        }
      });
    }
  }

  initCanvasDimensions(): void {
    // set calculate canvas dimensions
    if (this.deviceTemplate?.hardwareModel && this.canvas) {
      // 1. make canvas same size as device
      this.canvas.setDimensions({
        width: this.deviceTemplate.hardwareModel.width,
        height: this.deviceTemplate.hardwareModel.height,
      });
      // 2. get actually available width in browser
      const canvasScaledWidth: number = (document.getElementById('canvasContainer') as Element).clientWidth;
      // 3. determine scale factor between 1 and 2 for step 4
      const scale: number = canvasScaledWidth / this.deviceTemplate.hardwareModel.width;
      // 4. zoom the whole canvas object to take up only available space
      // https://stackoverflow.com/questions/35018734/zoom-in-and-out-with-fabric-js/35019311#35019311
      this.canvas.setZoom(scale);
      this.canvas.setDimensions({
        width: this.deviceTemplate.hardwareModel.width * scale,
        height: this.deviceTemplate.hardwareModel.height * scale,
      });
    }
  }

  /**
   * Helper function to initialize the canvas if it's not yet initialized,
   * else it would remove all existing objects from the canvas.
   * It will also set the right dimensions to the canvas
   *
   * Note: it seems we need to do it this way (instead of doing it once and maintaining canvas state in store),
   * because keeping the fabric.Canvas element in the vuex store sadly seems to break vuex
   * (too deeply nested object...)
   * Also: While the canvas has to be recreated for each change, canvas items are kept and updated!
   */
  initializeAndRenderCanvas(): void {
    if (!this.canvas) {
      this.canvas = initializeCanvasInstance(this.$refs.canvasElement as HTMLCanvasElement);
    }
    this.canvas?.clear();
    this.initCanvasDimensions();
  }

  async renderBackground(): Promise<void> {
    let deviceBackgroundContent: Optional<DeviceCanvasBackgroundItem> = undefined;
    // if no scheduled content is currently selected, set the index to -1 to have the same condition as if no schedule content is found
    const scheduleIndex: number = this.deviceTemplate.backgroundContent[0]?.scheduledContent
      ? this.deviceTemplate.backgroundContent[0].scheduledContent.findIndex(
          (schedule: ScheduledContentModel) => schedule.scheduleId === this.selectedSchedule
        )
      : -1;
    // for background only assume one item for now
    if (this.deviceTemplate?.backgroundContent[0]) {
      const scheduledLayer: ScheduleLayerModel | undefined =
        this.deviceTemplate.backgroundContent[0].scheduledContent?.[scheduleIndex]?.layer;
      const layerToRender: ScheduleLayerModel | BaseLayerModel | undefined =
        scheduledLayer || this.deviceTemplate.backgroundContent[0].baseLayer;
      const isBackgroundSelected: boolean = this.selectedForegroundIndex === -1;
      // Render the scheduled content if existent else the base layer
      if (layerToRender && this.canvas) {
        deviceBackgroundContent = new DeviceCanvasBackgroundItem(
          this.canvas,
          this.deviceTemplate,
          layerToRender,
          Number(this.$route.params.row),
          Number(this.$route.params.col),
          this.$route.params.id
        );
      }
      if (!deviceBackgroundContent) {
        return;
      }
      await deviceBackgroundContent?.render(
        isBackgroundSelected,
        undefined,
        undefined,
        (scheduledLayer &&
          this.schedules.find((schedule: Schedule) => schedule._id === this.selectedSchedule)?.color) ||
          undefined
      );
    }
  }

  async renderForeground(): Promise<void> {
    if (this.deviceTemplate && this.deviceTemplate.foregroundContent?.length > 0 && this.canvas) {
      for (const [key, foregroundContentItem] of this.deviceTemplate.foregroundContent.entries()) {
        const scheduleIndex: number = foregroundContentItem.scheduledContent
          ? foregroundContentItem.scheduledContent.findIndex(
              (schedule: ScheduledContentModel) => schedule.scheduleId === this.selectedSchedule
            )
          : -1;
        const isForegroundItemSelected: boolean = this.selectedForegroundIndex === key;
        // If schedule layer is found but has no name it means that only the visibility has been set for the scheduled content
        // Then we render the background content with visibility set to hidden
        // If not, we render the scheduled layer as usual
        const scheduledLayer: ScheduleLayerModel | undefined = this.getLayerToRender(
          foregroundContentItem,
          scheduleIndex
        );
        const foregroundContentToRender: DeviceCanvasForegroundItem = new DeviceCanvasForegroundItem(
          this.canvas,
          this.deviceTemplate,
          scheduledLayer || foregroundContentItem.baseLayer,
          Number(this.$route.params.row),
          Number(this.$route.params.col),
          this.$route.params.id,
          key
        );
        await foregroundContentToRender.render(
          isForegroundItemSelected,
          scheduledLayer || foregroundContentItem.baseLayer,
          scheduledLayer?.visibility === LayerVisibility.HIDDEN,
          (scheduleIndex >= 0 &&
            this.schedules.find((schedule: Schedule) => schedule._id === this.selectedSchedule)?.color) ||
            undefined
        );
      }
    }
  }

  /**
   * Get the scheduled content if it exists or create a new scheduled layer
   * @param foregroundContentItem foreground content to get or create the scheduled layer from
   * @param scheduleIndex index of the scheduled content (-1 if it doesn't exist)
   */
  getLayerToRender(foregroundContentItem: ForegroundContent, scheduleIndex: number): ScheduleLayerModel | undefined {
    if (scheduleIndex === -1) {
      return undefined;
    }
    const { _id, ...baseLayer }: BaseLayerModelJSON = foregroundContentItem.baseLayer.toJSON();
    return foregroundContentItem.scheduledContent?.[scheduleIndex]?.layer?.url !== HIDDEN_SCHEDULE_URL_VALUE
      ? foregroundContentItem.scheduledContent?.[scheduleIndex].layer
      : ScheduleLayerModel.fromJSON({
          ...baseLayer,
          visibility:
            foregroundContentItem.scheduledContent?.[scheduleIndex]?.layer.visibility || LayerVisibility.VISIBLE,
        });
  }

  get selectedForegroundIndex(): number {
    if (
      this.gondolaTemplatesStore.gondolaTemplates &&
      this.gondolaTemplatesStore.deviceContentSelection &&
      this.gondolaTemplatesStore.deviceContentSelection.index != undefined
    ) {
      return this.gondolaTemplatesStore.deviceContentSelection.index;
    }
    return -1;
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update;
  }
}
