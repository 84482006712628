import { fabric } from 'fabric';
import { IImageOptions, Image, IObjectOptions } from 'fabric/fabric-impl';

/**
 * Helper method to create a video html element for the content.
 * taken from https://itnext.io/video-element-serialization-and-deserialization-of-canvas-fc5dbf47666d
 * Only fabricjs canvas video example that actually worked
 */
const getVideoElement = (url: string, width: number, height: number) => {
  const videoE: HTMLVideoElement = document.createElement('video');
  videoE.height = height;
  videoE.width = width;
  videoE.muted = true;
  videoE.crossOrigin = 'anonymous';
  videoE.autoplay = true;
  videoE.loop = true;
  const source: HTMLSourceElement = document.createElement('source');
  source.src = url;
  videoE.appendChild(source);
  videoE.load();
  return videoE;
};

const createVideoImage = (contentUrl: string, width: number, height: number) => {
  const videoElement: HTMLVideoElement = getVideoElement(contentUrl, width, height);
  return new fabric.Image(videoElement, {
    left: 0,
    top: 0,
  });
};

const createImage = (url: string) => {
  return new Promise<Image>((resolve: (value: PromiseLike<Image> | Image) => void) => {
    // crossOrigin needs to be set in order to create data image from canvas, see fabric.js toDataURL
    fabric.Image.fromURL(
      url,
      (retrievedImage: Image) => {
        resolve(retrievedImage);
      },
      { crossOrigin: 'anonymous' }
    );
  });
};

const createImageConfig = (
  offsetX: number,
  offsetY: number,
  color?: string,
  isHidden?: boolean,
  index?: number,
  isScheduledContent?: boolean
) => {
  let imageConfig: IObjectOptions & IImageOptions = {
    left: offsetX,
    top: offsetY,
    lockMovementX: index === undefined || isScheduledContent,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: false,
    lockRotation: true,
    hasControls: index !== undefined && !isScheduledContent,
    hoverCursor: 'mouse',
    selectable: true,
    opacity: isHidden ? 0.3 : 1,
    cacheKey: index !== undefined ? `label-${index}` : 'background',
  };
  if (color) {
    imageConfig = {
      ...imageConfig,
      borderColor: color,
      transparentCorners: false,
      cornerColor: color,
      borderScaleFactor: 4,
      hasBorders: true,
      stroke: color,
      strokeWidth: 2,
    };
  }
  return imageConfig;
};

const initializeCanvasInstance = (canvasHTMLElement: HTMLCanvasElement) => {
  const canvas: fabric.Canvas = new fabric.Canvas(canvasHTMLElement, {
    backgroundColor: 'white',
    preserveObjectStacking: true /* to prevent background from coming to the front when selected */,
    selection: false /* disable group selection, important for background! */,
    fireRightClick: true /*Allow to capture the right click event inside the canvas or/and canvas objects*/,
  });
  fabric.util.requestAnimFrame(function render() {
    canvas.renderAll();
    fabric.util.requestAnimFrame(render);
  });
  return canvas;
};

export { getVideoElement, createVideoImage, createImage, createImageConfig, initializeCanvasInstance };
