import { render, staticRenderFns } from "./WarningMessage.vue?vue&type=template&id=982d4e74&scoped=true"
import script from "./WarningMessage.vue?vue&type=script&lang=ts"
export * from "./WarningMessage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "982d4e74",
  null
  
)

export default component.exports