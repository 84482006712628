
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Optional } from '@common/types';
import { GondolaTemplate as GondolaTemplateModel } from '@client/models';
import router from '@client/router';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { SortOrder } from '@common/enums/Search';
import { TranslateResult } from 'vue-i18n';
import { sortOptions } from '@client/components/Filters/SortPicker/types';
import InfiniteScroll from '@client/components/InfiniteScroll/InfiniteScroll.vue';
import SearchTextField from '@client/components/Filters/SearchTextField/SearchTextField.vue';
import DatePicker from '@client/components/Filters/DatePicker/DatePicker.vue';
import SortPicker, { SortPickerModel } from '@client/components/Filters/SortPicker/SortPicker.vue';
import { FilterOption } from '@client/definitions/dateselector';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import {
  DEFAULT_HEADER_EXTENSION_HEIGHT,
  DEFAULT_HEADER_HEIGHT,
  TABS_HEIGHT,
} from '@client/components/Layouts/variables';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';
import AddTemplateButton from '@client/components/GondolaTemplate/Buttons/AddTemplateButton.vue';

/**
 * Renders a toolbar that contains action buttons and filters related to gondola templates
 * Wraps the page to be rendered.
 * Wrapped page is rendered as the default slot.
 */
@Component({
  components: { AddTemplateButton, SelectTag, SortPicker, DatePicker, SearchTextField, InfiniteScroll },
})
export default class GondolaTemplateListToolbar extends Vue {
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private searchQuery: string = '';
  private selectedTags: TagReference[] = [];

  private sortConfig: SortPickerModel = {
    sortOrder: SortOrder.desc,
    orderByField: sortOptions()[0].value,
  };

  private dateRangeValue: [string?, string?] = [];

  /* LIFECYCLE EVENTS */

  async created(): Promise<void> {
    this.gondolaTemplatesStore.clearSearchResults();
  }

  /* METHODS */

  onIntersectionEvent(isIntersecting: boolean): void {
    if (
      isIntersecting &&
      (this.gondolaTemplatesStore.searchResults.results.length < this.gondolaTemplatesStore.searchResults.totalCount ||
        this.gondolaTemplatesStore.currentPage === 0)
    ) {
      this.gondolaTemplatesStore.search();
    }
  }

  updateTagFilter(selectedTags: Array<TagReference>) {
    this.selectedTags = selectedTags;
  }

  @Watch('dateRangeValue')
  @Watch('searchQuery')
  @Watch('sortConfig', { deep: true })
  @Watch('selectedTags')
  async onFiltersChange(): Promise<void> {
    this.gondolaTemplatesStore.clearSearchResults();
    this.gondolaTemplatesStore.setSearchFilters(
      this.sortConfig.sortOrder,
      this.sortConfig.orderByField,
      this.searchQuery,
      this.dateRangeValue[0],
      this.dateRangeValue[1],
      this.selectedTags?.map((tag: TagReference) => tag.id) ?? []
    );
    await this.gondolaTemplatesStore.search();
  }

  async addGondolaTemplate(): Promise<void> {
    const gondolaTemplateId: Optional<string> = await this.gondolaTemplatesStore.create(
      new GondolaTemplateModel(this.appGlobalStore.customer).toNewJSON()
    );
    if (!gondolaTemplateId) {
      return;
    }
    await router.push(`/template/${gondolaTemplateId}`);
  }

  /* GETTERS */

  get isSearching(): boolean {
    return this.gondolaTemplatesStore.isSearching;
  }

  get numberOfResultsMessage(): TranslateResult {
    if (!this.gondolaTemplatesStore.gondolaTemplates.length) {
      return this.$t(this.$i18nTranslationKeys.filtersAndPagination.noEntries.$path);
    }
    if (this.gondolaTemplatesStore.searchResults.totalCount) {
      return this.$tc(
        this.$i18nTranslationKeys.filtersAndPagination.resultsFound.$path,
        this.gondolaTemplatesStore.searchResults.totalCount
      );
    } else {
      return this.$t(this.$i18nTranslationKeys.filtersAndPagination.noResults.$path);
    }
  }

  get sortOptions(): Array<FilterOption> {
    return sortOptions();
  }

  get topBarPosition(): { top: string } {
    const defaultHeaderHeight: number = DEFAULT_HEADER_HEIGHT + DEFAULT_HEADER_EXTENSION_HEIGHT;
    if (!this.breadcrumbsStore.currentPage.tabs) {
      return { top: `${defaultHeaderHeight}px` };
    }
    return { top: `${defaultHeaderHeight + TABS_HEIGHT}px` };
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update || this.gondolaTemplatesStore.isFetching;
  }
}
